import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SessionCalendar from './SessionCalendar';
import config from '../../config';
import { getResourceUrl } from '../../Services/CommanFunctions';

function TutorList() {
  const user = JSON.parse(localStorage.getItem('user'))
  const history = useHistory()
  const selectedDay = val => {
    console.log(val);
  };
  const logout = (url) => {
    localStorage.clear()
    history.push(url)
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="tutor-list mt-3">
                  <Link to="/tutor-profile" className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar-male.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>John D.</h6>
                        <h5>47 Hours Taught | Maths Professor, University Of Illinois</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <ul>
                          <li>
                            Algebra
                          </li>
                          <li>
                            Science
                          </li>
                          <li>
                            Physics
                          </li>
                          <li>
                            Geography
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tutor-action">
                      <ul>
                        <li>
                          <a href="javascript:void(0)"><i className="fa fa-heart"></i></a>
                        </li>
                        <li className="active"><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                        </a>
                        </li>
                      </ul>
                    </div>
                  </Link>
                  <Link to="/tutor-profile" className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <h5>47 Hours Taught | Maths Professor, University Of Illinois</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <ul>
                          <li>
                            Algebra
                          </li>
                          <li>
                            Science
                          </li>
                          <li>
                            Physics
                          </li>
                          <li>
                            Geography
                          </li>
                          <li>
                            Calculus
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tutor-action">
                      <ul>
                        <li>
                          <a href="javascript:void(0)"><i className="fa fa-heart"></i></a>
                        </li>
                        <li className="active"><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                        </a>
                        </li>
                      </ul>
                    </div>
                  </Link>
                  <Link to="/tutor-profile" className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar-male.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>David T.</h6>
                        <h5>47 Hours Taught | Maths Professor, University Of Illinois</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <ul>
                          <li>
                            Algebra
                          </li>
                          <li>
                            Science
                          </li>
                          <li>
                            Physics
                          </li>
                          <li>
                            Geography
                          </li>
                          <li>
                            Calculus
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tutor-action">
                      <ul>
                        <li>
                          <a href="javascript:void(0)"><i className="fa fa-heart"></i></a>
                        </li>
                        <li className="active"><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                        </a>
                        </li>
                      </ul>
                    </div>
                  </Link>
                  <Link to="/tutor-profile" className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <h5>47 Hours Taught | Maths Professor, University Of Illinois</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <ul>
                          <li>
                            Algebra
                          </li>
                          <li>
                            Science
                          </li>
                          <li>
                            Physics
                          </li>
                          <li>
                            Geography
                          </li>
                          <li>
                            Calculus
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tutor-action">
                      <ul>
                        <li>
                          <a href="javascript:void(0)"><i className="fa fa-heart"></i></a>
                        </li>
                        <li className="active"><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                        </a>
                        </li>
                      </ul>
                    </div>
                  </Link>
                  <Link to="/tutor-profile" className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar-male.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>John D.</h6>
                        <h5>47 Hours Taught | Maths Professor, University Of Illinois</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <ul>
                          <li>
                            Algebra
                          </li>
                          <li>
                            Science
                          </li>
                          <li>
                            Physics
                          </li>
                          <li>
                            Geography
                          </li>
                          <li>
                            Calculus
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tutor-action">
                      <ul>
                        <li>
                          <a href="javascript:void(0)"><i className="fa fa-heart"></i></a>
                        </li>
                        <li className="active"><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                        </a>
                        </li>
                      </ul>
                    </div>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.student?.gender_avatar?.icon_image && <img src={getResourceUrl(user?.student?.gender_avatar?.icon_image)} width={'100%'} height={'100%'} alt="" />}{!user?.student?.gender_avatar?.icon_image && <img src="images/avatar2.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.student_profile?.school_name}</h5>
                  <p><small>ID: {user?.student?.student_code}</small></p>
                  <p>Academic Year: {user?.student?.academic_year}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => logout('/')}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <SessionCalendar />
          </div>
        </div>
      </section>

    </>
  )
}

export default TutorList
