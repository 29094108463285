import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link, useParams } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import Autocomplete from 'react-autocomplete';
import { Admin } from '../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import config from "./../../config";
import { useHistory } from 'react-router-dom';
import { FecthData } from '../../Services/FetchData';
import FieldErrors from '../../Services/FieldErrors';
import ToastShow from '../common/ToastShow';
import ToastModal from '../common/ToastModal';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';
import { getMaskedText, removeField } from '../../helpers/utils';

function SchoolStudent() {

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
  })
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showToast, setShowToast] = useState(false)
  const [modalBody, setModalBody] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [bg, setBg] = useState('')
  const [value, setValue] = useState('');
  const [school, setSchool] = useState({});
  const [student, setStudent] = useState([]);
  const [index, setIndex] = useState(0)
  const [selectedStudent, selectStudent] = useState();
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const [filter, setFilter] = useState('')
  const user = JSON.parse(localStorage.getItem('user'))
  const { id } = useParams();
  const history = useHistory()
  const onToggelToast = () => {
    setShowToast(!showToast)
  }
  const getStudentsOfSchool = () => {
    FecthData.getData('student/get_all_student_of_school/' + id + '?page=' + 0 + '&filter=' + filter).then(
      (data) => {
        setStudent(data?.data)
        selectStudent(data?.data[index])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }
  useEffect(() => {
    FecthData.getData('school/get_school_by_id/' + id).then(
      (data) => setSchool(data),
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }, [id]);
  useEffect(() => {
    getStudentsOfSchool()
  }, [filter])
  const submitForm = () => {
    FecthData.postJsonData({ ...formData, school_id: id }, 'student/add_student').then(
      (data) => {
        if (data?.data) {
          NotificationManager.success(data.message, 'success!', 2000)
          getStudentsOfSchool()
          handleClose()
        } else {
          setError(data.errors.details)

        }

      }
    )
  }
  const changeUserStatus = (userId, changedStatus) => {
    var body = ''
    var title = ''
    var bg = ''
    if (changedStatus == 'Active') {
      body = 'Student admitted successfuly'
      title = 'Success!'
      bg = 'success'
    } else {
      body = 'Student declined successfuly'
      title = 'Declined!'
      bg = 'danger'
    }
    setModalBody(body)
    setModalTitle(title)
    setBg(bg)
    Admin.changeUserStatus({ user_id: userId, status: changedStatus }).then(res => {
      setIndex(index)
      getStudentsOfSchool()
      onToggelToast()
    })
  }
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={6} className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="school-logo-small">
                      <img src={school?.data?.logo ? getResourceUrl(school?.data?.logo) : "default_logos/school-logo.png"} alt=" " />
                    </div>
                    <h4 className="text-muted mb-0">{school?.data?.school_name}</h4>
                  </div>
                </Col>
                <Col md={6} className="mt-4">
                  <div className="filters">
                    <ul>
                      <li>
                        {/* <a href="javascript:void(0)">Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Sort</option>
                          <option value={'ASC'}>Alphabetic A-Z</option>
                          <option value={'DESC'}>Alphabetic Z-A</option>
                        </Form.Select>
                      </li>
                      <li>
                        {/* <a href="javascript:void(0)">Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Filter</option>
                          <option value={'Last Modified'}>Last Modified</option>
                          <option value={'Oldest'}>Oldest</option>
                          <option value={'Newest'}>Newest</option>
                        </Form.Select>
                      </li>
                      <li onClick={handleShow}>
                        <a href="javascript:void(0)" className='btn-primary'>Add <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category">
                    <ul>
                      <li className="active">
                        <Link to={'/admin/school/students/' + id}>Students</Link>
                      </li>
                      <li>
                        <Link to={'/admin/school/tutor/' + id}>Tutors</Link>
                      </li>
                      <li>
                        <Link to={'/admin/school/facilitator/' + id}>Facilitators</Link>
                      </li>
                      <li>
                        <Link to={"/admin/school/parent/" + id}>Parents/Guardians</Link>
                      </li>
                      <li>
                        <Link
                          to={'/admin/school/admin/' + id}
                        >School Admin</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">ID</th>
                          <th scope="col">Grade</th>
                          {/* <th scope="col">Gender</th> */}
                          <th scope="col">Email</th>
                          {/* <th scope='col'>Edit</th> */}
                        </tr>
                      </thead>
                      <tbody >
                        {student?.map((content, index) => (
                          <tr key={index} onClick={() => {
                            setIndex(index)
                            selectStudent(content)
                          }} style={{ cursor: 'pointer' }} >
                            <td>
                              {content?.gender_avatar && content?.gender_avatar?.icon_image? <img src={getResourceUrl(content?.gender_avatar?.icon_image)} alt="" width="44" className="rounded me-2" />
                              :<img src={'images/no_img.png'} alt="" width="44"  />}

                              {getMaskedText(content?.first_name + ' ' + content?.last_name,'name')}
                            </td>
                            <td>{content?.student_code}</td>
                            <td>{content?.grade_id?.title}</td>
                            {/* <td>{content?.gender_avatar?.name}</td> */}
                            <td>{getMaskedText(content?.email,'email') }</td>
                            {/* <td><a className="btn btn-primary btn-sm" onClick={() => history.push({ pathname: "/admin/user/edit", state: { ...content, user_role: 'student', status: content?.user_id[0].status, name: content?.user_id[0].name } })}>Edit</a></td> */}
                          </tr>
                        ))}


                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>

          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {selectedStudent ? <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={selectedStudent?.gender_avatar ? getResourceUrl(selectedStudent?.gender_avatar?.icon_image) : "images/no_profile_img.png"} alt=" " />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item ><Link className={'dropdown-item'} to={{ pathname: "/school-students-profile", state: selectedStudent }}>View Profile</Link></Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item ><Link className={'dropdown-item'} to={{ pathname: "/admin/user/edit", state: { ...selectedStudent, user_role: 'student',...removeField(selectedStudent?.user_id,['_id','user_role']), user_sf_id: selectedStudent?.user_id?._id } }}>Edit Account</Link> </Dropdown.Item>
                      {/* <Dropdown.Divider />
                      <Dropdown.Item href="javascript:void(0)">Delete Account</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{getMaskedText(selectedStudent?.first_name +' '+selectedStudent?.last_name,'name')}</h5>
                  <h6>Student ID: {selectedStudent?.student_code}</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${selectedStudent?.phone}`}>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {selectedStudent?.phone ? <span className='show-phone'>{getMaskedText(selectedStudent?.phone,'phone')}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${selectedStudent?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className={`btn btn-${selectedStudent?.user_id.status == 'Active' ? 'primary' : 'secondary'} solid btn-sm no-shadow d-block`}>Status: {selectedStudent?.user_id.status}</a>
                </div>
                <div className="about-teacher">
                  {/* <h4>About:</h4>
                  <p>{selectedStudent?.about}
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                  <h4>Gender:</h4>
                  <p>{selectedStudent?.gender_avatar?.name}</p>
                  {/* <h4>Date of birth</h4>
                  <p>2 Jan 2003</p> */}
                  <h4>Email:</h4>
                  <p>{getMaskedText(selectedStudent?.email,'email') }</p>
                  <h4>Parent’s Email</h4>
                  <p>{getMaskedText(selectedStudent?.guardian_email,'email')}</p>
                  <h4>Facilitator Email:</h4>
                  <p>{getMaskedText(selectedStudent?.facilitator_email,'email')}</p>
                  {selectedStudent?.subjects.map.length>0&&<><h4>Subjects Interested:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      {selectedStudent?.subjects.map(subject => <li>{subject?.title}</li>)}
                      {/* <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li> */}
                    </ul>
                  </div></>}
                </div>
                <ToastModal show={showToast} onHide={onToggelToast} bg={bg} centered={true} modalTitle={modalTitle} modalBody={modalBody} />
                {/* <ToastShow position={'middle-center'} show={showToast} bg={'success'} toastBody={'hello toast'} toastHeader={'Success!'} autohide={true} delay={3000} onClose={onToggelToast} closeVariant={'black'} /> */}
                {selectedStudent?.user_id?.status == 'Inactive' ? <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={() => changeUserStatus(selectedStudent?.user_id, "Active")}>Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill" variant="secondary" onClick={() => changeUserStatus(selectedStudent?.user_id, 'Declined')}>Decline</Button>
                  </Col>
                </Row> : null}
              </div>
            </div> : null}
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="Search by school name, city or zip code" />
              </Form.Group> */}
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Student First Name*" value={formData.first_name} onChange={(e) => setFormData({ ...formData, first_name: e.target.value })} />
                  <FieldErrors errors={error} field="first_name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Student Last Name*" value={formData.last_name} onChange={(e) => setFormData({ ...formData, last_name: e.target.value })} />
                  <FieldErrors errors={error} field="last_name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Student Contact Number*" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                  <FieldErrors errors={error} field="phone" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" placeholder="Student Email*" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                  <FieldErrors errors={error} field="email" />
                </Form.Group>
                {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="Mather High School" placeholder="School Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="0221457" placeholder="School Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="5835 N LincoIn Ave" placeholder="Address" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Chicago" placeholder="City" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Illinois" placeholder="State" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="60659" placeholder="Zip Code" />
                </Form.Group> */}
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill" variant="primary" onClick={submitForm}>Send Request</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default SchoolStudent
