import React, { useState, useEffect } from 'react'
import {
  Container,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { Link, useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import ActionButton from '../ActionButton';
import { staticData } from '../../helpers/data';

const formatDate = (date) => {

  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [month, day, year].join('/');
}
function ParSignupStepThree({ nextStep, prevStep, handleFormData, handleSchool, values }) {
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [message, setMessage] = useState(null);
  const [student, setStudent] = useState();
  let { id } = useParams();
  const submitFormData = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const form = e.currentTarget;
      nextStep();

    }

  };
  const handleOnChange = (e) => {

    const { value, name, checked } = e.target;
    if (checked) {
      handleSchool(name, !values[name])
    } else {
      handleSchool(name, !values[name])
    }

  }
  const getStudentInfo = (tok) => {
    School.post({ token: id }, 'guardian/get_students_by_token').then(
      (data) => {
        if (data.error === 0) {
          setStudent(data.data[0])
        }


      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
  }
  const validateForm = () => {

    let fields = values;
    let errors = {};
    let formIsValid = true;

    if (!fields["first_name"] || fields["first_name"].trim() == '') {
      formIsValid = false;
      errors["first_name"] = "*Please Select Your First Name.";
    }
    if (!fields["last_name"] || fields["last_name"].trim() == '') {
      formIsValid = false;
      errors["last_name"] = "*Please Select Your Last Name.";
    }
    if (!fields["address"] || fields["address"].trim() == '') {
      formIsValid = false;
      errors["address"] = "*Please add your address";
    }
    // if (!fields["guardian_name"] || fields["guardian_name"].trim() == '') {
    //   formIsValid = false;
    //   errors["guardian_name"] = "*Please add your name";
    // }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }
    if (!fields["facilitator_email"] && fields['facilitator_selection'] == 'nominated') {
      formIsValid = false;
      errors["facilitator_email"] = "*Please enter your guardian email-ID.";
    }

    if (typeof fields["facilitator_email"] !== "undefined" && fields['facilitator_selection'] == 'nominated') {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["facilitator_email"])) {
        formIsValid = false;
        errors["facilitator_email"] = "*Please enter valid facilitator email-ID.";
      }
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "*Please enter your phone.";
    }

    if (typeof fields["phone"] !== "undefined") {
      //regular expression for phone validation
      var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
      if (!pattern.test(fields["phone"])) {
        formIsValid = false;
        errors["phone"] = "*Please enter valid phone.";
      }
    }
    if (!fields["password"] || fields["password"].trim() == '') {
      formIsValid = false;
      errors["password"] = "Please Enter your Password.";
    }

    if (!fields["confirmpassword"] || fields["confirmpassword"].trim() == '') {
      formIsValid = false;
      errors["confirmpassword"] = "Please Enter your Confirm Password.";
    }
    if (typeof fields["password"] !== "undefined" && typeof fields["confirmpassword"] !== "undefined") {

      if (fields["password"] != fields["confirmpassword"]) {
        formIsValid = false;
        errors["password"] = "Confirm password is Not Matched";
      }
    }

    if (!fields["parental_consent"]) {
      formIsValid = false;
      errors["parental_consent"] = "*Please give your consent.";
    }
    setError2(errors)
    return formIsValid;


  }

  // useEffect(() => {
  //   getStudentInfo(id)
  // }, [id])

  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '75%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Parental Consent</h6>
                    <h6>75% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>{staticData?.verbiage?.parent[3]?.heading}</h4>
                    <p> {staticData?.verbiage?.parent[3]?.text}</p>
                    <div className="signup-step" style={{ marginTop: 260 }}>
                      <ul>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <div className="add-details">
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="first_name" onChange={handleFormData("first_name")} defaultValue={values.first_name} placeholder="First Name*" required />
                              <span className="err">{error2?.first_name}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="last_name" onChange={handleFormData("last_name")} defaultValue={values.last_name} placeholder="Last Name*" required />
                              <span className="err">{error2?.last_name}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="tel" onChange={handleFormData("phone")} defaultValue={values.phone} placeholder="Mobile*" required />
                              <span className="err">{error2?.phone}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="address" onChange={handleFormData("address")} defaultValue={values.address} placeholder="Address*" required />
                              <span className="err">{error2?.address}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                          <Form.Group className="mb-3">
                                <Form.Select name="timezone" onChange={handleFormData("timezone")} defaultValue={values.timezone}>
                                  <option disabled value=''>Select Time Zone*</option>
                                  <option value="EST">EST</option>
                                  <option value="CST">CST</option>
                                  <option value="PST">PST</option>
                                </Form.Select>
                                <span className="err">{error2?.timezone}</span>
                              </Form.Group>
                              </Col>
                          {/* <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="email" name="email" onChange={handleFormData("email")} defaultValue={values.email} placeholder="Your School Email*" readOnly />
                              <span className="err">{error2?.email}</span>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="text" name="guardian_name" onChange={handleFormData("guardian_name")} defaultValue={values.guardian_name} placeholder="Parents/Guardians Name*" required />
                              <span className="err">{error2?.guardian_name}</span>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="email" name="guardian_email" onChange={handleFormData("guardian_email")} defaultValue={values.guardian_email} placeholder="Parents/Guardians Email*" required />
                              <span className="err">{error2?.guardian_email}</span>
                            </Form.Group>
                          </Col> */}
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="password" name="password" onChange={handleFormData("password")} placeholder="Enter your new password*" />
                              <span className="err">{error2?.password}</span>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="password" name="confirmpassword" onChange={handleFormData("confirmpassword")} placeholder="Confirm your password*" />
                              <span className="err">{error2?.confirmpassword}</span>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Select name="facilitator_selection" value={values.facilitator_selection} onChange={handleFormData("facilitator_selection")}>
                                <option value="">Facilitator?*</option>
                                <option value="self_facilitate">I will be the facilitator </option>
                                <option value="nominated">I am nominating a facilitator </option>
                                <option value="requested_facilitation">I want Dasya to provide me with a facilitator</option>
                              </Form.Select>
                              <span className="err">{error2?.require_facilitator}</span>
                            </Form.Group>
                          </Col>
                          {values.facilitator_selection == 'nominated' ? <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Control type="email" name="facilitator_email" onChange={handleFormData("facilitator_email")} placeholder="Enter Facilitator Email*" value={values.facilitator_email} />
                              <span className="err">{error2?.facilitator_email}</span>
                            </Form.Group>
                          </Col> : null}
                          <Col md={12}>
                            {/* <Form.Group className="iagree mb-3" controlId="befacilitator">
                              <Form.Check className="text-grey" type="checkbox" label="Become a facilitator" name="become_facilitator" onChange={handleOnChange} checked={values.become_facilitator} />
                            </Form.Group> */}
                            <Form.Group className="iagree mb-3" controlId="namevtutor">
                              <Form.Check className="text-grey" type="checkbox" label="Make name visible to tutors" name="name_visible" onChange={handleOnChange} checked={values.name_visible} />
                            </Form.Group>
                            <Form.Group className="iagree mb-3" controlId="contactvtutors">
                              <Form.Check className="text-grey" type="checkbox" label="Make contact number visible to tutors" name="number_visible" onChange={handleOnChange} checked={values.number_visible} />
                            </Form.Group>
                            <Form.Group className="iagree mb-3" controlId="livesession">
                              <Form.Check className="text-grey" type="checkbox" label="Allow live tutoring session" name="allow_live_session" onChange={handleOnChange} checked={values.allow_live_session} />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <h6 className="text-muted">GIVE PARENTAL CONSENT</h6>
                            <p className="text-muted">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </p>
                            <Form.Group className="iagree mb-3" controlId="formBasicCheckbox">
                              <Form.Check className="text-grey" type="checkbox" name="parental_consent" checked={values.parental_consent} onChange={handleOnChange} label={`I hereby consent to the above information that is shared on Dasya as on ${formatDate()}`} />
                              <span className="err">{error2?.parental_consent}</span>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Give Parental Consent'}
                          requiredFields={[values?.facilitator_selection, values?.first_name, values?.last_name, values?.phone, values?.address, values.password, values.confirmpassword, values.parental_consent ? values.parental_consent : '']} />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Continue</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default ParSignupStepThree
