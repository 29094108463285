import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import OrgSideMenu from "./OrgSideMenu";
import config from "./../../config";
import Logout from '../logout';
import { FecthData } from '../../Services/FetchData';
import { Admin } from '../../Services/Admin';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';
import { getMaskedText, removeField } from '../../helpers/utils';

function OrgAdmin() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user = JSON.parse(localStorage.getItem('user'))
  const [admins, setAdmins] = useState([])
  const [index, setIndex] = useState(0)
  const [selectedAdmin, selectAdmin] = useState()
  const [filter, setFilter] = useState('')

  const fetchCompanyadmin = () => {
    FecthData.getData('company_user/get_company_wise_user/' + user?.company?.company_id + '?page=' + 0 + '&filter=' + filter).then(
      (data) => {
        setAdmins(data?.data)
        selectAdmin(data?.data[index])
      }
    )
  }
  const changeUserStatus = (userId, changedStatus) => {
    Admin.changeUserStatus({ user_id: userId, status: changedStatus }).then(res => {
      fetchCompanyadmin()
    })
  }

  useEffect(() => {
    fetchCompanyadmin()
  }, [filter])
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <OrgSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card" onM>
              <Row>
                <Col md={6} className="mt-4">
                  <h3 className="text-muted mb-0">Dashboard</h3>
                </Col>
                <Col md={6} className="mt-4">
                  <div className="filters">
                    <ul>
                      <li>
                        {/* <Link to="#" className={'btn-primary'}>Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </Link> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Sort</option>
                          <option value={'ASC'}>Alphabetic A-Z</option>
                          <option value={'DESC'}>Alphabetic Z-A</option>
                        </Form.Select>
                      </li>
                      <li>
                        {/* <Link to="#" className={'btn-primary'}>Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </Link> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Filter</option>
                          <option value={'Last Modified'}>Last Modified</option>
                          <option value={'Oldest'}>Oldest</option>
                          <option value={'Newest'}>Newest</option>
                        </Form.Select>
                      </li>
                      {/* <li onClick={handleShow}>
                        <Link to="#" >Add <img src="images/add-icon.png" alt="" width="15" />
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category" >
                    <ul style={{ justifyContent: 'flex-start' }}>
                      <li >
                        <Link to="/organization-dashboard">Employees Volunteering As Tutors</Link>
                      </li>
                      <li className="active">
                        <Link to="/organization-admins">Admins</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          {/* <th scope="col">ID</th> */}
                          {/* <th scope="col">Role</th> */}
                          {/* <th scope="col">Gender</th> */}
                          <th scope="col">Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {admins?.map((admin,index)=><tr className='selected' onClick={() => {
                          setIndex(index)
                          selectAdmin(admin)
                          }}>
                          <td>
                            <img src={admin?.user_details[0]?.propic ? getResourceUrl(admin?.user_details[0]?.propic) : "default_logos/no_profile_img.png"} alt="" width="44" className="rounded me-2" />
                            {getMaskedText(admin?.user_details[0]?.name,'name') }
                          </td>
                          {/* <td>{admin?._id}</td> */}
                          {/* <td>{admins?.user_details[0]?.role}</td> */}
                          <td>{getMaskedText(admin?.email,'email')}</td>
                        </tr>)}
                        {/* <tr>
                          <td>
                            <img src="images/avatar1.png" alt="" width="44" className="rounded me-2" />
                            Andy Fay Ruben
                          </td>
                          <td>564789</td>
                          <td>Manager</td>
                          <td>Female</td>
                          <td>jane@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar3.png" alt="" width="44" className="rounded me-2" />
                            Andy Fay Ruben
                          </td>
                          <td>642745</td>
                          <td>HR</td>
                          <td>Male</td>
                          <td>ruben@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar2.png" alt="" width="44" className="rounded me-2" />
                            Andy Fay Ruben
                          </td>
                          <td>164782</td>
                          <td>Content Writer</td>
                          <td>Non-Binary</td>
                          <td>sandy@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar1.png" alt="" width="44" className="rounded me-2" />
                            Andy Fay Ruben
                          </td>
                          <td>764419</td>
                          <td>Accountant</td>
                          <td>Female</td>
                          <td>carla@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar3.png" alt="" width="44" className="rounded me-2" />
                            Andy Fay Ruben
                          </td>
                          <td>642745</td>
                          <td>Accountant</td>
                          <td>Male</td>
                          <td>ruben@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar2.png" alt="" width="44" className="rounded me-2" />
                            Jessica R. Brownlee
                          </td>
                          <td>164782</td>
                          <td>Accountant</td>
                          <td>Non-Binary</td>
                          <td>sandy@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar3.png" alt="" width="44" className="rounded me-2" />
                            Jeffrey S. Douglas
                          </td>
                          <td>642745</td>
                          <td>Content Writer</td>
                          <td>Male</td>
                          <td>ruben@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar1.png" alt="" width="44" className="rounded me-2" />
                            Jessica R. Brownlee
                          </td>
                          <td>164782</td>
                          <td>Content Writer</td>
                          <td>Non-Binary</td>
                          <td>sandy@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar2.png" alt="" width="44" className="rounded me-2" />
                            Debra A. Bragg
                          </td>
                          <td>764419</td>
                          <td>HR</td>
                          <td>Female</td>
                          <td>carla@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar1.png" alt="" width="44" className="rounded me-2" />
                            Jessica R. Brownlee
                          </td>
                          <td>164782</td>
                          <td>Manager</td>
                          <td>Non-Binary</td>
                          <td>sandy@mather.edu</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name}  </h5>
                  <p>Status: {user?.status}</p>
                </div>
                <Logout url={'/organization'} />
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {selectedAdmin ? <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={selectedAdmin?.user_details[0]?.propic ? getResourceUrl(selectedAdmin?.user_details[0]?.propic) : "default_logos/no_profile_img.png"} alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item><Link to={{ pathname: "/organization-teacher", state: selectedAdmin }}>View Profile</Link></Dropdown.Item>
                      <Dropdown.Divider /> */}
                      <Dropdown.Item href="#"><Link className={'dropdown-item'} to={{ pathname: '/organization-edit-user', state: { ...selectedAdmin, ...removeField(selectedAdmin?.user_details[0],['_id','user_role']), user_role: 'company_user', user_sf_id: selectedAdmin?.user_id } }}>Edit Account</Link></Dropdown.Item>
                      {/* <Dropdown.Divider />
                      <Dropdown.Item href="#">Delete Account</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{getMaskedText(selectedAdmin?.user_details[0]?.name,'name')}</h5>
                  {/* <h6>ID: {selectedAdmin?.company_code}</h6> */}
                  <a href="javascript:void(0)" className={`btn btn-${selectedAdmin?.user_details[0]?.status == 'Active' ? 'primary' : 'secondary'} secondary solid btn-sm no-shadow d-block`}  >Status: {selectedAdmin?.user_details[0]?.status}</a>                </div>
                <Form>
                  <Row>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value={getMaskedText(selectedAdmin?.user_details[0]?.name,'name')} />
                    </Form.Group>
                    {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value={selectedAdmin?.last_name} />
                    </Form.Group> */}
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value={selectedAdmin?.department} />
                    </Form.Group>
                    {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value="5835 N Lincoln Ave, Chicago, IL 60659, USA" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey">
                        <option>State</option>
                        <option value="1" selected>Illinois</option>
                        <option value="2">Illinois</option>
                        <option value="3">Illinois</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="60659" />
                    </Form.Group> */}
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="tel" value={getMaskedText(selectedAdmin?.phone,'phone')} />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="email" value={getMaskedText(selectedAdmin?.email,'email')} />
                    </Form.Group>
                    <Col md={12}>
                      <Form.Group className="iagree mb-2" controlId="formbymail">
                        <Form.Check className="text-grey" type="checkbox" label="Contact Me By Email" checked={selectedAdmin.contact_by[0] == 'email'} />
                      </Form.Group>
                      <Form.Group className="iagree mb-2" controlId="formbyphone">
                        <Form.Check className="text-grey" type="checkbox" label="Contact Me By Phone" checked={selectedAdmin.contact_by[0] == 'phone'} />
                      </Form.Group>
                    </Col>
                    {selectedAdmin?.user_details[0]?.status == 'Inactive'&&<><Col md="6" className="text-center mt-4">
                      <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={() => changeUserStatus(selectedAdmin?.user_id, 'Active')}>Admit</Button>
                    </Col>
                    <Col md="6" className="text-center mt-4">
                      <Button className="px-4 btn-pill" variant="secondary" onClick={() => changeUserStatus(selectedAdmin?.user_id, 'Declined')}>Decline</Button>
                    </Col></>}
                  </Row>
                </Form>
              </div>
              {/* <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={selectedAdmin?.user_details[0]?.propic ? selectedAdmin?.user_details[0]?.propic : "images/avatar.jpg"} alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item><Link to={{ pathname: "/organization-teacher", state: selectedAdmin }}>View Profile</Link></Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#"><Link to={{ pathname: '/organization-edit-user', state: { ...selectedAdmin, status: selectedAdmin?.user_details[0]?.status, user_role: 'company', name: selectedAdmin?.user_details[0]?.name } }}>Edit Account</Link></Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#">Delete Account</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{selectedAdmin?.user_details[0]?.name}</h5>
                  <h6>ID: 567872</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${selectedAdmin?.phone}`} itemRef='ghgf'>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      <span className='show-phone'>{selectedAdmin?.phone}</span>
                    </li>
                    <li>
                      <a href={`mailto:${selectedAdmin?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className={`btn btn-${selectedAdmin?.user_details[0]?.status == 'Active' ? 'primary' : 'secondary'} secondary solid btn-sm no-shadow d-block`}  >Status: {selectedAdmin?.user_details[0]?.status}</a>
                </div>
                <div className="about-teacher">
                  <h4>About:</h4>
                  <p>
                    {selectedAdmin?.about}
                  </p>
                  <h4>Experience:</h4>
                  <p>
                    {selectedAdmin?.tutoring_experience}
                  </p>
                  <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p>
                  <h4>Email:</h4>
                  <p>{selectedAdmin?.email}</p>
                  <h4>Subjects Interested In Teaching:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      {selectedAdmin?.subjects?.map(subject => <li>{subject}</li>)}
                      <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li>
                    </ul>
                  </div>
                </div>
                {selectedAdmin?.user_details[0]?.status == 'Inactive' ? <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow same-width" variant="primary" onClick={() => changeUserStatus(selectedAdmin?.user_details[0]?._id, 'Active')}>Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill same-width" variant="secondary" onClick={() => changeUserStatus(selectedAdmin?.user_details[0]?._id, 'Inactive')}>Decline</Button>
                  </Col>
                </Row> : null}
              </div> */}
            </div> : null}
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Employees Volunteering As Tutors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Employees First Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Employees Last Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Employees Contact Number" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Employees Email" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="ABC Road" placeholder="Area" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="0621457" placeholder="Employees Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="5835 N LincoIn Ave" placeholder="Address" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Chicago" placeholder="City" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Illinois" placeholder="State" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="60659" placeholder="Zip Code" />
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill" variant="primary">Send Request</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default OrgAdmin
