import React, { useEffect, useState } from 'react'
import {
  Container,
  Col,
  Form,
  Row,
  Modal,
  Button,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { Link, useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import OtpInput from 'react-otp-input';
import ActionButton from '../ActionButton';
import { staticData } from '../../helpers/data';

function ParSignupStepOne({ nextStep, handleImage, handleFormData, handleSchool, values }) {
  const [inputEmail, setInpEmail] = useState('')
  const [error2, setError2] = useState(false);
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState();
  const [message, setMessage] = useState(null);
  let { id } = useParams();
  const onChange = (value) => {
    setOtp(value);
  }

  const inputfocus = (elmnt, index) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus()
      }
    }

    // if (isNaN(elmnt.target.value)) return false;
    ;

    setOtp(prevState => ({
      ...prevState,
      [index]: elmnt.target.value
    }));

  }

  const submitFormData = (e) => {
    e.preventDefault();
    if (otp && otp?.length === 6) {
      // School.verifyOtp({ otp: otp, token: id }, 'guardian/verify_gurdian_otp').then(
      //   (data) => {
      //     if (data.error === 0) {

      //       // fetchData()

      //     }
      //     

      //   },
      //   (error) => {
      //     NotificationManager.error(error.toString(), 'error!', 2000);
      //     setError(error.toString())
      //   }
      // )
      School.verifyOtp({ otp: otp, email: values.email }).then(
        (data) => {
          if (data.error === 0) {
            handleSchool('otp_verified', true)
            NotificationManager.success(data.message, 'success!', 2000);
            setMessage(data.message)
            nextStep();
          }


        },
        (error) => {
          NotificationManager.error(error.toString(), 'error!', 2000);
          setError(error.toString())
        }
      )
    } else {
      NotificationManager.error('please enter six digit valid OTP', 'Error!', 2000);
    }

  };
  // useEffect(() => {
  //   if(id !=''){
  //     resendOtp()
  //   }
  // }, [id])

  const resendOtp = () => {
    // e.preventDefault();
    if (values?.email !== '') {
      School.sendEmail({ email: values?.email, in_register: true }).then(
        (data) => {
          if (data.error === 0) {
            NotificationManager.success(data.message, 'success!', 2000);
            // setMessage(data.message)

          }


        },
        (error) => {
          // setError(error.toString())
          // NotificationManager.success(error.toString(), 'Error!', 2000);
        }
      )
      // nextStep();

    } else {
      NotificationManager.success('Email is required', 'Error!', 2000);
    }
    // if (id !== '') {
    //   School.post({ token: id }, 'guardian/fetch_students_info').then(
    //     (data) => {
    //       if (data.error === 0) {
    //         NotificationManager.success(data.message, 'success!', 2000);
    //       }
    //       

    //     },
    //     (error) => setError(error.toString())
    //   )
    // } else {
    //   NotificationManager.error('Email is required', 'error!', 2000);
    // }

  };
  const validateForm = () => {

    let fields = values;
    let errors = {};
    let formIsValid = true;

    // if (!fields["first_name"] || fields["first_name"].trim() == '') {
    //   formIsValid = false;
    //   errors["first_name"] = "*Please Select Your First Name.";
    // }
    // if (!fields["last_name"] || fields["last_name"].trim() == '') {
    //   formIsValid = false;
    //   errors["last_name"] = "*Please Select Your Last Name.";
    // }
    // if (!fields["address"] || fields["address"].trim() == '') {
    //   formIsValid = false;
    //   errors["address"] = "*Please add your address";
    // }
    // if (!fields["guardian_name"] || fields["guardian_name"].trim() == '') {
    //   formIsValid = false;
    //   errors["guardian_name"] = "*Please add your name";
    // }
    if (inputEmail == '') {
      formIsValid = false;
      errors["email"] = "*Please enter your email-ID.";
    }

    else if (typeof inputEmail !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(inputEmail)) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }
    // if (!fields["guardian_email"]) {
    //   formIsValid = false;
    //   errors["guardian_email"] = "*Please enter your guardian email-ID.";
    // }

    // if (typeof fields["guardian_email"] !== "undefined") {
    //   //regular expression for email validation
    //   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    //   if (!pattern.test(fields["guardian_email"])) {
    //     formIsValid = false;
    //     errors["guardian_email"] = "*Please enter valid guardian email-ID.";
    //   }
    // }
    // if (!fields["phone"]) {
    //   formIsValid = false;
    //   errors["phone"] = "*Please enter your phone.";
    // }

    // if (typeof fields["phone"] !== "undefined") {
    //   //regular expression for phone validation
    //   var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
    //   if (!pattern.test(fields["phone"])) {
    //     formIsValid = false;
    //     errors["phone"] = "*Please enter valid phone.";
    //   }
    // }
    // if (!fields["password"] || fields["password"].trim() == '') {
    //   formIsValid = false;
    //   errors["password"] = "Please Enter your Password.";
    // }

    // if (!fields["confirmpassword"] || fields["confirmpassword"].trim() == '') {
    //   formIsValid = false;
    //   errors["confirmpassword"] = "Please Enter your Confirm Password.";
    // }
    // if (typeof fields["password"] !== "undefined" && typeof fields["confirmpassword"] !== "undefined") {

    //   if (fields["password"] != fields["confirmpassword"]) {
    //     formIsValid = false;
    //     errors["password"] = "Confirm password is Not Matched";
    //   }
    // }

    // if (!fields["parental_consent"]) {
    //   formIsValid = false;
    //   errors["parental_consent"] = "*Please give your consent.";
    // }
    setError2(errors)
    return formIsValid;


  }
  const sendOtp = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (inputEmail == values.email) {
        resendOtp()
        const form = e.currentTarget;
        // nextStep();
        handleClose()

      } else {
        setError2({ email: '*Wrong Email-ID!' })
      }

    }

  };
  const fetchData = () => {
    School.post({ token: id }, 'guardian/get_students_by_token').then(
      (data) => {
        if (data.error === 0) {
          handleSchool('student_id', data.data._id)
          handleSchool('first_name', data.data.first_name)
          handleSchool('last_name', data.data.last_name)
          handleSchool('email', data.data.email)
          handleSchool('phone', data.data?.phone)
          handleSchool('address', data.data?.address)
          handleSchool('guardian_email', data.data?.guardian_email)
          nextStep();
        }


      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
  }
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '25%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Pending Activation</h6>
                    <h6>25% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>{staticData?.verbiage?.parent[1]?.heading}</h4>
                    <p>{staticData?.verbiage?.parent[1]?.text}</p>
                    <div className="signup-step">
                      <ul>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      {/* <div className="d-block alert alert-danger d-flex align-items-center" role="alert">
                          <img src="/images/cross.png" alt="" width="20"/>
                          <div className="ms-2">
                            Code entered is incorrect. Please enter again or request a new code 
                          </div>
                        </div> */}
                      <div className="verify-account">
                        <img src="images/lock-icon.png" alt="" width="76" />
                        <h4 className="my-3">Enter The Code</h4>
                        <p>
                          Protecting your account is our top priority. An email with the verification code <br />
                          has been sent. Please confirm your account by entering the authorization<br /> code sent to the email registered with us.
                        </p>
                        <div className="new-otp-field">
                          <OtpInput
                            value={otp}
                            containerStyle="otp-input"
                            inputStyle="form-control"
                            onChange={onChange}
                            numInputs={6}
                            isInputNum={true}
                          />
                        </div>
                        <p>
                          It may take a minute to receive your code.<br />
                          Haven’t received it? Resend a new code <Link to="#" onClick={handleShow}>Resend a new code</Link>
                        </p>
                      </div>
                      <div className="signup-back-btn">
                        <Link to="/parents" className="btn btn-link">Back</Link>
                        <ActionButton type='submit' className={'btn btn-primary btn-pill px-4'} requiredFields={[otp?.length == 6 ? otp : '']} title={'Continue'} />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Continue</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Request Verification Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-text text-center">
            Protecting your account is our top priority. An email with the verification code will be sent to the email shared by your child at time of registration.
          </p>
          <Form onSubmit={sendOtp}>
            <Form.Group className="my-4">
              <Form.Control name='email' className="bg-white" type="text" placeholder="Enter Your Email" value={inputEmail} onChange={e => setInpEmail(e.target.value)} />
              <span className="err">{error2?.email}</span>
            </Form.Group>
            <div className="text-center">
              <Button variant="primary" type='submit' className="px-5">
                Send
              </Button>
            </div>
          </Form>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default ParSignupStepOne
