import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link, useParams } from "react-router-dom";
import AdminSideMenu from "../AdminSideMenu";
import Autocomplete from 'react-autocomplete'
import AdminLayout from './AdminLayout';
import { Admin } from '../../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import config from "./../../../config";
import { School } from '../../../Services/School';
import ActionButton from '../../ActionButton';
import { FecthData } from '../../../Services/FetchData';
import FieldErrors from '../../../Services/FieldErrors';
import { getResourceUrl } from '../../../Services/CommanFunctions';

function AdminDashboard() {

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setSchlFormData({ ...schlFormData, logo: null })
    setValue('')
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const [showSchModal, setShowSchModal] = useState(false);
  const handleSchModalClose = () => setShowSchModal(false);
  const handleSchModalShow = () => setShowSchModal(true);
  const [value, setValue] = useState('');
  const [school, setSchool] = useState({});
  const [schools, setSchools] = useState([]);
  const [selectedSchool, selectSchool] = useState();
  const [schoolUser, setSchoolUser] = useState()
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'))
  const [sort, setSort] = useState('ASC')
  const [filter, setFilter] = useState('')

  const [schlFormData, setSchlFormData] = useState({
    school_name: "",
    school_code: "",
    school_dist: "",
    // low_grade: "",
    // high_grade: "",
    county: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    logo: null,
    // zip_last4: "",
    // phone: "",
    // locale_code: "",
    // locale: "",
    // charter: "",
    // magnet: "",
    created_by: user?._id
  })

  // const [schlFormData, setSchlFormData] = useState({
  //   school: "",
  //   school_id: "",
  //   school_name: "",
  //   school_code: "",
  //   school_street: "",
  //   school_city: "",
  //   school_state: "",
  //   school_zip: "",
  //   logo: "",
  //   logo_url: "",
  //   display_logo: "yes",
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   phone: "",
  //   contact_by: [],
  //   accept_privacy_policy: false
  // })

  const setSchoolInfo = (item) => {
    setSchlFormData({
      ...schlFormData,
      school_list_id: item._id,
      school_name: item.school_name,
      school_code: item.school_code,
      street: item.street,
      city: item.city,
      state: item.state,
      zip_code: item.zip_code
    })
  }
  const submitSchlData = (e) => {
    e.preventDefault()
    let formData = new FormData();
    Object.entries(schlFormData).map(([key, value]) => {
      formData.append(key, value);
    })
    FecthData.post(formData, 'school/create').then(
      (data) => {
        if (!data.errors) {
          // console.log(message)
          NotificationManager.success(data.message, 'success!', 2000);
          handleClose()
          getDasyaSchools()
          // setMessage(data.message)
          // history.push('/school');
        } else {
          NotificationManager.error(data.message.toString(), 'error!', 2000);
        }
      },

      (error) => {

        if (error.error == 1) {

        }
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error)
      }
    )
  }
  const submitData = (e) => {
    e.preventDefault()
    let formData = new FormData();
    Object.entries(schlFormData).map(([key, value]) => {
      if (key !== 'contact_by') {
        formData.append(key, value);
      } else {
        formData.append(key, value.toString());
      }

    })
    School.registerSchool(formData).then(
      (data) => {
        if (!data.errors) {
          // console.log(message)
          NotificationManager.success(data.message, 'success!', 2000);
          // setMessage(data.message)
          // history.push('/school');
        } else {
          NotificationManager.error(data.message.toString(), 'error!', 2000);
        }
      },

      (error) => {

        if (error.error == 1) {

        }
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error)
      }
    )
  }
  const getDasyaSchools = () => {
    FecthData.getData('school/get_all_school?page=' + 0 + '&filter=' + filter).then(
      (data) => {
        setSchool(data?.data)
        // selectSchool(data?.data[0])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }
  const getAllSchools = (search) => {
    FecthData.getData('school/get_school_list?search=' + search).then(
      (data) => {

        setSchools(data?.data)
        // selectSchool(data?.data[0])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }

  useEffect(() => {
    if (value.length >= 3 || !value)
      getAllSchools(value)
  }, [value]);
  useEffect(() => {
    getDasyaSchools()
  }, [filter]);
  const fetchSchoolUser = () => {
    if (selectedSchool) {
      FecthData.getData('school_user/get_school_user_by_school_id/' + selectedSchool._id).then(
        (data) => setSchoolUser(data?.data),
        (error) => {
          // NotificationManager.error(data?.message, 'error!', 2000)
        }
      )
    }
  }
  useEffect(() => {
    fetchSchoolUser()
  }, [selectedSchool])
  // 
  return (
    <>
      <AdminLayout school={selectedSchool} schoolUser={schoolUser} refreshCard={fetchSchoolUser} userDetailPath='/admin/school/students/' showUserCard={selectedSchool}>
        <Row>
          <Col md={12}>
            <Form.Group className="form-group mb-2">
              <Form.Control type="text" placeholder="Search" className="search-input" />
              <Button variant="primary" type="submit" className="search-btn">
                <img src="images/search-icon.png" alt="" width="22" />
              </Button>
            </Form.Group>
          </Col>
          <Col md={6} className="mt-4">
            <h3 className="text-muted mb-0">Schools</h3>
          </Col>
          <Col md={6} className="mt-4">
            <div className="filters">
              <ul>
                <li>
                  {/* <a href="javascript:void(0)" className='btn-primary' >Sort <img src="images/sort-icon.png" alt="" width="17" />
                    
                  </a> */}
                  <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                    <option selected disabled>Sort</option>
                    <option value={'ASC'}>Alphabetic A-Z</option>
                    <option value={'DESC'}>Alphabetic Z-A</option>
                  </Form.Select>
                </li>
                <li>
                  {/* <a href="javascript:void(0)" className='btn-primary'>Filter <img src="images/filter-icon.png" alt="" width="13" />
                  </a> */}
                  <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                    <option selected disabled>Filter</option>
                    <option value={'Last Modified'}>Last Modified</option>
                    <option value={'Oldest'}>Oldest</option>
                    <option value={'Newest'}>Newest</option>
                  </Form.Select>
                </li>
                <li onClick={handleShow}>
                  <a href="javascript:void(0)" className='btn-primary'>Add <img src="images/add-icon.png" alt="" width="15" />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          {school && school.length > 0 && school?.map((content, index) => (
            <Col md={3} key={index} onClick={() => {
              selectSchool(content)
            }}>
              <div className="school-box">
                <Link to={'#'}>
                  <div className="school-box-logo">
                    <img src={content?.logo ? getResourceUrl(content?.logo) : "default_logos/school-logo.png"} alt="" />
                  </div>
                  <h4>{content.school_name}</h4>
                </Link>
              </div>
            </Col>
          ))}
          {!school && <Col md={12} >No school Found</Col>}
        </Row>

      </AdminLayout >
      <Modal show={showSchModal} onHide={handleSchModalClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add School</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitSchlData}>
            <div className="modal-form">
              <Row>
                {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="Search by school name, city or zip code" />
              </Form.Group> */}
                {/* <Form.Group className="form-group mb-3" style={{ zIndex: 2 }}>
                  {/* <Form.Control type="text" placeholder="Search" /> 
                  <Autocomplete className="search"
                    items={school || []}
                    shouldItemRender={(item, value
                    ) => item.school_name.toLowerCase()
                      .indexOf(value.toLowerCase()) > -1}
                    getItemValue={item => item.school_name}
                    renderItem={(item, isHighlighted) =>
                      <div style={{
                        background: isHighlighted ?
                          '#eee' : 'white',
                        padding: '5px 10px',
                      }}
                        key={item.id}>
                        {item.school_name}
                      </div>
                    }
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    onSelect={(val, item) => {
                      setValue(val)
                      setSchoolInfo(item)
                    }}
                    inputProps={{
                      style: {
                        width: '100%',
                        background: '#E9ECF4',
                        // boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                        width: '100%',
                        color: '#000',
                        borderRadius: 10,
                        minHeight: 52,
                        border: '1px solid #E9ECF4',
                        fontSize: 14,
                        paddingLeft: 15,
                        paddingRight: 15,
                      },
                      placeholder: 'Search by school name, city or zip code'
                    }}
                    wrapperStyle={{
                      width: '100%',
                    }}
                  />
                  <Button variant="primary" type="submit" className="search-btn" style={{ right: 18 }}>
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group> */}
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="School Name" value={schlFormData.school_name} onChange={(e) => setSchlFormData({ ...schlFormData, school_name: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="School Code" value={schlFormData.school_code} onChange={(e) => setSchlFormData({ ...schlFormData, school_code: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Low Grade*" value={schlFormData.low_grade} onChange={(e) => setSchlFormData({ ...schlFormData, low_grade: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="High Grade*" value={schlFormData.high_grade} onChange={(e) => setSchlFormData({ ...schlFormData, high_grade: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Phone*" value={schlFormData.phone} onChange={(e) => setSchlFormData({ ...schlFormData, phone: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Magnet*" value={schlFormData.magnet} onChange={(e) => setSchlFormData({ ...schlFormData, magnet: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Charter" value={schlFormData.charter} onChange={(e) => setSchlFormData({ ...schlFormData, charter: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.street} placeholder="Street*" onChange={(e) => setSchlFormData({ ...schlFormData, street: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.city} placeholder="City" onChange={(e) => setSchlFormData({ ...schlFormData, city: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.school_dist} placeholder="School Dist" onChange={(e) => setSchlFormData({ ...schlFormData, school_dist: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.state} placeholder="State" onChange={(e) => setSchlFormData({ ...schlFormData, state: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.county} placeholder="County" onChange={(e) => setSchlFormData({ ...schlFormData, county: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.zip_code} placeholder="Zip Code" onChange={(e) => setSchlFormData({ ...schlFormData, zip_code: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.locale} placeholder="Local" onChange={(e) => setSchlFormData({ ...schlFormData, locale: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.locale_code} placeholder="Local Code" onChange={(e) => setSchlFormData({ ...schlFormData, locale_code: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.zip_last4} placeholder="Zip last 4" onChange={(e) => setSchlFormData({ ...schlFormData, zip_last4: e.target.value })} />
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  <ActionButton title={'Send Request'} type={'submit'} requiredFields={[schlFormData.magnet, schlFormData.county, schlFormData.school_name, schlFormData.low_grade, schlFormData.high_grade, schlFormData.phone, schlFormData.street, schlFormData.charter, schlFormData.city, schlFormData.school_dist, schlFormData.state, schlFormData.zip_code, schlFormData.zip_last4, schlFormData.locale, schlFormData.locale_code, schlFormData.school_code]} />
                  {/* <Button className="px-4 btn-pill" variant="primary" onClick={submitData}>Send Request</Button> */}
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add School</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitSchlData}>
            <div className="modal-form">
              <Row>
                {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Control type="text" placeholder="Search by school name, city or zip code" />
                </Form.Group> */}
                <Form.Group className="form-group mb-3">
                  <div className="upload-resource">
                    <img src={schlFormData.logo ? URL.createObjectURL(schlFormData.logo) : "images/camera-icon.png"} alt="" width="78" />
                    <input type="file" onChange={(e) => setSchlFormData({ ...schlFormData, logo: e.target.files[0] })} />
                  </div>
                  <FieldErrors errors={error} field="logo" />
                </Form.Group>
                <Form.Group className="form-group mb-3" style={{ zIndex: 1 }}>
                  {/* <Form.Control type="text" placeholder="Search by school name.." /> */}
                  <Autocomplete className="search"
                    items={schools || []}
                    shouldItemRender={(item, value
                    ) => item?.school_name.toLowerCase()
                      .indexOf(value.toLowerCase()) > -1}
                    getItemValue={item => item?.school_name}
                    renderItem={(item, isHighlighted) =>
                      <div style={{
                        background: isHighlighted ?
                          '#eee' : 'white',
                        padding: '5px 10px',
                      }}
                        key={item.id}>
                        {item?.school_name}
                      </div>
                    }
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    onSelect={(val, item) => {
                      setValue(val)
                      setSchoolInfo(item)
                    }}
                    inputProps={{
                      style: {
                        width: '100%',
                        background: '#E9ECF4',
                        // boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                        width: '100%',
                        color: '#000',
                        borderRadius: 10,
                        minHeight: 52,
                        border: '1px solid #E9ECF4',
                        fontSize: 14,
                        paddingLeft: 15,
                        paddingRight: 15,
                      },
                      // readOnly: true,
                      placeholder: 'Search by school name, city or zip code(Enter at least 3 word)'
                    }}
                    wrapperStyle={{
                      width: '100%',
                    }}
                  />
                  <Button variant="primary" type="button" className="search-btn" style={{ right: 18 }}>
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
                {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Point of Contact First Name*" value={schlFormData.first_name} onChange={(e) => setSchlFormData({ ...schlFormData, first_name: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Point of Contact Last Name*" value={schlFormData.last_name} onChange={(e) => setSchlFormData({ ...schlFormData, last_name: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Contact number*" value={schlFormData.phone} onChange={(e) => setSchlFormData({ ...schlFormData, phone: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" placeholder="Email*" value={schlFormData.email} onChange={(e) => setSchlFormData({ ...schlFormData, email: e.target.value })} />
                </Form.Group> */}
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="School Name" value={schlFormData.school_name} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.school_code} placeholder="School Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.street} placeholder="Address" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.city} placeholder="City" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.state} placeholder="State" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.zip_code} placeholder="Zip Code" />
                </Form.Group>
                {/* <Col md={12} className='m-2'>
                  <Form.Group className="form-group iagree mb-3" controlId="formbymail">
                    <Form.Check className="text-grey" name="contact_by" type="checkbox" onChange={(e) => handleContactBy(e)} checked={schlFormData.contact_by.includes('email')} label="Contact Me By Email" value="email" />
                  </Form.Group>
                  <Form.Group className="form-group iagree mb-3" controlId="formbyphone">
                    <Form.Check className="text-grey" name="contact_by" type="checkbox" onChange={(e) => handleContactBy(e)} checked={schlFormData.contact_by.includes('phone')} label="Contact Me By Phone" value="phone" />
                  </Form.Group>
                </Col> */}
                <Col md="12" className="text-center mt-2">
                  <ActionButton title={'Add school'} type={'submit'} requiredFields={[schlFormData.school_id]} />
                  {/* <Button className="px-4 btn-pill" variant="primary" onClick={submitData}>Send Request</Button> */}
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminDashboard
