import React from 'react'
import {
  Container,
  Nav,
  Navbar,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

function Header(props) {

  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <div className="logo">
              <img src="/images/logo.png" alt="" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {props.isAdmin ?
                <>
                  <NavLink exact activeClassName='active' className="nav-link" to="/">Student</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/tutor">Tutor</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/parents">Parent/Guardian</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/school">School</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/organization">Organization</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/facilitator">Facilitator</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/admin">Admin</NavLink>
                </>
                :
                <>
                  <NavLink exact activeClassName='active' className="nav-link" to="/">Student</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/tutor">Tutor</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/parents">Parent/Guardian</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/school">School</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/organization">Organization</NavLink>
                  <NavLink activeClassName='active' className="nav-link" to="/facilitator">Facilitator</NavLink>
                </>}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
