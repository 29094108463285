import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import config from "./../../config";
import { School } from '../../Services/School';
import { FecthData } from '../../Services/FetchData';
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';
import SessionCalendar from './SessionCalendar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SessionForm from '../Session/SessionForm';
import { UTCToSpecificTimeZone, formatDate, timeFormate, utcToLocal } from '../../helpers/utils';
import TutorBlock from '../common/TutorBlock';
import ActionButton from '../ActionButton';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';

function Dashboard() {

  const selectedDay = (index, val) => {
    // console.log(val);
    const date = formatDate(new Date(val)) 
    // selectDate(date)
    // var date = new Date(val)
    let data = [...inputSlot];
    data[index].date = date
    setInputSlot(data);

    // let scheduleDays = [...scheduleDay];
    // scheduleDays[index] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][val.getDay()];
    // setScheduleDay(scheduleDays);

    // let scheduleDates = [...scheduleDate];
    // scheduleDates[index] = [val].toLocaleString('default', { month: 'short', day: "2-digit" });
    // setScheduleDate(scheduleDates);

    // let datezz = [...datez];
    // datezz[index] = formatDate(val);
    // setDatez(datezz);
    // console.log(index, val)
    // handleChangeSetData(index, 'date', formatDate(val))
    if (inputSlot[index].subject != '' && inputSlot[index].type_of_tutoring_session != '' && inputSlot[index].date != '' && inputSlot[index]?.start_time != '' && inputSlot[index]?.end_time != '') {
      getTutorSession(index, inputSlot[index]?.subject, user?.student?.grade_id?._id, inputSlot[index]?.type_of_tutoring_session, inputSlot[index]?.date, inputSlot[index]?.start_time, inputSlot[index]?.end_time)
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error1, setError1] = useState([]);
  // const [selectedDate, selectDate] = useState(formatDate(new Date()))
  const [value, setValue] = useState('');
  const [sessions, setSessions] = useState([])
  const [subject, setSubject] = useState([]);
  const [studSubjects, setStudSubjects] = useState([]);
  const [studAssignmets, setStudAssignments] = useState([]);
  const [studFavTutors, setStudFavTutors] = useState([]);
  const [studTutors, setStudTutors] = useState([]);
  const [noSessionMsg,setNoSessionMsg]=useState('')
  const user = JSON.parse(localStorage.getItem('user'))
  const history = useHistory()
  const [inputSlot, setInputSlot] = useState([
    { recurring_option: '', date: '', subject: '', type_of_tutoring_session: '', start_time: '10:00', end_time: '11:00', student_id: user?.student?._id, current_day_sessions: [], upcoming_days_sessions: [], tutor_sessions: [], tutor_session_id: '' }
  ])
  const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const handleChange = (index, event) => {
    // setValue(event.target.name,event.target.value) 
    let data = [...inputSlot];
    data[index][event.target.name] = event.target.value;
    setInputSlot(data);
    if (inputSlot[index].subject != '' && inputSlot[index].type_of_tutoring_session != '' && inputSlot[index].date != '' && inputSlot[index]?.start_time != '' && inputSlot[index]?.end_time != '') {
      getTutorSession(index, inputSlot[index].subject, user?.student?.grade_id?._id, inputSlot[index]?.type_of_tutoring_session, inputSlot[index]?.date, inputSlot[index]?.start_time, inputSlot[index]?.end_time)
    }
  }
  const getTutorSession = (index, subject, grade_id, type_of_tutoring_session, date, start_time, end_time) => {
    // var da1 = new Intl.DateTimeFormat('en-US').format(date)
    const reqData = {
      student_id:user?.student?._id,
      subject: subject,
      grade_id: grade_id,
      type_of_tutoring_session: type_of_tutoring_session,
      date: date,
      start_time: start_time,
      end_time: end_time
    }
    FecthData.postJsonData(reqData, 'tutor/get_tutor_sessions_by_student_filter').then(
      (data) => {
        const data1 = [...inputSlot]
        data1[index].current_day_sessions = data?.data?.current_day_sessions
        data1[index].upcoming_days_sessions = data?.data?.upcoming_days_sessions
        setInputSlot(data1)
        if(data?.data?.current_day_sessions.length==0 && data?.data?.upcoming_days_sessions.length==0){
          setNoSessionMsg('Currently there is no session available to join! Please try again later')
        }else if(data?.data?.current_day_sessions.length==0){
          setNoSessionMsg('Currently there is no session available on selected date! Please join next available session or try again later')
        }else{
          setNoSessionMsg('')
        }
      }
    )
  }
  const submitFormData = (e) => {
    e.preventDefault();
    School.post({ student_id: user?.student?._id, slot: inputSlot, send_email: false }, 'student/student_add_session').then(
      (data) => {
        if (data.error === 0) {
          NotificationManager.success(data.message, 'success!', 2000);
          handleClose()
          //setMessage(data.message)
          // nextStep()
          //  localStorage.setItem('student_subject', JSON.stringify(data.data))
          // history.push('/');
        }


      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        // setError(error.toString())
      }
    )
  }
  const validateForm = () => {

    let fields = inputSlot;
    let errors1 = [{}];
    let data = [...error1];
    let formIsValid = true;
    fields.map((field, index) => {
      let errors = [];
      if (!field["date"]) {
        formIsValid = false;
        errors["date"] = "*Please select date.";
      }
      // if (!field["recurring_option"]) {
      //   formIsValid = false;
      //   errors["recurring_option"] = "*Please select Recurring Option.";
      // }
      if (!field["subject"]) {
        formIsValid = false;
        errors["subject"] = "*Please select subject.";
      }
      // if (!field["session"]) {
      //   formIsValid = false;
      //   errors["session"] = "*Please select session.";
      // }
      // if (!field["start_time"]) {
      //   formIsValid = false;
      //   errors["start_time"] = "*Please select start time.";
      // }
      // if (!field["end_time"]) {
      //   formIsValid = false;
      //   errors["end_time"] = "*Please select end time.";
      // }
      if (!field["type_of_tutoring_session"]) {
        formIsValid = false;
        errors["type_of_tutoring_session"] = "*Please select tutoring session.";
      }
      data[index] = errors;
      setError1(data)
    })

    //setError1(errors)
    return formIsValid;


  }
  // const getStudentSessionsDateWise = () => {
  //   FecthData.postJsonData({ student_id: user?.student?._id, date: selectedDate }, 'student/get_student_session_by_student_id').then(
  //     (data) => setSessions(data?.data)
  //   )
  // }
  const getStudentSubjects = () => {
    FecthData.getData('student/get_student_selected_subject/'+user?.student?._id).then(
      (data) => setStudSubjects(data?.data)
    )
  }
  const getStudentAssignments = () => {
    FecthData.getData('student/get_student_assignments/' + user?.student?._id).then(
      (data) => setStudAssignments(data?.data)
    )
  }
  const getStudentFavTutor = () => {
    FecthData.getData('student/get_favourite_tutors/' + user?.student?._id).then(
      (data) => setStudFavTutors(data?.data)
    )
  }
  const getStudentTutors = () => {
    FecthData.getData('student/get_student_tutors/' + user?.student?._id).then(
      (data) => setStudTutors(data?.data)
    )
  }
  useEffect(() => {
    FecthData.getData('streem_and_subject/get_all_subject').then(
      (data) => {
        if (data.error === 0) {
          setSubject(data.data)
        }
      },
      (error) => {
        NotificationManager
          .error(error.toString(), 'error!', 2000);
        // setError(error.toString())
      }
    )
    getStudentSubjects()
    getStudentAssignments()
    getStudentFavTutor()
    getStudentTutors()
    console.log(UTCToSpecificTimeZone())
  }, [])
  // useEffect(() => {
  //     getStudentSessionsDateWise()
  // }, [selectedDate])


  // const studentSessions = _.filter(sessions, session => {
  //   if (session?.tutor_session_id) {
  //     if (session.tutor_session_id.date == new Date(selectedDate).toISOString()) {
  //       return session
  //     }
  //   }
  //   // console.log(session.tutor_session_id.date, new Date(selectedDate).toISOString())

  // })

  const likeTutor=(tutor_id)=>{
    FecthData.postJsonData({student_id:user?.student?._id, tutor_id:tutor_id},'student/make_favourite_tutor').then(res=>{
      getStudentFavTutor()
    })
  }
  const unLikeTutor=(favTutor)=>{
    console.log(favTutor?._id)
    FecthData.deleteAPI('student/unfavourite/'+favTutor?._id).then(res=>{
      getStudentFavTutor()
    })
  }
  const isTutorFav=(tutor_id)=>_.find(studFavTutors,(o)=>o?.tutor_id?._id==tutor_id)


  const logout = () => {
    localStorage.clear()
    history.push('/')
  }
  const testDate=(e)=>{
    e.preventDefault()
    var d=e.target[0].value
    var dat=new Date(d)
    fetch('').then(console.log)
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
                <div className="mt-4">
                  <h2 className="text-muted mb-0">{`Welcome back ${user.name}!`} </h2>
                  {/* <h6 className="text-muted mt-3">Preferred Subjects</h6> */}
                </div>
              </Col>
              {/* {studSubjects?.map(subject => <Col md={4}>
                <div className="dash-box">
                  <img src={subject?.icon_image ? subject.icon_image : "images/dash-box1.jpg"} alt="" />
                  <div className="dash-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>{subject?.title}</p>
                  </div>
                </div>
              </Col>)} */}
              {/* <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box1.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>ALGEBRA</p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box2.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Expressions & Equations</h4>
                    <p>ALGEBRA</p>
                  </div>
                </div>
              </Col> */}
              <Col md={4}>
                <div className="dash-box dash-box-text-blank mt-4" onClick={handleShow}>
                  <img className='' src="images/add-icon.png" alt="" width="30%" />
                  <h6 className="text-muted">Create A New Session</h6>
                </div>
              </Col>
              {/* <Col md={12}>
                <h6 className="text-muted mt-2">My Assignments</h6>
              </Col>
              {studAssignmets?.map(assignment => <Col md={3}>
                <div className="assignment-box text-purple">
                  <div className="folder-img">
                    <img src="images/folder-1.png" alt="" width="78" />
                  </div>
                  <h5>{assignment?.subject_id?.title}</h5>
                  <p>{assignment?.title}</p>
                </div>
              </Col>)}
              <Col md={3}>
                <div className="assignment-box text-purple">
                  <div className="folder-img">
                    <img src="images/folder-1.png" alt="" width="78" />
                  </div>
                  <h5>Algebra</h5>
                  <p>Linear equations, functions, & graphs.</p>
                </div>
              </Col>
              <Col md={3}>
                <div className="assignment-box text-yellow">
                  <div className="folder-img">
                    <img src="images/folder-2.png" alt="" width="78" />
                  </div>
                  <h5>Science</h5>
                  <p>The periodic table of elements</p>
                </div>
              </Col>
              <Col md={3}>
                <div className="assignment-box text-red">
                  <div className="folder-img">
                    <img src="images/folder-3.png" alt="" width="78" />
                  </div>
                  <h5>Physics</h5>
                  <p>Measurement & language of Physics</p>
                </div>
              </Col>
              <Col md={3}>
                <div className="assignment-box text-green">
                  <div className="folder-img">
                    <img src="images/folder-4.png" alt="" width="78" />
                  </div>
                  <h5>Geography</h5>
                  <p>Cultural geography</p>
                </div>
              </Col> */}
              <Col md={12}>
                <h6 className="text-muted mt-2">Favorite Tutors</h6>
              </Col>
              <Col md={12}>
                <div className="tutor-list">
                  {studFavTutors?.map(tutor =>


                    <TutorBlock tutor={tutor?.tutor_id} likeTutor={likeTutor} unLikeTutor={unLikeTutor} isTutorFav={isTutorFav}></TutorBlock>

                  )}



                  {/* <Link to="/tutor-list" className="tutor-item">
                    <div className="tutor-wrap">

                      <div className="tutor-avatar">
                        <img src="images/avatar.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <p>47 Hours Taught | Maths Professor, University Of Illinois</p>
                        <ul>
                          <li>
                            Algebra
                          </li>
                          <li>
                            Science
                          </li>
                          <li>
                            Physics
                          </li>
                          <li>
                            Geography
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tutor-action">
                      <ul>
                        <li className="active">
                          <a href="javascript:void(0)"><i className="fa fa-heart"></i></a>
                        </li>
                        <li><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                        </a>
                        </li>
                      </ul>
                    </div>
                  </Link>
                  <Link to="/tutor-list" className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <p>47 Hours Taught | Maths Professor, University Of Illinois</p>
                        <ul>
                          <li>
                            Algebra
                          </li>
                          <li>
                            Science
                          </li>
                          <li>
                            Physics
                          </li>
                          <li>
                            Geography
                          </li>
                          <li>
                            Calculus
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tutor-action">
                      <ul>
                        <li className="active">
                          <a href="javascript:void(0)"><i className="fa fa-heart"></i></a>
                        </li>
                        <li><a href="javascript:void(0)"><i className="fa fa-comment-o"></i>
                        </a>
                        </li>
                      </ul>
                    </div>
                  </Link> */}
                </div>
              </Col>
            </Row>
          </div>
          
          <div className="right">
            <div className="user-short-text">
              <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.student?.gender_avatar?.icon_image && <img src={getResourceUrl(user?.student?.gender_avatar?.icon_image)} width={'100%'} height={'100%'} alt="" />}{!user?.student?.gender_avatar?.icon_image && <img src="images/avatar2.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.student_profile?.school_name}</h5>
                  <p><small>ID: {user?.student?.student_code}</small></p>
                  <p>Academic Year: {user?.student?.academic_year}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <SessionCalendar />

          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton >
          <Modal.Title >Create A New Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitFormData}>
            <div className="modal-form">
              {inputSlot.map((element, index) => <><SessionForm mode={"dashboard"}
                inputSlot={inputSlot}
                selectedDay={selectedDay}
                timeFormate={timeFormate}
                handleChange={handleChange}
                noSessionMsg={noSessionMsg}
                error1={error1}
                subject={subject} />
                {/* <Row>
                  <Form.Group className="form-group mb-3" as={Col} md="12">
                    <Form.Select name='subject' onChange={e => handleChange(index, e)}>
                      <option>Select Subject*</option>
                      {subject.map(item => <option value={item._id}>{item.title}</option>)}

                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="form-group mb-3" as={Col} md="12">
                    <Form.Select name='type_of_tutoring_session' onChange={e => handleChange(index, e)}>
                      <option>Type of tutoring session*</option>
                      <option value="Synchronous Session">Synchronous Session</option>
                      <option value="Asynchronous Session">Asynchronous Session</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group mb-3" as={Col} md="12">
                    <Form.Select name='recurring_option' onChange={e => handleChange(index, e)}>
                      <option>Apply This Schedule To*</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Every Two Weeks">Every Two Weeks</option>
                      <option value="Monthly">Monthly</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group mb-3" as={Col} md="12">
                    <Form.Control type="date" placeholder="Select Date*" name='date' onChange={e => handleChange(index, e)} defaultValue={new Date()} />
                  </Form.Group>
                  {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="time" placeholder="Start Time*" name='start_time' onChange={e => handleChange(index, e)} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="time" placeholder="End Time*" name='end_time' onChange={e => handleChange(index, e)} />
                </Form.Group> 
                  <Form.Group className="form-group mb-3" as={Col} md="12">
                    <Form.Select name='tutor_session_id' onChange={e => handleChange(index, e)}>
                      <option>Type of session*</option>
                      {element.tutor_sessions.map(session => <option value={session._id}>{session.type_of_tutoring_session}</option>)}
                      {/* <option value="2">Session 2</option>
                    <option value="3">Session 3</option>
                    <option value="4">Session 4</option>
                    <option value="5">Session 5</option>
                    <option value="6">Session 6</option> 
                  </Form.Group>
                  {/* <div className="mb-3 applyschedule">
                  <Form.Check
                    inline
                    label={`Apply schedule to all  ${element?.date ? day[new Date(element?.date).getDay()] : day[new Date().getDay()]}`}
                    name="recurring_option"
                    type="radio"
                    id="one"
                    value={element?.date ? day[new Date(element?.date).getDay()] : day[new Date().getDay()]}
                    onChange={e => handleChange(index, e)}
                    checked
                  />
                  <Form.Check
                    inline
                    label={`Apply the schedule to ${element?.date ? new Date(element?.date).toLocaleString('default', { month: 'short', day: "2-digit" }) : new Date().toLocaleString('default', { month: 'short', day: "2-digit" })}`}
                    name="recurring_option"
                    type="radio"
                    id="two"
                    value={element?.date ? new Date(element?.date).toLocaleString('default', { month: 'short', day: "2-digit" }) : new Date().toLocaleString('default', { month: 'short', day: "2-digit" })}
                    onChange={e => handleChange(index, e)}
                  />
                </div> 
                  <Col md="12" className="text-center mt-2">
                    <Button className="px-4" variant="primary" type='submit'>Submit</Button>
                  </Col>
                </Row> */}
                <Col md="12" className="text-center mt-2">
                  {/* <ActionButton /> */}
                  <ActionButton type={'submit'} className={'btn btn-primary px-4'} title={'Submit'}
                    requiredFields={[inputSlot[0].date, inputSlot[0].start_time, inputSlot[0].end_time, inputSlot[0].subject, inputSlot[0].type_of_tutoring_session, inputSlot[0].tutor_session_id]} />
                  {/* <Button className="px-4" variant="primary" type='submit' >Submit</Button> */}
                </Col>
              </>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default Dashboard
