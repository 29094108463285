import config from "./../config";
import { history } from "./../helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export const Auth = {
  login,
  socialLogin,
  passwordReset,
  useLogout
};
function socialLogin(reqData) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
  };
  return fetch(`${config.apiUrl}login-with-google`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}


function login(reqData) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqData),
  };
  return fetch(`${config.apiUrl}login`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const error = (data && data.message) || response.message;
    if (error == 'Auth failed') {
      return Promise.reject(error);
    } else {
      if (data && data.error === 1) {
        return Promise.reject(error);
      }
    }
    return data;
  });
}
function passwordReset(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${config.apiUrl}forget-password`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function useLogout(url) {
  const redirect = useHistory()
  if (url) {
    localStorage.clear()
    redirect.push(url)
  }
}