import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import FacilitatorSideMenu from "./FacilitatorSideMenu";
// import DatePicker from "react-horizontal-datepicker";
import Countdown from 'react-countdown';
import Logout from '../logout';
import { FecthData } from '../../Services/FetchData';
import config from '../../config';
import TutorSessionCalendar from '../tutor/TutorSessionCalendar';
import { formatDate, getMaskedText, timeFormate } from '../../helpers/utils';
import { cancelSession, getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';

function FacilitatorDashboard() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [students, setStudents] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dateWiseSessions, setDateWiseSessions] = useState([])
  const [selectedDate, selectDate] = useState()
  const [sessions, setSessions] = useState([])
  const selectedDay = val => {
    console.log(val);
    const date = new Date(val)
    selectDate(formatDate(date))
    getAllSessionsDateWise(formatDate(date))
  };
  const date = Date.now() + 2000000;
  const getAllStudent = () => {
    FecthData.getData('facilitator/get_student_by_facilitator/' + user?.email).then(
      (data) => setStudents(data?.data)
    )
  }
  const getAllSessions = () => {
    FecthData.getData('facilitator/get_facilitators_student_session/' + user?.email).then(
      (data) => setSessions(data?.data)
    )
  }
  const getAllSessionsDateWise = (date) => {
    FecthData.getData('facilitator/get_facilitators_student_session/' + user?.email + '?date=' + date).then(
      (data) => setDateWiseSessions(data?.data)
    )
  }
  const cancelTutorSession=(session_id,cancelled_by)=>{
    cancelSession(session_id,cancelled_by).then(()=>{
      getAllSessions()
    })
  }
  useEffect(() => {
    getAllStudent()
    getAllSessions()
  }, [])

  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <FacilitatorSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
                <div className="mt-4">
                  <h2 className="text-muted mb-0">Welcome back {user?.name}! </h2>
                  <h6 className="text-muted mt-3 d-flex justify-content-between">UPCOMING SESSIONS <a className="text-muted" href="javascript:void(0)"><small>View All</small></a></h6>
                </div>
              </Col>
              {sessions?.map(session => <Col md={4}>
                <div className="dash-box-tutor">
                  <img src="images/tutor-bg1.jpg" alt="" />
                  <div className="remaining-hrs">
                    <Countdown
                      date={session?.date}
                      daysInHours="true"
                    />
                  </div>
                  <div className="tutor-user">
                  {session?.status&& session?.status=='cancelled'?<h4 >{session?.status}</h4>
                    :<h4 style={{cursor:'pointer'}} onClick={()=>cancelTutorSession(session?._id,user?._id)}>{session?.status?session?.status:'cancel'}</h4>}
                    {/* <div className="tutor-user-img">
                      <img src="images/avatar2.png" alt="" />
                    </div> */}
                    {/* <h4>ID: 567876</h4> */}
                    {/* <p>{session?.grade_id?.title}</p> */}
                  </div>
                  <div className="dash-box-text">
                    {/* <h4>{session?.subject?.title} Functions & Graphing</h4> */}
                    <p>{session?.subject?.title.toUpperCase()}</p>
                    <p><small>{new Date(session.date).toDateString()}  |  {timeFormate(session?.start_time)}-{timeFormate(session?.end_time)}</small></p>
                  </div>
                </div>
              </Col>)}
              {/* <Col md={4}>
                <div className="dash-box-tutor">
                  <img src="images/tutor-bg2.jpg" alt="" />
                  <div className="remaining-hrs">
                    <Countdown
                      date={date}
                      daysInHours="true"
                    />
                  </div>
                  <div className="tutor-user">
                    <div className="tutor-user-img">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <h4>ID: 567876</h4>
                    <p>8th Grade</p>
                  </div>
                  <div className="dash-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>ALGEBRA</p>
                    <p><small>Monday, Aug 06   |   8:00AM-9:00AM </small></p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box-tutor">
                  <img src="images/tutor-bg3.jpg" alt="" />
                  <div className="remaining-hrs">
                    <Countdown
                      date={date}
                      daysInHours="true"
                    />
                  </div>
                  <div className="tutor-user">
                    <div className="tutor-user-img">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <h4>ID: 567876</h4>
                    <p>8th Grade</p>
                  </div>
                  <div className="dash-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>ALGEBRA</p>
                    <p><small>Monday, Aug 06   |   8:00AM-9:00AM </small></p>
                  </div>
                </div>
              </Col> */}
              <Col md={12}>
                <h6 className="text-muted mt-3 d-flex justify-content-between">My Students <a className="text-muted" href="javascript:void(0)"><small>View All</small></a></h6>
              </Col>
              <Col md={12}>
                <div className="student-list">
                  <ul>
                    {students?.map(student => <li>
                      <Link to="#">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src={student?.gender_avatar?.icon_image ? getResourceUrl(student?.gender_avatar?.icon_image) : "images/avatar2.png"} alt="" />
                          </div>
                          <h4>{getMaskedText(student?.first_name,'name') }</h4>
                          <p>{student?.grade_id?.title}</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>)}
                    {/* <li>
                      <Link to="#">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar2.png" alt="" />
                          </div>
                          <h4>567876</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar1.png" alt="" />
                          </div>
                          <h4>346789</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar3.png" alt="" />
                          </div>
                          <h4>187726</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <div className="tutor-user">
                          <div className="tutor-user-img">
                            <img src="images/avatar2.png" alt="" />
                          </div>
                          <h4>567876</h4>
                          <p>8th Grade</p>
                        </div>
                        <div className="online-status active"></div>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </Col>
              {/* <Col md={12}>
                <h6 className="text-muted my-3 d-flex justify-content-between">Request Received <a className="text-muted" href="javascript:void(0)"><small>View All</small></a></h6>
              </Col>
              <Col md={12}>
                <div className="tutor-list">
                  <a href="javascript:void(0)" className="tutor-item" onClick={handleShow}>
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar2.png" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                      </div>
                    </div>
                  </a>
                  <a href="javascript:void(0)" className="tutor-item" onClick={handleShow}>
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar1.png" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Andy F.</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                      </div>
                    </div>
                  </a>
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src={user?.propic ? getResourceUrl(user?.propic) : "images/no_profile_img.png"} width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} | ID: 567872</h5>
                  <p>Status: {user?.status}</p>
                </div>
                <Logout url={'/facilitator'} />
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <TutorSessionCalendar dateWiseSessions={dateWiseSessions} selectedDay={selectedDay} />
            {/* <div className="upcom-class">
              <h5 className="text-muted">Calendar</h5>
              <DatePicker getSelectedDay={selectedDay}
                endDate={100}
                selectDate={new Date("2022-02-08")}
                labelFormat={"MMMM"}
                color={"#287AEB"}
              />
              <div className="upcoming-class-schedule">
                <ul>
                  <li className="eight-am">
                    <div className="left">
                      <p>8 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Chemistry with Jane D.</h5>
                        <p>8:00 am-9:00 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="nine-am">
                    <div className="left">
                      <p>9 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Algebra with Sarah D.</h5>
                        <p>9:00 am-9:30 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="ten-am mt-4">
                    <div className="left">
                      <p>10 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Geography with John M.</h5>
                        <p>8:00 am-9:00 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="eleven-am no-class">
                    <div className="left">
                      <p>11 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Physics with David G.</h5>
                        <p>12:00 pm-1:00 pm</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="twelve-pm">
                    <div className="left">
                      <p>12 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Physics with David G.</h5>
                        <p>12:00 pm-1:00 pm</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="one-pm">
                    <div className="left">
                      <p>1 PM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Geometry with Jane D.</h5>
                        <p>1:00 pm-1:30 pm</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>2 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>3 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>4 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Facilitator Request From Student ID: 567876</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <Col md="12">
                  <div className="faci-profile">
                    <div className="faci-profile-img">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <h6>Jane D. | Student ID: 567876</h6>
                    <p>Academic Year 2021-2022</p>
                  </div>
                </Col>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Select>
                    <option>1:00 HR</option>
                    <option value="1">2:00 HR</option>
                    <option value="2">3:00 HR</option>
                    <option value="3">4:00 HR</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Select>
                    <option>Live Session</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Select>
                    <option>Start Time</option>
                    <option value="1" selected>10:00AM</option>
                    <option value="2">11:00AM</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Select>
                    <option>End Time</option>
                    <option value="1">10:00AM</option>
                    <option value="2" selected>11:00AM</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="12">
                  <Form.Select>
                    <option>Apply This Schedule To All Sundays</option>
                  </Form.Select>
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill me-2" variant="primary">ACCEPT</Button>
                  <Button className="px-4 btn-pill ms-2" variant="secondary">DECLINE</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default FacilitatorDashboard
