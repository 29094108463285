import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown,
  Collapse
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import AdminSideMenu from "./../AdminSideMenu";
import Autocomplete from 'react-autocomplete'
import config from '../../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Auth } from '../../../Services/Auth';
import { Admin } from '../../../Services/Admin';
import { BsApp } from "react-icons/bs";
import { PiDotsThreeOutline, PiDotsThreeOutlineLight, PiDotsThreeOutlineThin } from "react-icons/pi";
import { getResourceUrl } from '../../../Services/CommanFunctions';
import NotificationUI from '../../common/NotificationUI';
import { getMaskedText } from '../../../helpers/utils';

function AdminLayout(props) {
  const org = props?.school ? props?.school : props?.company
  const user_role = props?.school ? 'school_user' : 'company_user'
  const orgUser = props?.schoolUser ? props?.schoolUser : props?.companyUser
  const userDetailPath = props?.userDetailPath
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [userInfoToggle, setUserInfoToggle] = useState(false);
  const [userList, setUserList] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState('');
  const user = JSON.parse(localStorage.getItem('user'))
  const history = useHistory()

  const fetchUsers = () => {
    Admin.getInactiveSchoolUser('school').then(res => {
      setUserList(res.data)
    })
  }
  // const showUserCard = (user) => {
  //   setSelectedUser(user)
  //   setUserInfoToggle(!userInfoToggle)
  // }

  useEffect(() => {
    fetchUsers()
  }, [])
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  const changeUserStatus = (id, status) => {
    Admin.changeUserStatus({ user_id: id, status: status }).then(res => {
      fetchUsers()
    })
  }
  function UserCard({ orgUser, org }) {
    const title = org?.school_name ? org?.school_name : org?.company_name
    const id = org?.school_code ? org?.school_code : org?.company_code
    const first_name = orgUser?.first_name
    const last_name = orgUser?.last_name
    const department = orgUser?.department
    const email = orgUser?.email
    const phone = orgUser?.phone ? orgUser?.phone : org?.phone
    const address = [org?.street ? org?.street : org?.company_address_line1, org?.city ? org?.city : org?.company_address_line2]
    const dist = org?.school_dist ? org?.school_dist : org?.company_city
    const state = org?.state ? org?.state : org?.company_state
    const county = org?.county ? org?.county : org?.company_country
    const zip_code = org?.zip_code ? org?.zip_code : org?.company_zip
    const default_logo=org?.school_name?"default_logos/school-logo.png":"default_logos/org-logo.png"
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a href='' ref={ref} onClick={e => {
        e.preventDefault();
        onClick(e);
      }} >{children}</a>
    ));
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
      setIsHover(true);
    };

    const handleMouseLeave = () => {
      setIsHover(false);
    };
    return <div className="card">
      <div className="school-side-details">
        {/* <div ><img onClick={() => showUserCard()} src='images/drop-icon.png' height={12} width={20} /></div> */}

        <div className="school-logo-circle">
          <img src={org?.logo ? getResourceUrl(org?.logo) : default_logo} alt=" " />
        </div>
        <div className="school-more-option">
          <Dropdown>
            <Dropdown.Toggle >
              {/* <PiDotsThreeOutlineLight size={25} color='black' /> */}
              <img src="images/more-icon.png" alt="" width="20" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item
              // href="#/action"
              >View Profile</Dropdown.Item> */}
              <Dropdown.Item
              ><Link to={userDetailPath + org?._id} className={'dropdown-item'}>View Details</Link></Dropdown.Item>

              {/* {orgUser ? <><Dropdown.Divider /><Dropdown.Item
              ><Link className='dropdown-item' to={{ pathname: '/admin/user/edit', state: { ...orgUser, ...orgUser?.user_id, user_role: user_role, _id: orgUser?._id, user_sf_id: orgUser?.user_id?._id } }}>Edit Profile</Link>
              </Dropdown.Item></> : null} */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="school-name">
          <h5>{title} </h5>
          <h6>ID: {id}</h6>
          {orgUser ? <a className={`btn btn-${orgUser?.user_id?.status == 'Active' ? 'primary' : 'secondary'} solid btn-sm no-shadow d-block`}>Status: {orgUser?.user_id?.status}</a> : null}
        </div>
        <Form>
          <Row>
            {orgUser ? <> <Form.Group className="form-group mb-3" as={Col} md="6">
              <Form.Control type="text" value={getMaskedText(first_name,'name')} />
            </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="6">
                <Form.Control type="text" value={getMaskedText(last_name,'name')} />
              </Form.Group>
              {department ? <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" value={department} />
              </Form.Group> : null}</> : null}
            {address[0] ? <Form.Group className="form-group mb-3" as={Col} md="12">
              <Form.Control type="text" value={address[0]} />
            </Form.Group> : null}
            {address[1] ? <Form.Group className="form-group mb-3" as={Col} md="12">
              <Form.Control type="text" value={address[1]} />
            </Form.Group> : null}
            <Form.Group className="form-group mb-3" as={Col} md="12">
              <Form.Control type="text" value={dist} />
            </Form.Group>
            <Form.Group className="form-group mb-3" as={Col} md="6">
              <Form.Select className="bg-grey">
                <option>State</option>
                <option value="1" selected>{state}</option>
                {/* <option value="2">Illinois</option>
                <option value="3">Illinois</option> */}
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group mb-3" as={Col} md="6">
              <Form.Control type="text" value={county} />
            </Form.Group>
            <Form.Group className="form-group mb-3" as={Col} md="6">
              <Form.Control type="text" value={zip_code} />
            </Form.Group>
            {orgUser ? <><Form.Group className="form-group mb-3" as={Col} md="12">
              <Form.Control type="tel" value={getMaskedText(phone,'phone') } />
            </Form.Group>
              <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="email" value={getMaskedText(email,'email')} />
              </Form.Group>
              <Col md={12}>
                <Form.Group className="iagree mb-2" controlId="formbymail">
                  <Form.Check className="text-grey" type="checkbox" label="Contact Me By Email" checked />
                </Form.Group>
                <Form.Group className="iagree mb-2" controlId="formbyphone">
                  <Form.Check className="text-grey" type="checkbox" label="Contact Me By Phone" />
                </Form.Group>
              </Col>
              {orgUser?.user_id?.status == 'Inactive' ? <><Col md="6" className="text-center mt-4">
                <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={() => {
                  changeUserStatus(orgUser?.user_id?._id, 'Active')
                  props?.refreshCard()
                }}>Admit</Button>
              </Col>
                <Col md="6" className="text-center mt-4">
                  <Button className="px-4 btn-pill" variant="secondary" onClick={() => {
                    changeUserStatus(orgUser?.user_id?._id, 'Declined')
                    props?.refreshCard()
                  }}>Decline</Button>
                </Col></> : null}</> : null}
          </Row>
        </Form>
      </div>
    </div >
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            {props.children}
          </div>
          <div className="right">
            <div className="user-short-text" >
              <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="school-more-option"> */}
                {/* <img src="images/drop-icon.png" alt="" width="10" /> */}
                {/* </div> */}
              </div>
            </div>
            {props?.showUserCard ? <UserCard orgUser={orgUser} org={org} /> : null}
            {/*<div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src="default_logos/school-logo.png" alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                      // href="#/action"
                      >View Profile</Dropdown.Item> 
                      <Dropdown.Item
                      // href={"/admin/school/students/" + school?._id}
                      ><Link to={userDetailPath + cardData?._id}>View Details</Link></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{cardData?.school_name} {cardData?.company_name}</h5>
                  <h6>ID: 567872</h6>
                  <a className="btn btn-primary solid btn-sm no-shadow d-block" onClick={()=>{}}>Activate Account</a>
                </div>
                <Form>
                  <Row>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="John" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="Doe" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value="Dean" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value="5835 N Lincoln Ave, Chicago, IL 60659, USA" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey">
                        <option>State</option>
                        <option value="1" selected>Illinois</option>
                        <option value="2">Illinois</option>
                        <option value="3">Illinois</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="60659" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="tel" value="312-672-8763" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="email" value="mather@edu" />
                    </Form.Group>
                    <Col md={12}>
                      <Form.Group className="iagree mb-2" controlId="formbymail">
                        <Form.Check className="text-grey" type="checkbox" label="Contact Me By Email" checked />
                      </Form.Group>
                      <Form.Group className="iagree mb-2" controlId="formbyphone">
                        <Form.Check className="text-grey" type="checkbox" label="Contact Me By Phone" />
                      </Form.Group>
                    </Col>
                    <Col md="6" className="text-center mt-4">
                      <Button className="px-4 btn-pill no-shadow" variant="primary">Admit</Button>
                    </Col>
                    <Col md="6" className="text-center mt-4">
                      <Button className="px-4 btn-pill" variant="secondary">Decline</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>*/}
          </div>
        </div>
      </section>
    </>
  )
}

export default AdminLayout
