import moment from "moment-timezone"

const user=JSON.parse(localStorage.getItem('user'))

export const timeFormate = (time) => {
  return UTCToSpecificTimeZone(time,user?.timezone)
}
export const getWeekOfMonth = (date) => {
  let monthStart = new Date(date);
  monthStart.setDate(1);
  return Math.floor((monthStart.getDay() + new Date(date).getDate() - 1) / 7);
}
export const colorObj = {
  Algebra: '#914CE9',
  Chemistry: '#FEB41C',
  Physics: '#D0202C',
  Geometry: '#73913E',
  Biology: '#023e8a',
  Calculus: '#249DA1'
}
export const timeZone = {
  EST: 'America/New_York',
  PST: 'America/Los_Angeles',
  CST: 'America/Chicago',
  
}
export const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const utcToTzDate = (date,format) => {
  if(date!=undefined){
    return moment(date).format(format)
  }else{
    return "";
  }
}
export const  msToTime=(date)=> {
  return moment(date, "YYYYMMDD").fromNow()
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }  return Math.floor(seconds) + " seconds";
}
export const getMaskedText=(text,type)=>{
  if(text){
    switch(type){
      case 'email':
        return text.replace(/^(.)(.*)(.@.*)$/,(match,p1,p2,p3)=>p1+'*'.repeat(p2.length)+p3)
      case 'name':
        return text.replace(/\b(\w)(\w+)\b/g,(match,p1,p2)=>p1+'*'.repeat(p2.length))
      case 'phone':
        return text.replace(/.(?=.{4})/g,'*')
  
      default:
        return text;
  
    }
  }
  
}
export const localTimeToMin=(time)=>{
  return moment(time,'HH:mm')/(60*1000)
  let timeArr=time.split(':')
  return Number(timeArr[0])*60+Number(timeArr[1]);
}
export const minToTime=(time)=>{
  return moment(time*(60*1000)).format('HH:mm')
  let h=Math.floor(time/60)
  let m=time%60
  if(h<10){
    h='0'+h
  }
  if(m<10){
    m='0'+m
  }
  return h+':'+m;
}
export const localTimeDiffInMin=(t1,t2)=>{
  return (t2-t1)
}
export const rangeToTimePartition=(time,p)=>{
  return Math.floor(time/p)
}
export const divideTimeRangeToSession=(start_time,end_time,p)=>{
  let parts=[]
  let st=localTimeToMin(start_time)
  let et=localTimeToMin(end_time)
  let timeDiff=localTimeDiffInMin(st,et)
  let noOfPart=Math.floor(timeDiff/p)
  for(let i=1;i<=noOfPart;i++){
    let obj={
      start_time:minToTime(st+(i-1)*p),
      end_time:minToTime(st+i*p)
    }
    parts.push(obj)
  }
  return parts
}
export const UTCToSpecificTimeZone=(date,tz)=>{

  if(date!=undefined){
    const time= moment.utc(date).tz(timeZone[tz])
    return time.format('h:mm A z ')
  }else{
    return "";
  }
}
export const slotDateFormat=(time)=>{
 return moment(time,"h:mm:ss").format('hh:mm A')
}
export const removeField=(data,fields)=>{
  var data1={...data}
  for(const field of fields){
    if (data1 && data1[field]) {
      delete data1[field];
    }
  }
  return data1
}