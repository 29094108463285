import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Logout({url}){
    let history=useHistory()
    const logout=()=>{
        localStorage.clear()
        history.push(url)
    }
    return <Dropdown >
    <Dropdown.Toggle drop='none' variant="">

    </Dropdown.Toggle >
    <Dropdown.Menu>
      <Dropdown.Item onClick={logout}
      // href="#/action"
      >Logout</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
}