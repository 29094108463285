import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link, useParams } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import Autocomplete from 'react-autocomplete';
import { Admin } from '../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import config from "./../../config";
import { useHistory } from 'react-router-dom';
import { Organization } from '../../Services/Organization';
import ActionButton from '../ActionButton';
import { filter } from 'lodash';
import { FecthData } from '../../Services/FetchData';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';
import { getMaskedText, removeField } from '../../helpers/utils';

function AdminOrgUser() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState('');
  const [school, setSchool] = useState({});
  const [companyUser, setCompanyUser] = useState([]);
  const [index, setIndex] = useState(0)
  const [selectedCompanyUser, selectCompanyUser] = useState();
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'))
  const { id } = useParams();
  const history = useHistory()
  const [filter, setFilter] = useState('')

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    company_id: id,
    display_logo: "yes",
    department: "",
    contact_by: [],
  })

  const getAllCompanyUser = () => {
    FecthData.getData('company_user/get_company_wise_user/' + id + '?page=' + 0 + '&filter=' + filter).then(
      (data) => {
        setCompanyUser(data?.data)
        selectCompanyUser(data?.data[index])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }
  const submitFormData = (e) => {
    e.preventDefault();

    let requestData = new FormData();
    Object.entries(formData).map(([key, value]) => {
      if (key !== 'contact_by') {
        requestData.append(key, value);
      } else {
        requestData.append(key, value.toString());
      }

    })
    Organization.registerCompany(requestData).then(
      (data) => {
        if (!data.errors) {
          NotificationManager.success(data.message, 'success!', 2000);
          handleClose()
        } else {
          NotificationManager.error(data.message, 'error!', 2000);
        }


      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )

  }
  useEffect(() => {

    FecthData.getData('school/get_school_by_id/' + id).then(
      (data) => setSchool(data),
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }, [id]);
  useEffect(() => {
    getAllCompanyUser()
  }, [filter])
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  const changeUserStatus = (id, status) => {
    Admin.changeUserStatus({ user_id: id, status: status }).then(
      (data) => {
        getAllCompanyUser()
      }
    )
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={6} className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="school-logo-small">
                      <img src="default_logos/org-logo.png" alt="" />
                    </div>
                    <h4 className="text-muted mb-0">{school?.data?.school_name}</h4>
                  </div>
                </Col>
                <Col md={6} className="mt-4">
                  <div className="filters">
                    <ul>
                      <li>
                        {/* <a href="javascript:void(0)" className='btn-primary'>Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Sort</option>
                          <option value={'ASC'}>Alphabetic A-Z</option>
                          <option value={'DESC'}>Alphabetic Z-A</option>
                        </Form.Select>
                      </li>
                      <li>
                        {/* <a href="javascript:void(0)" className='btn-primary'>Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Filter</option>
                          <option value={'Last Modified'}>Last Modified</option>
                          <option value={'Oldest'}>Oldest</option>
                          <option value={'Newest'}>Newest</option>
                        </Form.Select>
                      </li>
                      <li onClick={handleShow}>
                        <a href="javascript:void(0)" className='btn-primary'>Add <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category">
                    <ul className='justify-content-start'>
                      <li >
                        <Link to={'/admin/company/tutor/' + id}>Tutors</Link>
                      </li>
                      <li className="active">
                        <Link to={'/admin/company/admin' + id}>Company Admin</Link>
                      </li>
                      {/* <li>
                        <Link to={'/admin/school/tutor/' + id}>Tutors</Link>
                      </li>
                      <li>
                        <Link to={'/admin/school/facilitator/' + id}>Facilitators</Link>
                      </li>
                      <li>
                        <Link to={"/admin/school/parent/"  + id}>Parents/Guardians</Link>
                      </li> */}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          {/* <th scope="col">ID</th> */}
                          {/* <th scope="col">Grade</th>
                          <th scope="col">Gender</th> */}
                          <th scope="col">Email</th>
                          {/* <th scope='col'>Edit</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {companyUser?.map((content, index) => (
                          <tr key={index} onClick={() => {
                            setIndex(index)
                            selectCompanyUser(content)
                          }}>
                            <td>
                            {content?.user_details[0] && content?.user_details[0]?.display_logo == 'yes' && content?.user_details[0]?.propic? <img src={getResourceUrl(content?.user_details[0]?.propic)} alt="" width="44" className="rounded me-2" />
                              :<img src={'images/no_img.png'} alt="" width="44" className="rounded me-2" />}
                              {getMaskedText(content?.first_name + ' ' + content?.last_name,'name') }</td>
                            {/* <td>{content?.company_id}</td> */}
                            {/* <td>{content?.grade_details[0]?.title}</td>
                            <td>{content?.gender[0]?.name}</td> */}
                            <td>{getMaskedText(content?.email,'email')}</td>
                            {/* <td><a className="btn btn-primary btn-sm" onClick={() => history.push({ pathname: "/admin/user/edit", state: { ...content, user_role: 'company_user', status: content?.user_details.status, name: content?.user_details.name } })}>Edit</a></td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>

          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {selectedCompanyUser ? <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={selectedCompanyUser?.user_details[0]?.propic?getResourceUrl(selectedCompanyUser?.user_details[0]?.propic):"images/no_profile_img.png"} alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/*<Dropdown.Item
                      // href="#/action"
                      >
                        {/* View Profile 
                      </Dropdown.Item>
                      <Dropdown.Item
                      // href={"/admin/school/students/" + school?._id}
                      >
                        {/* <Link to={userDetailPath + cardData?._id}>View Details</Link> 
                      </Dropdown.Item>*/}
                      <Dropdown.Item
                      // href={"/admin/school/students/" + school?._id}
                      >
                         <Link className={'dropdown-item'} to={{ pathname: '/admin/user/edit', state: { ...selectedCompanyUser, user_role: 'company_user', ...removeField(selectedCompanyUser?.user_details[0],['_id','user_role']), user_sf_id:selectedCompanyUser?.user_id }}}>View Details</Link> 
                      </Dropdown.Item>
                      {/* <Dropdown.Divider />
                      <Dropdown.Item
                      // href={"/admin/school/students/" + school?._id}
                      >
                        <Link className={'dropdown-item'} to={{ pathname: '/admin/user/edit', state: { ...selectedCompanyUser, user_role: 'company_user',...removeField(selectedCompanyUser?.user_details[0],['_id','user_role']), user_sf_id: selectedCompanyUser?.user_id } }}>Edit Account</Link>
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{getMaskedText(selectedCompanyUser?.first_name+' '+selectedCompanyUser?.last_name,'name')}</h5>
                  {/* <h6>ID: 567872</h6> */}
                  <a className={`btn btn-${selectedCompanyUser?.user_details[0]?.status == 'Active' ? 'primary' : 'secondary'} secondary solid btn-sm no-shadow d-block`}
                  // onClick={() => changeUserStatus(selectedCompanyUser?.user_id, selectedCompanyUser?.user_detauils?.status == 'Active' ? 'Inactive' : 'Active')}
                  >Status: {selectedCompanyUser?.user_details[0]?.status}</a>
                </div>
                <Form>
                  <Row>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value={getMaskedText(selectedCompanyUser?.first_name,'name')} />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value={getMaskedText(selectedCompanyUser?.last_name,'name')} />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value={selectedCompanyUser?.department} />
                    </Form.Group>
                    {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="text" value="5835 N Lincoln Ave, Chicago, IL 60659, USA" />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Select className="bg-grey">
                        <option>State</option>
                        <option value="1" selected>Illinois</option>
                        <option value="2">Illinois</option>
                        <option value="3">Illinois</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="6">
                      <Form.Control type="text" value="60659" />
                    </Form.Group> */}
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="tel" value={getMaskedText(selectedCompanyUser?.phone,'phone')} />
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control type="email" value={getMaskedText(selectedCompanyUser?.email,'email')} />
                    </Form.Group>
                    <Col md={12}>
                      <Form.Group className="iagree mb-2" controlId="formbymail">
                        <Form.Check className="text-grey" type="checkbox" label="Contact Me By Email" checked />
                      </Form.Group>
                      <Form.Group className="iagree mb-2" controlId="formbyphone">
                        <Form.Check className="text-grey" type="checkbox" label="Contact Me By Phone" />
                      </Form.Group>
                    </Col>
                    {selectedCompanyUser?.user_details[0]?.status == 'Inactive' ? <>< Col md="6" className="text-center mt-4">
                      <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={() => changeUserStatus(selectedCompanyUser?.user_id, 'Active')}>Admit</Button>
                    </Col>
                      <Col md="6" className="text-center mt-4">
                        <Button className="px-4 btn-pill" variant="secondary" onClick={() => changeUserStatus(selectedCompanyUser?.user_id, 'Declined')}>Decline</Button>
                      </Col></> : null}
                  </Row>
                </Form>
              </div>
            </div> : null}
            {/* <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src="images/avatar2.png" alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/school-companyUsers-profile">View Profile</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="/edit-companyUser-account">Edit Account</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#">Delete Account</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>Jane Doe</h5>
                  <h6>companyUser ID: 567872</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="#" className="btn btn-primary solid btn-sm no-shadow d-block">Activate Account</a>
                </div>
                <div className="about-teacher">
                  <h4>About:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p>
                  <h4>Email:</h4>
                  <p>jane@mather.edu</p>
                  <h4>Parent’s Email</h4>
                  <p>sarahdoe@gmail.com</p>
                  <h4>Facilitator Email:</h4>
                  <p>john@mather.edu</p>
                  <h4>Subjects Interested:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li>
                    </ul>
                  </div>
                </div>
                <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow" variant="primary">Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill" variant="secondary">Decline</Button>
                  </Col>
                </Row>
              </div>
            </div> */}
          </div>
        </div>
      </section >

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Company Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitFormData}>
            <div className="modal-form">
              <Row>
                {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="Search by school name, city or zip code" />
              </Form.Group> */}
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="First Name*" value={formData.first_name} onChange={e => setFormData({ ...formData, first_name: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Last Name*" value={formData.last_name} onChange={e => setFormData({ ...formData, last_name: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Contact Number*" value={formData.phone} onChange={e => setFormData({ ...formData, phone: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" placeholder="Email*" value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Department" value={formData.department} onChange={e => setFormData({ ...formData, department: e.target.value })} />
                </Form.Group>
                {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="0221457" placeholder="School Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="5835 N LincoIn Ave" placeholder="Address" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Chicago" placeholder="City" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Illinois" placeholder="State" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="60659" placeholder="Zip Code" />
                </Form.Group> */}
                <Col md="12" className="text-center mt-2">
                  <ActionButton title={'submit'} type={'submit'} requiredFields={[formData.company_id, formData.first_name, formData.department, formData.email, formData.phone]} />
                  {/* <Button className="px-4 btn-pill" variant="primary">Send Request</Button> */}
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default AdminOrgUser
