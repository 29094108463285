import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link, useHistory } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import OtpInput from 'react-otp-input';
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import { Student } from '../../Services/Student';
import ActionButton from '../ActionButton';
import { FecthData } from '../../Services/FetchData';
import { staticData } from '../../helpers/data';

function SignupStepFour({ nextStep, handleFormData, prevStep, values }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState('');
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState();
  const [message, setMessage] = useState(null);
  let history = useHistory();

  const onChange = (value) => {
    setOtp(value);
  }

  const inputfocus = (elmnt, index) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus()
      }
    }

    // if (isNaN(elmnt.target.value)) return false;
    ;

    setOtp(prevState => ({
      ...prevState,
      [index]: elmnt.target.value
    }));

  }
  const sendOtp = () => {
    if (values.email !== '') {
      School.sendEmail({ email: values.email, in_register: true }).then(
        (data) => {
          if (!data.errors) {
            NotificationManager.success(data.message, 'success!', 2000);
            setMessage(data.message)
          } else {
            NotificationManager.error(data.message, 'error!', 2000);
            setMessage(data.message)
          }


        },
        (error) => setError(error.toString())
      )

    } else {
      NotificationManager.error('Email is required', 'error!', 2000);
    }
  }
  const resendOtp = (e) => {
    e.preventDefault();
    setOtp()
  };

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    if (otp && otp?.length === 6) {
      let formData = new FormData();
      Object.entries(values).map(([key, value]) => {
        formData.append(key, value)

      })
      School.verifyOtp({ otp: otp, email: values.email }).then(
        (data) => {
          if (data.error === 0) {
            NotificationManager.success(data.message, 'success!', 2000);
            setMessage(data.message)
            // FinalSubmitFormData(formData)
            nextStep()
          }


        },
        (error) => {
          // NotificationManager.error(error.toString(), 'error!', 2000);
          setError(error.toString())
        }
      )
    } else {
      setError('please enter six digit valid OTP', 'Error!');
      // NotificationManager.error('please enter six digit valid OTP', 'Error!', 2000);
    }
    // nextStep();
  };
  const FinalSubmitFormData = (formData) => {

    Student.post(formData, 'student/register').then(
      (data) => {
        if (data.error === 0) {
          NotificationManager.success(data.message, 'success!', 2000);
          setMessage(data.message)
          localStorage.setItem('student', JSON.stringify(data.data))
          // nextStep();
          history.push('/');
        }


      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )

  }
  const getStudentById = (id) => {
    FecthData.getData('student/get_student_details/' + id).then(
      (data) => localStorage.setItem('student', JSON.stringify(data?.data))
    )
  }
  useEffect(() => {
    if (values.email) {
      sendOtp()
    }
    if (values.student_id) {
      getStudentById(values.student_id)
    }
  }, [values.email])
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }} >
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '75%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Pending Activation</h6>
                    <h6>75% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>{staticData?.verbiage?.student[4]?.heading}</h4>
                    <p>{staticData?.verbiage?.student[4]?.text}</p>
                    <div className="signup-step">
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    {error ? <div className="d-block alert alert-danger d-flex align-items-center" role="alert" id="alrt_msg">
                      <img src="/images/cross.png" alt="" width="20" />
                      <div className="ms-2">
                        {error}
                      </div>
                    </div> : ''}
                    <Form onSubmit={submitFormData}>
                      <div className="verify-account">
                        <img src="images/lock-icon.png" alt="" width="76" />
                        <h4 className="my-3">Enter The Code</h4>
                        <p>
                          Protecting your account is our top priority. An email with the verification code <br />
                          has been sent. Please confirm your account by entering the authorization<br /> code sent to the email registered with us.
                        </p>
                        <div className="new-otp-field">
                          <OtpInput
                            value={otp}
                            containerStyle="otp-input"
                            inputStyle="form-control"
                            onChange={onChange}
                            numInputs={6}
                            isInputNum={true}
                          />
                        </div>
                        <p>
                          It may take a minute to receive your code.<br />
                          Haven’t received it? Resend a new code <a href="javascript:void(0)" onClick={(e) => resendOtp(e)}>Resend a new code</a>
                        </p>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        <ActionButton type='submit' className='btn btn-primary btn-pill px-4'
                          requiredFields={[otp?.length == 6 ? otp : '']} title='Obtain Parental Consent' />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Obtain Parental Consent</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section >
      <Footer />

    </>
  )
}

export default SignupStepFour
