import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SchoolSideMenu from "./SchoolSideMenu";
import { School } from '../../Services/School';
import { NotificationManager } from 'react-notifications';
import config from "./../../config";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FecthData } from '../../Services/FetchData';
import FieldErrors from '../../Services/FieldErrors';
import { Admin } from '../../Services/Admin';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';
import { getMaskedText, removeField } from '../../helpers/utils';

function SchoolTutor() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    org_type: "school",
  })
  const [isVisible, setIsVisible] = useState(true);
  const [tutor, setTutor] = useState({});
  const [index, setIndex] = useState(0)
  const [selectedTutor, selectTutor] = useState()
  const [error, setError] = useState();
  const [fieldError, setFieldError] = useState();
  const [value, setValue] = useState('');
  let history = useHistory()
  const [validated, setValidated] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'))
  const [filter, setFilter] = useState('')

  const getAllSchoolTutor = () => {
    FecthData.getData('tutor/get_all_tutor_of_school/' + user?.school?.school_id + '?page=' + 0 + '&filter=' + filter).then(
      (data) => {
        setTutor(data)
        selectTutor(data?.data[index])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), '!', 2000);
      }
    )
  }
  useEffect(() => {
    getAllSchoolTutor()
  }, [filter]);

  const submitForm = () => {
    FecthData.postJsonData({ ...formData, school_id: user?.school.school_id }, 'tutor/add_tutor').then(
      (data) => {
        if (data?.data) {
          NotificationManager.success(data.message, 'success!', 2000)
          getAllSchoolTutor()
          handleClose()
        } else {
          setFieldError(data.errors.details)

        }
      }
    )
  }
  const changeUserStatus = (userId, changedStatus) => {
    Admin.changeUserStatus({ user_id: userId, status: changedStatus }).then(res => {
      getAllSchoolTutor()
    })
  }
  const logout = () => {
    localStorage.clear()
    history.push('/school')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SchoolSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={6} className="mt-4">
                  <h3 className="text-muted mb-0">Dashboard</h3>
                </Col>
                <Col md={6} className="mt-4">
                  <div className="filters">
                    <ul>
                      <li>
                        {/* <a href="javascript:void(0)" className='btn-primary'>Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Sort</option>
                          <option value={'ASC'}>Alphabetic A-Z</option>
                          <option value={'DESC'}>Alphabetic Z-A</option>
                        </Form.Select>
                      </li>
                      <li>
                        {/* <a href="javascript:void(0)" className='btn-primary'>Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Filter</option>
                          <option value={'Last Modified'}>Last Modified</option>
                          <option value={'Oldest'}>Oldest</option>
                          <option value={'Newest'}>Newest</option>
                        </Form.Select>
                      </li>
                      <li onClick={handleShow}>
                        <a href="javascript:void(0)" className='btn-primary'>Add <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category" style={{ paddingLeft: 0, }}>
                    <ul style={{ justifyContent: 'flex-start' }}>
                      <li>
                        <Link to="/school-dashboard">Students</Link>
                      </li>
                      <li className="active">
                        <Link to="/school-tutor">Tutors</Link>
                      </li>
                      <li>
                        <Link to="/school-facilitator">Facilitators</Link>
                      </li>
                      <li>
                        <Link to="/school-parent">Parents</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">ID</th>
                          <th scope="col">Role</th>
                          <th scope="col">Email</th>
                          {/* <th scope="col">Edit</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {(tutor && tutor?.data?.map((content, index) => (
                          <tr key={index} onClick={() => {
                            setIndex(index)
                            selectTutor(content)
                            }}>
                            <td>
                              {(content?.display_logo == 'yes' && content?.user_id && content?.user_id?.propic != '') && <img src={getResourceUrl(content?.user_id?.propic)} alt="" width="44" className="rounded me-2" />}
                              {getMaskedText(content?.user_id?.name,'name') }
                            </td>
                            <td>{content?.tutor_code}</td>
                            <td>{'Tutor'}</td>
                            <td>{getMaskedText(content?.user_id?.email,'email')}</td>
                            {/* <td><a className="btn btn-primary btn-sm" onClick={() => history.push({ pathname: "/school/user/edit", state: { ...content, ...content?.user_id, user_role: 'tutor', _id: content?._id } })}>Edit</a></td> */}
                          </tr>
                        )))}
                        {!tutor &&
                          <tr>
                            <td colSpan="6" className="text-center">
                              <div className="spinner-border spinner-border-lg align-center"></div>
                            </td>
                          </tr>
                        }
                        {tutor && !tutor?.data?.length &&
                          <tr>
                            <td colSpan="6" className="text-center">
                              <div className="p-2">No Tutor To Display</div>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name}  </h5>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {selectedTutor ? <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={selectedTutor?.user_id?.propic?getResourceUrl(selectedTutor?.user_id?.propic):"images/avatar.jpg"} alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#" onClick={() => history.push({ pathname: "/school-teacher-stats", state: selectedTutor })}>View Profile</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#" onClick={() => history.push({ pathname: "/school/user/edit", state: { ...selectedTutor, ...removeField(selectedTutor?.user_id,['_id','user_role']), user_role: 'tutor', user_sf_id: selectedTutor?.user_id?._id } })}>Edit Account</Dropdown.Item>
                      {/* <Dropdown.Divider />
                      <Dropdown.Item href="#">Delete Account</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{getMaskedText(selectedTutor?.first_name,'name')} {getMaskedText(selectedTutor?.last_name,'name')}</h5>
                  {/* <h6>ID: 567872</h6> */}
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${selectedTutor?.phone}`} itemRef='ghgf'>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {selectedTutor?.phone ? <span className='show-phone'>{getMaskedText(selectedTutor?.phone,'phone')}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${selectedTutor?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className={`btn btn-${selectedTutor?.user_id, selectedTutor?.user_id.status == 'Active' ? 'primary' : 'secondary'} solid btn-sm no-shadow d-block`}>Status: {selectedTutor?.user_id.status}</a>
                </div>
                <div className="about-teacher">
                  <h4>About:</h4>
                  <p>
                    {selectedTutor?.about}
                  </p>
                  <h4>Experience:</h4>
                  <p>
                    {selectedTutor?.tutoring_experience}
                  </p>
                  {/* <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p> */}
                  <h4>Email:</h4>
                  <p>{getMaskedText(selectedTutor?.email,'email')}</p>
                  <h4>Subjects Interested In Teaching:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      {selectedTutor?.subjects?.map(subject => <li>
                        {subject?.title}
                      </li>)}
                      {/* <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li> */}
                    </ul>
                  </div>
                </div>
                {selectedTutor?.user_id.status == 'Inactive' ? <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow same-width" variant="primary" onClick={() => changeUserStatus(selectedTutor?.user_id, 'Active')}>Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill same-width" variant="secondary" onClick={() => changeUserStatus(selectedTutor?.user_id, 'Declined')}>Decline</Button>
                  </Col>
                </Row> : null}
              </div>
            </div> : null}
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Tutor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Tutor First Name" value={formData.first_name} onChange={(e) => setFormData({ ...formData, first_name: e.target.value })} />
                  <FieldErrors errors={fieldError} field="first_name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Tutor Last Name" value={formData.last_name} onChange={(e) => setFormData({ ...formData, last_name: e.target.value })} />
                  <FieldErrors errors={fieldError} field="last_name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Tutor Contact Number" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                  <FieldErrors errors={fieldError} field="phone" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Tutor Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                  <FieldErrors errors={fieldError} field='email' />
                </Form.Group>
                {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="Mather High School" placeholder="School Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="0221457" placeholder="School Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="5835 N LincoIn Ave" placeholder="Address" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Chicago" placeholder="City" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Illinois" placeholder="State" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="60659" placeholder="Zip Code" />
                </Form.Group> */}
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill" variant="primary" onClick={submitForm}>Send Request</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default SchoolTutor
