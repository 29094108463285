import { Dropdown } from "react-bootstrap"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { getResourceUrl } from "../../Services/CommanFunctions"
import NotificationUI from "../common/NotificationUI"

export default function ShortUserInfo() {
    const user = JSON.parse(localStorage.getItem('user'))
    let history = useHistory()
    const logout = () => {
        localStorage.clear()
        history.push('/school')
    }
    return <div className="user-short-text">
        <NotificationUI/>
        {/* <div className="notification-bell">
            <img src="images/bell-icon.png" alt="" width="32" />
            <div className="noti-dot"></div>
        </div> */}
        <div className="user-short-profile">

            <div className="user-avatar">
                {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="default_logos/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
            </div>
            <div className="user-short-name">
                <h5>{user?.name}  </h5>
                <p>Status: {user?.status}</p>
            </div>
            {/* <Button variant="outline-secondary" onClick={logout}>Logout</Button> */}
            <Dropdown >
                <Dropdown.Toggle drop='none' variant="">

                </Dropdown.Toggle >
                <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {/* <div className="drop-down">
        <img src="images/drop-icon.png" alt="" width="10" />
      </div> */}
        </div>
    </div>
}