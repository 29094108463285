import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link, useParams } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import ActionButton from '../ActionButton';
import config from '../../config';
import { getResourceUrl } from '../../Services/CommanFunctions';
import { staticData } from '../../helpers/data';

function FacilitatorSignupStepThree({ nextStep, prevStep, handleFormData, handleSchool, values }) {
  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);
  const [message, setMessage] = useState(null);
  let { id } = useParams();
  const student = JSON.parse(localStorage.getItem('student'))


  const submitFormData = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const form = e.currentTarget;

      nextStep();
      // 
    }

  };

  const validateForm = () => {

    let fields = values;
    let errors = {};
    let formIsValid = true;

    if (!fields["facilitator_name"] || fields["facilitator_name"].trim() == '') {
      formIsValid = false;
      errors["facilitator_name"] = "*Please add your name";
    }
    // if (!fields["facilitator_email"]) {
    //   formIsValid = false;
    //   errors["facilitator_email"] = "*Please enter your facilitator email-ID.";
    // }

    // if (typeof fields["facilitator_email"] !== "undefined") {
    //   //regular expression for email validation
    //   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    //   if (!pattern.test(fields["facilitator_email"])) {
    //     formIsValid = false;
    //     errors["facilitator_email"] = "*Please enter valid facilitator email-ID.";
    //   }
    // }
    if (!fields["password"] || fields["password"].trim() == '') {
      formIsValid = false;
      errors["password"] = "Please Enter your Password.";
    }

    if (!fields["confirmpassword"] || fields["confirmpassword"].trim() == '') {
      formIsValid = false;
      errors["confirmpassword"] = "Please Enter your Confirm Password.";
    }
    if (typeof fields["password"] !== "undefined" && typeof fields["confirmpassword"] !== "undefined") {

      if (fields["password"] != fields["confirmpassword"]) {
        formIsValid = false;
        errors["password"] = "Confirm password is Not Matched";
      }
    }

    setError2(errors)
    return formIsValid;


  }
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '75%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Accept Request</h6>
                    <h6>75% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>{staticData?.verbiage?.facilitator[3]?.heading}</h4>
                    <p> {staticData?.verbiage?.facilitator[3]?.text}</p>
                    <div className="signup-step">
                      <ul>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <Row>
                        <Col md={12}>
                          <div className="faci-profile">
                            <div className="faci-profile-img">
                              {/* {values && values?.gender_avatar === 'Male' && <img src="images/avatar3.png" alt="" />}
                              {values && values?.gender_avatar === 'Female' && <img src="images/avatar2.png" alt="" />} */}
                              <img src={student?.gender[0]?.icon_image ? getResourceUrl(student?.gender[0]?.icon_image) : 'images/avatar3.png'} />
                            </div>
                            <h6>{student?.first_name + ' ' + student?.last_name}. | Student ID: {student?.student_code}</h6>
                            <p>Academic Year {staticData?.years?.getYearByValue(student.academic_year).label }</p>
                          </div>
                        </Col>
                        <Col md={12}>
                          <Form.Group className="form-group mb-3">
                            <Form.Control type="text" name="facilitator_name" onChange={handleFormData("facilitator_name")} defaultValue={values.name} placeholder="Facilitator Name*" required />
                            <span className="err">{error2?.facilitator_name}</span>
                          </Form.Group>
                        </Col>

                        {/* <Col md={6}>
                          <Form.Group className="form-group mb-3">
                            <Form.Control type="email" name="facilitator_email" onChange={handleFormData("email")} defaultValue={values.email} placeholder="Your Email*" readOnly required />
                            <span className="err">{error2?.facilitator_email}</span>
                          </Form.Group>
                        </Col> */}
                        <Col md={6}>
                          <Form.Group className="form-group mb-3">
                            <Form.Control type="password" name="password" onChange={handleFormData("password")} placeholder="Enter your new password*" />
                            <span className="err">{error2?.password}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="form-group mb-3">
                            <Form.Control type="password" name="confirmpassword" onChange={handleFormData("confirmpassword")} placeholder="Confirm your password*" />
                            <span className="err">{error2?.confirmpassword}</span>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                                <Form.Select name="timezone" onChange={handleFormData("timezone")} defaultValue={values.timezone}>
                                  <option disabled value=''>Select Time Zone*</option>
                                  <option value="EST">EST</option>
                                  <option value="CST">CST</option>
                                  <option value="PST">PST</option>
                                </Form.Select>
                                <span className="err">{error2?.timezone}</span>
                              </Form.Group>
                              </Col>
                        {/* <Col md={12}>
                          <Form.Group className="form-group mb-3">
                            <Form.Control className="bg-grey no-shadow" type="text" value="Schedule applied to all Sundays" />
                          </Form.Group>
                        </Col> */}
                        {/* <Col md="6" className="text-center">
                          <ActionButton type={'submit'} className="btn btn-primary px-4 btn-pill no-shadow" title={'Accept Request'}
                            requiredFields={[values.facilitator_name, values.password, values.confirmpassword]}></ActionButton>
                        </Col>
                        <Col md="6" className="text-center">
                          <Button className="px-4 btn-pill" variant="secondary" disabled={values.facilitator_name == '' || values.password == '' || values.confirmpassword == ''}>Decline Request</Button>
                        </Col> */}
                      </Row>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} title={'Continue'} className={'btn btn-primary btn-pill px-4'}
                          requiredFields={[values.facilitator_name, values.password, values.confirmpassword]} />
                        {/* <button className="btn btn-primary btn-pill px-4" onClick={nextStep} >skip</button> */}
                        {/* <Link to="/facilitator-signup-step4" className="btn btn-primary btn-pill px-4">Continue</Link> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default FacilitatorSignupStepThree
