import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SideMenu from "./SideMenu";

function JoinClass() {

  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <div className="chat-student-list mt-3">
                  <ul>
                    <li>
                      <img src="images/student1.jpg" alt="" />
                      <div className="chat-student-name">Sarah D. | Facilitator</div>
                    </li>
                    <li>
                      <img src="images/student2.jpg" alt="" />
                      <div className="chat-student-name">Dave D. | Facilitator</div>
                    </li>
                    <li>
                      <img src="images/student3.jpg" alt="" />
                      <div className="chat-student-name">Student ID: 567876</div>
                    </li>
                    <li>
                      <img src="images/student2.jpg" alt="" />
                      <div className="chat-student-name">Student ID: 567876</div>
                    </li>
                  </ul>
                </div>
                <div className="chat-teacher mt-3">
                  <img src="images/teacher-big.jpg" alt="" />
                  <div className="chat-teacher-name">John D.</div>
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/chat-icon1.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/chat-icon2.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/chat-icon3.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/chat-icon4.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/chat-icon5.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/chat-icon6.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="right">
            <div className="card mt-3">
              <h5 className="text-muted">Group Chat</h5>
              <div className="group-chat">
                <ul>
                  <li>
                    <div className="to-text">
                      <div className="sender-avatar">
                        <img src="images/avatar.jpg" alt="" />
                      </div>
                      <div className="sender-text">
                        <h6>Student ID: 567876</h6>
                        <div className="avatar-text-bg">
                          <p>I just did. I do have a few queries regarding quadratic equations? </p>
                          <div className="time">8:00</div>
                        </div>
                      </div>
                    </div>
                    <div className="from-text">
                      <h6 className="text-end">Me To Everyone</h6>
                      <div className="sender-text">
                        <p>I can help you with that. Send me an image of what you have so far and I can help you with it?  </p>
                        <div className="time">8:03</div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="to-text">
                      <div className="sender-avatar">
                        <img src="images/avatar.jpg" alt="" />
                      </div>
                      <div className="sender-text">
                        <h6>Student ID: 567876</h6>
                        <div className="avatar-text-bg">
                          <p>Absolutely! Will send it right away. Thanks for your help.</p>
                          <div className="time">8:09</div>
                        </div>
                      </div>
                    </div>
                    <div className="from-text">
                      <h6 className="text-end">Me To Everyone</h6>
                      <div className="sender-text">
                        <p>Sure no problem. Happy to help!</p>
                        <div className="time">8:03</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="chat-input">
                <Form.Control type="text" placeholder="Type your message…" />
                <div className="chat-attach">
                  <ul>
                    <li>
                      <img src="images/attach-icon.png" width="12" alt="" />
                    </li>
                    <li>
                      <img src="images/img-icon.png" width="20" alt="" />
                    </li>
                  </ul>
                </div>
                <Button variant="primary" type="submit" className="chat-send">
                  <img src="images/send-icon.png" alt="" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default JoinClass
