import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import config from "./../../config";
import { Student } from '../../Services/Student';
import TextField from "@material-ui/core/TextField";
import validator from "validator";
import { FecthData } from '../../Services/FetchData';
import ActionButton from '../ActionButton';
import { staticData } from '../../helpers/data';

function TutorSignupStepOne({ nextStep, handleImage, handleFormData, handleSchool, values }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState(values.school_name);
  const [validated, setValidated] = useState(false);
  const [school, setSchool] = useState('');
  const [error, setError] = useState();
  const [grade, setGrade] = useState();
  const [selectGrade, setSelectGrade] = useState(values.grade_id);
  const [error1, setError1] = useState();
  const [error2, setError2] = useState();


  const submitFormData = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      nextStep();
    }
  };

  const validateForm = () => {

    let fields = values;
    let errors = {};
    let formIsValid = true;

    if (!fields["school_name"] || fields["school_name"].trim().length <= 0) {
      formIsValid = false;
      errors["school_name"] = "*Please select Your school.";
    }

    if (!fields["tutoring_experience"]) {
      formIsValid = false;
      errors["tutoring_experience"] = "*Please select your Experience.";
    }
    setError2(errors)
    return formIsValid;


  }
  useEffect(() => {
    FecthData.getData('tutor/get_school_and_company').then(
      (data) => setSchool(data),
    )
    // Student.getSchool({ is_registered: false }).then(
    //   (data) => setSchool(data),
    //   (error) => setError(error.toString())
    // );


  }, [])

  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '25%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Account Creation</h6>
                    <h6>25% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>{staticData?.verbiage?.tutor[1]?.heading} </h4>
                    <p>{staticData?.verbiage?.tutor[1]?.text} </p>
                    <div className="signup-step">
                      <ul>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <Form.Group className="form-group my-2" style={{ zIndex: 2 }}>
                        {/* <Form.Control type="text" placeholder="Search" /> */}
                        <Autocomplete className="search"
                          items={school?.data || []}
                          shouldItemRender={(item, value
                          ) => item[item.type + "_name"].toLowerCase()
                            .indexOf(value.toLowerCase()) > -1}
                          getItemValue={item => item[item.type + "_name"]}
                          renderItem={(item, isHighlighted) =>
                            <div style={{
                              background: isHighlighted ?
                                '#eee' : 'white',
                              padding: '5px 10px',
                            }}
                              {...item} key={item._id}>
                              {item[item.type + "_name"]}
                            </div>
                          }
                          defaultValue={values.school_name}
                          value={value}
                          onChange={(e) => {
                            setValue(e.target.value)
                            handleFormData(e)
                          }}
                          onSelect={(val, item) => {
                            setValue(val)
                            handleSchool('school', item)
                            handleSchool('school_id', item._id)
                            handleSchool('school_name', item[item.type + "_name"])
                            // handleSchool('school_code', item[item.type + "_code"])
                            // handleSchool('school_street', item.street)
                            // handleSchool('school_city', item.city)
                            // handleSchool('school_state', item.state)
                            // handleSchool('school_zip', item.zip_code)
                            handleSchool('org_type', item.type)
                          }}
                          inputProps={{
                            style: {
                              width: '100%',
                              background: '#fff',
                              boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                              color: '#000',
                              borderRadius: 10,
                              minHeight: 52,
                              border: '1px solid #E9ECF4',
                              fontSize: 14,
                              paddingLeft: 15,
                              paddingRight: 15,
                            },
                            name: 'school',
                            placeholder: 'Search by School/Company name',
                            required: true
                          }}
                          wrapperStyle={{
                            width: '100%',
                          }}
                        />
                        <Button variant="primary" type="submit" className="search-btn">
                          <img src="images/search-icon.png" alt="" width="22" />
                        </Button>
                        <span className="err">{error2?.school_name}</span>
                      </Form.Group>
                      <div className="text-end">
                        <a href="javascript:void(0)" className="text-primary fw-500" onClick={handleShow}>Can’t find your school?</a>
                      </div>
                      <div className="subject-name" style={{ height: 'auto', overflowY: 'inherit', marginTop: 108 }}>
                        <h6 className="text-muted text-uppercase" style={{ fontSize: 14 }}>How many years of TEACHING/TUTORING experience do you have?</h6>
                        <div className="label-wrap text-break">
                          <input type='radio' name='tutoring_experience' value='0' id="one" onChange={(e) => handleSchool('tutoring_experience', '0')} checked={(values.tutoring_experience === '0')} />
                          <label htmlFor="one">
                            <div className="sub-img">
                              <img src="images/grade-star.png" alt="" />
                            </div>
                            <h6 className="yellow">NO EXPERIENCE/<br />CURRENT STUDENT</h6>
                          </label>
                          <input type='radio' name='tutoring_experience' value='less than one Year' id="two" onChange={(e) => handleSchool('tutoring_experience', 'less than one Year')} checked={(values.tutoring_experience === 'less than one Year')} />
                          <label htmlFor="two">
                            <div className="sub-img">
                              <img src="images/grade-star.png" alt="" />
                            </div>
                            <h6 className="yellow">LESS THAN A YEAR</h6>
                          </label>
                          <input type='radio' name='tutoring_experience' value='2-5 Years' id="three" onChange={(e) => handleSchool('tutoring_experience', '2-5 Years')} checked={(values.tutoring_experience === '2-5 Years')} />
                          <label htmlFor="three">
                            <div className="sub-img">
                              <img src="images/grade-star.png" alt="" />
                            </div>
                            <h6 className="yellow">2-5 YEARS</h6>
                          </label>
                          <input type='radio' name='tutoring_experience' value='5+ Years' id="four" onChange={(e) => handleSchool('tutoring_experience', '5+ Years')} checked={(values.tutoring_experience === '5+ Years')} />
                          <label htmlFor="four">
                            <div className="sub-img">
                              <img src="images/grade-star.png" alt="" />
                            </div>
                            <h6 className="yellow">5+ Years</h6>
                          </label>
                        </div>
                        <span className="err">{error2?.tutoring_experience}</span>
                      </div>
                      <div className="signup-back-btn">
                        <Link to="/tutor" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Continue'}
                          requiredFields={[values?.school_id, values?.tutoring_experience]} />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Continue</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Can't find your school?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-text text-center">
            It looks like your school is not registered with us. Enter the name of your school and we can reach out to them and express your interest in Dasya.
          </p>
          <Form>
            <Form.Group className="my-4">
              <Form.Control className="bg-white" type="text" placeholder="Enter your school name" />
            </Form.Group>
          </Form>
          <div className="text-center">
            <Button variant="primary" onClick={handleClose} className="px-5">
              Send
            </Button>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default TutorSignupStepOne
