import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SchoolInternalSideMenu from "./SchoolInternalSideMenu";

function SchoolInternalProfileSettingGrades() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modal, setModal] = useState(false);
  const handleClose1 = () => setModal(false);
  const handleShow1 = () => setModal(true);

  const [conf, setConf] = useState(false);
  const handleClose2 = () => setConf(false);
  const handleShow2 = () => setConf(true);


  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SchoolInternalSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={6} className="mt-4">
                  <h3 className="text-muted mb-0">  Profile Settings</h3>
                </Col>
                <Col md={6} className="mt-4">
                  <div className="filters">
                    <ul>

                      <li>
                        <a href="#">Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a>
                      </li>
                      <li>
                        <a href="#">Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a>
                      </li>
                      <li onClick={handleShow}>
                        <a className="btn btn-primary" href="#">Add <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category" style={{ paddingLeft: 0 }}>
                    <ul style={{ justifyContent: 'flex-start' }}>
                      <li >
                        <Link to="/School-Internal-Profile-Setting">Subjects</Link>
                      </li>
                      <li className='active'>
                        <Link to="/School-Internal-Profile-Setting-Grades">Grades</Link>
                      </li>
                      <li>
                        <Link to="/School-Internal-Profile-Setting-Avatar">Avatar</Link>
                      </li>

                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive profilStingSec">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Icons</th>
                          <th scope="col">Grade</th>
                          <th scope="col">Status</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className='userProfile'>
                              <img src="images/g-icn1.png" alt="" width="44" className="rounded me-2" />
                            </div>

                          </td>
                          <td>Grade 1</td>


                          <td>
                            <Form.Group style={{ minWidth: 120 }}>
                              <Form.Select className="declineSlct">
                                <option> Enabled</option>
                                <option>Disabled</option>
                              </Form.Select>
                            </Form.Group>
                          </td>

                          <td className='text-end'>
                            <a href="#" onClick={setConf}> <img src="images/edit-icn.png" alt="" width="29" className="rounded me-2" /></a>


                            <a href="#" onClick={setModal}>  <img src="images/delete.png" alt="" width="23" className="rounded me-2" /></a>
                          </td>

                        </tr>

                        <tr>
                          <td>
                            <div className='userProfile'>
                              <img src="images/g-icn2.png" alt="" width="44" className="rounded me-2" />
                            </div>

                          </td>
                          <td>Grade 2</td>

                          <td>
                            <Form.Group style={{ minWidth: 120 }}>
                              <Form.Select className="declineSlct">
                                <option> Enabled</option>
                                <option>Disabled</option>
                              </Form.Select>
                            </Form.Group>
                          </td>

                          <td className='text-end'>
                            <a href="#" onClick={setConf}> <img src="images/edit-icn.png" alt="" width="29" className="rounded me-2" /></a>


                            <a href="#" onClick={setModal}>  <img src="images/delete.png" alt="" width="23" className="rounded me-2" /></a>
                          </td>

                        </tr>

                        <tr>
                          <td>
                            <div className='userProfile'>
                              <img src="images/g-icn3.png" alt="" width="44" className="rounded me-2" />
                            </div>

                          </td>
                          <td>Grade 3</td>

                          <td>
                            <Form.Group style={{ minWidth: 120 }}>
                              <Form.Select className="declineSlct">
                                <option> Enabled</option>
                                <option>Disabled</option>
                              </Form.Select>
                            </Form.Group>
                          </td>

                          <td className='text-end'>
                            <a href="#" onClick={setConf}> <img src="images/edit-icn.png" alt="" width="29" className="rounded me-2" /></a>


                            <a href="#" onClick={setModal}>  <img src="images/delete.png" alt="" width="23" className="rounded me-2" /></a>
                          </td>

                        </tr>

                        <tr>
                          <td>
                            <div className='userProfile'>
                              <img src="images/g-icn4.png" alt="" width="44" className="rounded me-2" />
                            </div>

                          </td>
                          <td>Grade 4</td>

                          <td>
                            <Form.Group style={{ minWidth: 120 }}>
                              <Form.Select className="declineSlct">
                                <option> Enabled</option>
                                <option>Disabled</option>
                              </Form.Select>
                            </Form.Group>
                          </td>

                          <td className='text-end'>
                            <a href="#" onClick={setConf}> <img src="images/edit-icn.png" alt="" width="29" className="rounded me-2" /></a>


                            <a href="#" onClick={setModal}>  <img src="images/delete.png" alt="" width="23" className="rounded me-2" /></a>
                          </td>

                        </tr>

                        <tr>
                          <td>
                            <div className='userProfile'>
                              <img src="images/g-icn5.png" alt="" width="44" className="rounded me-2" />
                            </div>

                          </td>
                          <td>Grade 5</td>

                          <td>
                            <Form.Group style={{ minWidth: 120 }}>
                              <Form.Select className="declineSlct">
                                <option> Enabled</option>
                                <option>Disabled</option>
                              </Form.Select>
                            </Form.Group>
                          </td>

                          <td className='text-end'>
                            <a href="#" onClick={setConf}> <img src="images/edit-icn.png" alt="" width="29" className="rounded me-2" /></a>


                            <a href="#" onClick={setModal}>  <img src="images/delete.png" alt="" width="23" className="rounded me-2" /></a>
                          </td>

                        </tr>

                        <tr>
                          <td>
                            <div className='userProfile'>
                              <img src="images/g-icn6.png" alt="" width="44" className="rounded me-2" />
                            </div>

                          </td>
                          <td>Grade 6</td>

                          <td>
                            <Form.Group style={{ minWidth: 120 }}>
                              <Form.Select className="declineSlct">
                                <option> Enabled</option>
                                <option>Disabled</option>
                              </Form.Select>
                            </Form.Group>
                          </td>

                          <td className='text-end'>
                            <a href="#" onClick={setConf}> <img src="images/edit-icn.png" alt="" width="29" className="rounded me-2" /></a>


                            <a href="#" onClick={setModal}>  <img src="images/delete.png" alt="" width="23" className="rounded me-2" /></a>
                          </td>

                        </tr>

                        <tr>
                          <td>
                            <div className='userProfile'>
                              <img src="images/g-icn7.png" alt="" width="44" className="rounded me-2" />
                            </div>

                          </td>
                          <td>Grade 7</td>

                          <td>
                            <Form.Group style={{ minWidth: 120 }}>
                              <Form.Select className="declineSlct">
                                <option> Enabled</option>
                                <option>Disabled</option>
                              </Form.Select>
                            </Form.Group>
                          </td>

                          <td className='text-end'>
                            <a href="#" onClick={setConf}> <img src="images/edit-icn.png" alt="" width="29" className="rounded me-2" /></a>


                            <a href="#" onClick={setModal}>  <img src="images/delete.png" alt="" width="23" className="rounded me-2" /></a>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="right">
            <div className="user-short-text">
              <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div>
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src="images/no_profile_img.png" alt="" />
                </div>
                <div className="user-short-name">
                  <h5>Balaji</h5>
                  <p>Status: Admin</p>
                </div>
                <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>


      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton className='text-center'>
          <Modal.Title >Add Subject</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <div className="card">
                <div className="school-side-details">
                  <Form>
                    <Row>
                      <Form.Group className="form-group my-3" as={Col} md="12">
                        <div className="upload-resource">
                          <img src="images/camera-icon.png" alt="" width="78" />
                          <div className='uploadTxt'> Upload Icon</div>
                          <input type="file" />
                        </div>

                      </Form.Group>


                      <Form.Group className="form-group mb-3" as={Col} md="12">
                        <Form.Select className="bg-grey">
                          <option>Select Category</option>
                          <option value="1">Category 1</option>
                          <option value="2">Category 2</option>
                          <option value="3">Category 3</option>
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="form-group mb-3" as={Col} md="12">
                        <Form.Control className="bg-grey" type="text" placeholder="Enter Subject" />
                      </Form.Group>


                      <Col md="12" className="text-center mt-2">
                        <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={handleShow}>Confirm</Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={modal} onHide={handleClose1}
        size="md"
        centered
      >
        <Modal.Header closeButton className='text-center'>
          <Modal.Title >Delete Subject </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='text-center'>
            <img src="images/delete.png" alt="" width="58" className="rounded mb-3" />
            <p className="modal-text ">
              Are you sure you want to delete this grade? Deleting this grade will remove it from the the students grade selection page.
            </p>
            <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={handleClose1}>Confirm</Button>

          </div>
        </Modal.Body>
      </Modal>

      <Modal show={conf} onHide={handleClose2}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Grade</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form>
            <Row>
              <Form.Group className="form-group my-3" as={Col} md="12">
                <div className="upload-resource">

                  <img src="images/g-icn1.png" alt="" width="107" />

                  <div className='popcamraIcn'> <img src="images/camera-icon.png" alt="" width="78" /></div>
                  <input type="file" />
                </div>

              </Form.Group>


              <Form.Group className="form-group mb-3" as={Col} md="12">

              </Form.Group> <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control className="bg-grey" type="text" placeholder="Enter Grade" />
              </Form.Group>




              <Col md="12" className="text-center mt-2">
                <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={handleClose2}>Confirm</Button>
              </Col>
            </Row>
          </Form>

        </Modal.Body>
      </Modal>


    </>
  )
}

export default SchoolInternalProfileSettingGrades
