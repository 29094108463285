import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
  } from 'react-bootstrap';
import { NavLink, Link } from "react-router-dom";

export default class Default extends Component {
    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="notfound">
                                <div className="notfound-404">
                                    <h1>404</h1>
                                </div>
                                <h2>Oops! Nothing was found</h2>
                                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable. <Link to="/login">Return to homepage</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
