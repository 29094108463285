import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link, useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import OtpInput from 'react-otp-input';
import ActionButton from '../ActionButton';
import { staticData } from '../../helpers/data';

function FacilitatorSignupStepOne({ nextStep, handleImage, handleFormData, handleSchool, values }) {
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState();
  const [message, setMessage] = useState(null);
  let { id } = useParams();
  const onChange = (value) => {
    setOtp(value);
  }

  const inputfocus = (elmnt, index) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus()
      }
    }

    // if (isNaN(elmnt.target.value)) return false;
    ;

    setOtp(prevState => ({
      ...prevState,
      [index]: elmnt.target.value
    }));

  }

  const submitFormData = (e) => {
    e.preventDefault();
    if (otp && otp?.length === 6) {
      // School.verifyOtp({ otp: otp, token: id }, 'guardian/verify_gurdian_otp').then(
      //   (data) => {
      //     if (data.error === 0) {

      //       // fetchData()

      //     }
      //     

      //   },
      //   (error) => {
      //     NotificationManager.error(error.toString(), 'error!', 2000);
      //     setError(error.toString())
      //   }
      // )
      School.verifyOtp({ otp: otp, email: values?.email }).then(
        (data) => {
          if (data.error === 0) {
            handleSchool('otp_verified', true)
            NotificationManager.success(data.message, 'success!', 2000);
            setMessage(data.message)
            nextStep();
          }


        },
        (error) => {
          NotificationManager.error(error.toString(), 'error!', 2000);
          setError(error.toString())
        }
      )
    } else {
      NotificationManager.error('please enter six digit valid OTP', 'Error!', 2000);
    }
    // nextStep();
  };
  // useEffect(() => {
  //   if (id != '') {
  //     resendOtp()
  //   }
  // }, [id])

  const resendOtp = () => {
    // e.preventDefault();
    // if (id !== '') {
    //   School.post({ token: id }, 'facilitator/send_otp_facilitator').then(
    //     (data) => {
    //       if (data.error === 0) {
    //         NotificationManager.success(data.message, 'success!', 2000);
    //       }
    //       

    //     },
    //     (error) => setError(error.toString())
    //   )
    // } else {
    //   NotificationManager.error('Email is required', 'error!', 2000);
    // }
    if (values?.email) {
      School.sendEmail({ email: values?.email, in_register: true }).then(
        (data) => {
          if (data.error === 0) {
            NotificationManager.success(data.message, 'success!', 2000);
            // setMessage(data.message)

          }


        },
        (error) => {
          // setError(error.toString())
          // NotificationManager.success(error.toString(), 'Error!', 2000);
        }
      )
      // nextStep();

    } else {
      NotificationManager.warning('Email is required', '!', 2000);
    }

  };
  const fetchData = () => {
    School.post({ token: id }, 'guardian/get_students_by_token').then(
      (data) => {
        if (data.error === 0) {
          handleSchool('student_id', data.data._id)
          handleSchool('first_name', data.data.first_name)
          handleSchool('last_name', data.data.last_name)
          handleSchool('email', data.data.email)
          handleSchool('academic_year', data.data.academic_year)
          handleSchool('student_code', data.data.student_code)
          handleSchool('gender_avatar', data.data.gender_avatar)
          handleSchool('require_facilitator', data.data.require_facilitator)
          handleSchool('facilitator_email', data.data.facilitator_email)
          handleSchool('phone', data.data?.phone)
          handleSchool('address', data.data?.address)
          nextStep();
        }


      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
  }
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '25%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Pending Activation</h6>
                    <h6>25% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>{staticData?.verbiage?.facilitator[1]?.heading}</h4>
                    <p> {staticData?.verbiage?.facilitator[1]?.text}</p>
                    <div className="signup-step">
                      <ul>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <div className="verify-account">
                        <img src="images/lock-icon.png" alt="" width="76" />
                        <h4 className="my-3">Enter The Code</h4>
                        <p>
                          Protecting your account is our top priority. An email with the verification code <br />
                          has been sent. Please confirm your account by entering the authorization<br /> code sent to the email registered with us.
                        </p>
                        <div className="new-otp-field">
                          <OtpInput
                            value={otp}
                            containerStyle="otp-input"
                            inputStyle="form-control"
                            onChange={onChange}
                            numInputs={6}
                            isInputNum={true}
                          />
                        </div>
                        <p>
                          It may take a minute to receive your code.<br />
                          Haven’t received it? Resend a new code <Link to="#" onClick={resendOtp}>Resend a new code</Link>
                        </p>
                      </div>
                      <div className="signup-back-btn">
                        <Link to="/facilitator" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Continue'}
                          requiredFields={otp?.length == 6 ? otp : ''} />
                        {/* <button className="btn btn-primary btn-pill px-4" onClick={nextStep}>Skip</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default FacilitatorSignupStepOne
