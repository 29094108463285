import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import SessionCalendar from './SessionCalendar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../config';
import { FecthData } from '../../Services/FetchData';
import _ from 'lodash';
import GraphUI from '../common/GraphUI';
import { getWeekOfMonth } from '../../helpers/utils';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    },
    title: {
      display: true,
      text: '',
    },
  },
};

const labels = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const data = {
  labels,
  datasets: [
    {
      label: 'Algebra',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#914CE9',
      ],
    },
    {
      label: 'Chemistry',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#FEB41C',
      ],
    },
    {
      label: 'Physics',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#D0202C',
      ],
    },
    {
      label: 'Geometry',
      data: {
        MONDAY: 0,
        TUESDAY: 0,
        WEDNESDAY: 0,
        THURSDAY: 0,
        FRIDAY: 0,
        SATURDAY: 0,
        SUNDAY: 0,
      },
      backgroundColor: [
        '#73913E',
      ],
    },
  ],
};

ChartJS.register(Tooltip, Legend);

function Progress() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [interval, setInterval] = useState('weekly')
  const [labels, setLabels] = useState(['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'])
  const [datasets, setDatasets] = useState(data.datasets)
  const [statistics, setStatistics] = useState()
  const history = useHistory()
  const colorObj = {
    Algebra: '#914CE9',
    Chemistry: '#FEB41C',
    Physics: '#D0202C',
    Geometry: '#73913E',
    Biology: '#d32833',
    Calculus: '#7b2cbf'
  }
  const selectedDay = val => {
  };
  const logout = (url) => {
    localStorage.clear()
    history.push(url)
  }
  const getStudentProgress = () => {
    FecthData.getData('student/get_student_progress/' + user?.student?._id + '?interval=' + interval).then(
      (data) => {
        var labels1 = interval == 'weekly' ? ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] : ['WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4', 'WEEK 5'];
        let arr1 = []
        data?.data.map(pr => {
          var obj = {
            label: pr?._id?.subject_name,
            data: interval == 'weekly' ? {
              MONDAY: new Date(pr?._id?.date).getDay() == 1 ? (pr.total_hours / 60).toFixed(2) : 0,
              TUESDAY: new Date(pr?._id?.date).getDay() == 2 ? (pr.total_hours / 60).toFixed(2) : 0,
              WEDNESDAY: new Date(pr?._id?.date).getDay() == 3 ? (pr.total_hours / 60).toFixed(2) : 0,
              THURSDAY: new Date(pr?._id?.date).getDay() == 4 ? (pr.total_hours / 60).toFixed(2) : 0,
              FRIDAY: new Date(pr?._id?.date).getDay() == 5 ? (pr.total_hours / 60).toFixed(2) : 0,
              SATURDAY: new Date(pr?._id?.date).getDay() == 6 ? (pr.total_hours / 60).toFixed(2) : 0,
              SUNDAY: new Date(pr?._id?.date).getDay() == 7 ? (pr.total_hours / 60).toFixed(2) : 0,
            } : {
              'WEEK 1': getWeekOfMonth(pr?._id?.date) == 0 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 2': getWeekOfMonth(pr?._id?.date) == 1 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 3': getWeekOfMonth(pr?._id?.date) == 2 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 4': getWeekOfMonth(pr?._id?.date) == 3 ? (pr.total_hours / 60).toFixed(2) : 0,
              'WEEK 5': getWeekOfMonth(pr?._id?.date) == 4 ? (pr.total_hours / 60).toFixed(2) : 0,
            },
            backgroundColor: [
              colorObj[`${pr?._id?.subject_name}`]
            ],
          }
          arr1.push(obj)
        })
        setLabels(labels1)
        setDatasets(arr1)
      }
    )
  }
  const getStudentStatistics = () => {
    FecthData.getData('student/get_student_progress_count/' + user?.student?._id).then(
      (data) => {
        setStatistics(data?.data)

      }
    )
  }
  useEffect(() => {
    getStudentStatistics()
  }, [])
  useEffect(() => {
    getStudentProgress()
  }, [interval])
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SideMenu />
          <div className="middle">
            <Row>
              <GraphUI options={options} state={{
                labels: labels, datasets: datasets
              }} statistics={statistics} setInterval={setInterval} />
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
              <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.student?.gender_avatar?.icon_image && <img src={getResourceUrl(user?.student?.gender_avatar?.icon_image)} width={'100%'} height={'100%'} alt="" />}{!user?.student?.gender_avatar?.icon_image && <img src="images/avatar2.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.student_profile?.school_name}</h5>
                  <p><small>ID: {user?.student?.student_code}</small></p>
                  <p>Academic Year: {user?.student?.academic_year}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => logout('/')}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <SessionCalendar />
            {/* <div className="upcom-class">
              <h5 className="text-muted">Calendar</h5>
              <DatePicker getSelectedDay={selectedDay}
                endDate={100}
                selectDate={new Date("2022-02-08")}
                labelFormat={"MMMM"}
                color={"#287AEB"}          
              />
              <div className="upcoming-class-schedule">
                <ul>
                  <li className="eight-am">
                    <div className="left">
                      <p>8 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Chemistry with Jane D.</h5>
                        <p>8:00 am-9:00 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="nine-am">
                    <div className="left">
                      <p>9 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Algebra with Sarah D.</h5>
                        <p>9:00 am-9:30 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="ten-am mt-4">
                    <div className="left">
                      <p>10 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Geography with John M.</h5>
                        <p>8:00 am-9:00 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="eleven-am no-class">
                    <div className="left">
                      <p>11 AM</p>
                    </div>
                    <div className="right">
                      
                    </div>
                  </li>
                  <li className="twelve-pm">
                    <div className="left">
                      <p>12 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Physics with David G.</h5>
                        <p>12:00 pm-1:00 pm</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="one-pm">
                    <div className="left">
                      <p>1 PM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Geometry with Jane D.</h5>
                        <p>1:00 pm-1:30 pm</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>2 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>3 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>4 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>5 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>6 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>

    </>
  )
}

export default Progress
