import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Tabs,
  Tab,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import config from '../../config';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Student } from '../../Services/Student';
import { NotificationManager } from 'react-notifications';
import { userInfo } from '../../helpers/user-builder';
import { Organization } from '../../Services/Organization';
import FieldErrors from '../../Services/FieldErrors';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';

function EditStudentAccount() {
  const editableUser = useLocation().state
  const buildUser = userInfo(editableUser)
  const [state, setState] = useState({
    ...buildUser
    //     name:editableUser.first_name+" "+editableUser.last_name,
    // student_code:editableUser.student_code,
    // email:editableUser.email,
    // guardian_email:editableUser.guardian_email,
    // gender_id:editableUser.gender_avatar,
    // phone:editableUser.phone,
    // school_id:editableUser.school_id,
    // grade_id:editableUser.grade_id,
    // academic_year:editableUser.academic_year,
    // status:editableUser.user_details[0].status
  })

  const user = JSON.parse(localStorage.getItem('user'))
  const [school, setSchool] = useState(null);
  const [company, setCompany] = useState(null);
  const [error, setError] = useState();
  const [fieldError, setFieldError] = useState();
  const [error1, setError1] = useState();
  const [grade, setGrade] = useState();
  const [gender, setGender] = useState()
  useEffect(() => {
    Student.getDta({ page: 0 }, 'grades/get_all_grade').then(
      (data) => setGrade(data.data),
      (error) => setError1(error.toString())
    );
    Student.getSchool({ is_registered: false }).then(
      (data) => setSchool(data.data),
      (error) => setError(error.toString())
    );
    Student.getDta({ page: 0 }, 'gender/get-all-gender-avatar').then(
      (data) => setGender(data.data),
      (error) => setError1(error.toString())
    );
    Organization.getCategory({ is_registered: true }).then(
      (data) => setCompany(data.data),
      (error) => setError(error.toString()))
  }, [])

  const submit = () => {
    const name = state.name.split(" ")
    const payloadData = { ...state }
    const url = state.user_role + "/update_" + state.user_role
    Student.update(payloadData, url).then(
      (data) => {
        ;
        if (data?.data) {
          NotificationManager.success(data.message, 'success!', 2000);
          setFieldError(null)
          // setSubject(data.data)
        } else {
          setFieldError(data.errors.details)

        }

      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error)
      }
    )
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="card">
                  {/* <div className="upload-profile student-setting">
                    <div className="upload-profile-img">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <div className="upload-icon">
                      <img src="images/camera-icon.png" alt="" width="78" />
                      <input type="file" />
                    </div>
                  </div> */}
                  <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="home" title="Account Details">
                      <div className="account-setting">
                        <h5 className="text-muted">Basic Information</h5>
                        <Form>
                          <Row>
                            <Form.Group className="mb-3" as={Col} md="6">
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" value={state.name} onChange={(e) => setState({ ...state, name: e.target.value })} />
                              <FieldErrors errors={fieldError} field="name" />
                            </Form.Group>
                            {state?.student_code ? <Form.Group className="mb-3" as={Col} md="6">
                              <Form.Label>Student ID</Form.Label>
                              <Form.Control type="text" value={state.student_code} readOnly />
                            </Form.Group> : null}
                            <Form.Group className="mb-3" as={Col} md="6">
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="email" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
                              <FieldErrors errors={fieldError} field="email" />
                            </Form.Group>
                            {state?.guardian_email != null ? <Form.Group className="mb-3" as={Col} md="6">
                              <Form.Label>Parents/Guardian Email</Form.Label>
                              <Form.Control type="email" value={state.guardian_email} onChange={(e) => setState({ ...state, guardian_email: e.target.value })} />
                              <FieldErrors errors={fieldError} field="guardian_email" />
                            </Form.Group> : null}
                          </Row>
                          <Row>
                            {state?.gender_id ? <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={state.gender_id} onChange={(e) => setState({ ...state, gender_id: e.target.value })}>
                                  {gender?.map(item => {
                                    if (item.avatars.length > 0) {
                                      return <option value={item.avatars[0]._id}>{item.name}</option>
                                    }
                                  })}
                                </Form.Select>
                              </Form.Group>
                            </Col> : null}
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control type="tel" value={state.phone} onChange={(e) => setState({ ...state, phone: e.target.value })} />
                                <FieldErrors errors={fieldError} field="phone" />
                              </Form.Group>
                            </Col>
                            {state?.school_id ? <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>School</Form.Label>
                                <Form.Select aria-label="Default select example" defaultActiveKey={state?.school_id} onChange={(e) => setState({ ...state, school_id: e.target.value })}>
                                  {school?.map(item => <option value={item._id}>{item.school_name}</option>)}
                                  {/* <option value="1">Mather High School, IL</option>
                                  <option value="2">Marion High School, IL</option>
                                  <option value="3">Don Bosco School</option> */}
                                </Form.Select>
                              </Form.Group>
                            </Col> : null}
                            {state?.company_id ? <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Company</Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={state?.company_id} onChange={(e) => setState({ ...state, company_id: e.target.value })}>
                                  {company?.map(item => <option value={item._id}>{item.company_name}</option>)}
                                  {/* <option value="1">Mather High School, IL</option>
                                  <option value="2">Marion High School, IL</option>
                                  <option value="3">Don Bosco School</option> */}
                                </Form.Select>
                              </Form.Group>
                            </Col> : null}
                            {state?.grade_id ? <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Grade</Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={state?.grade_id} onChange={(e) => setState({ ...state, grade_id: e.target.value })}>
                                  {grade?.map(item => <option value={item._id}>{item.title}</option>)}
                                </Form.Select>
                              </Form.Group>
                            </Col> : null}
                            {state?.about != null ? <Form.Group className="mb-3" as={Col} md="6">
                              <Form.Label>About</Form.Label>
                              <Form.Control type="text" as={"textarea"} value={state?.about} onChange={(e) => setState({ ...state, about: e.target.value })} />
                              <FieldErrors errors={fieldError} field="about" />
                            </Form.Group> : null}
                            {state?.highest_education_background ? <Form.Group className="mb-3" as={Col} md="6">
                              <Form.Label>Education</Form.Label>
                              <Form.Select aria-label="Default select example" defaultValue={state?.highest_education_background} onChange={(e) => setState({ ...state, highest_education_background: e.target.value })}>
                                <option value="1">Highest Education Background 1</option>
                                <option value="2">Highest Education Background 2</option>
                                <option value="3">Highest Education Background 3</option>
                              </Form.Select>
                            </Form.Group> : null}
                            {state?.tutoring_experience ? <Form.Group className="mb-3" as={Col} md="6">
                              <Form.Label>Experience</Form.Label>
                              <Form.Select aria-label="Default select example" defaultValue={state?.tutoring_experience} onChange={(e) => setState({ ...state, tutoring_experience: e.target.value })}>
                                <option value={"0"}>NO EXPERIENCE CURRENT STUDENT</option>
                                <option value="< 1 Year">less then one Year</option>
                                <option value="2-5 Years">2-5 Years</option>
                                <option value="5+ Years">5+ Years</option>
                              </Form.Select>
                            </Form.Group> : null}
                            {state?.timezone ? <Form.Group className="mb-3" as={Col} md="6">
                              <Form.Label>Timezone</Form.Label>
                              <Form.Select aria-label="Default select example" defaultValue={state?.timezone} onChange={(e) => setState({ ...state, timezone: e.target.value })}>
                                <option value="1">timezone 1</option>
                                <option value="2">timezone 2</option>
                                <option value="3">timezone 3</option>
                              </Form.Select>
                            </Form.Group> : null}
                            {state?.academic_year != null ? <Form.Group className="mb-3" as={Col} md="6">
                              <Form.Label>Academic Year</Form.Label>
                              <Form.Control type="text" value={state?.academic_year} onChange={(e) => setState({ ...state, academic_year: e.target.value })} />
                              <FieldErrors errors={fieldError} field="academic_year" />
                            </Form.Group> : null}
                            {state?.department != null ? <Form.Group className="mb-3" as={Col} md="6">
                              <Form.Label>Department</Form.Label>
                              <Form.Control type="text" value={state?.department} onChange={(e) => setState({ ...state, department: e.target.value })} />
                              <FieldErrors errors={fieldError} field="department" />
                            </Form.Group> : null}
                            <Col md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Status</Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={state.status} onChange={(e) => setState({ ...state, status: e.target.value })}>
                                  <option value="Active">Active</option>
                                  <option value="Inactive">Inactive</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Button variant="primary" onClick={submit}>
                            Save Changes
                          </Button>
                        </Form>
                        <hr />
                        <h5 className="text-muted">Accessbility</h5>
                        <Form>
                          <div className="accessbility mb-3">
                            <Form.Check
                              type="checkbox"
                              id="one"
                              label="Request a facilitator"
                              checked
                            />
                            <Form.Check
                              type="checkbox"
                              id="two"
                              label="Get notifications"
                            />
                            <Form.Check
                              type="checkbox"
                              id="three"
                              label="Use chat feature"
                            />
                            <Button variant="primary" type="submit" disabled className="mt-3">
                              Save Changes
                            </Button>
                          </div>
                        </Form>
                        <hr />
                        <h5 className="text-muted">Delete Accounts</h5>
                        <Button variant="danger" type="submit" className="mt-3" style={{ width: 200, }}>
                          Delete Your Account
                        </Button>
                      </div>
                    </Tab>
                    {/* <Tab eventKey="profile" title="Privacy Settings">
                      <div className="account-setting">
                        <Form>
                          <h5 className="text-muted">Change Password</h5>
                          <Form.Group className="mb-3">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="text" value="Jane Doe" />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" />
                          </Form.Group>
                          <Button variant="primary" type="submit">
                            Save Changes
                          </Button>
                        </Form>
                      </div>
                    </Tab> */}
                  </Tabs>
                </div>
              </Col>
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default EditStudentAccount
