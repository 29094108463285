import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link, useHistory } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import { Facilitator } from '../../Services/Facilitator';
import { NotificationManager } from 'react-notifications';
import ActionButton from '../ActionButton';
import { staticData } from '../../helpers/data';
import PrivacyModal from '../common/PrivacyModal';

const formatDate = (date) => {

  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [month, day, year].join('/');
}
function FacilitatorSignupStepFour({ prevStep, handleImage, handleFormData, handleSchool, values }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [isChecked, setIsChecked] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);
  let history = useHistory();
  const submitFormData = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (values.accept_privacy_policy) {
      let formData = new FormData();
      Object.entries(values).map(([key, value]) => {
        formData.append(key, value);
      })
      Facilitator.registerFacilitator(formData).then(
        (data) => {
          if (!data.errors) {
            NotificationManager.success(data.message, 'success!', 2000);
            setMessage(data.message)
            history.push('/facilitator');
          } else {
            NotificationManager.error(data.message, 'error!', 2000);
            setMessage(data.message)
          }


        },
        (error) => {
          NotificationManager.error(error.toString(), 'error!', 2000);
          setError(error.toString())
        }
      )
    } else {
      NotificationManager.error('Please accept privacy policy', 'error!', 2000);
    }

  };
  const handleChange = event => {
    setIsChecked(current => !current);
  };
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '100%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Privacy Notice</h6>
                    <h6>100% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <h4>{staticData?.verbiage?.facilitator[4]?.heading}</h4>
                    <p>{staticData?.verbiage?.facilitator[4]?.text}</p>
                    <div className="signup-step" style={{ marginTop: 260 }}>
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <div className="add-details">
                        <Row>
                          <Col md={12}>
                            <h6 className="text-muted">Privacy notice</h6>
                            {/* <ul >
                              <li style={{listStyle:'inside'}}>
                                <Button variant="link" className='text-primary' onClick={handleShow}>Click here to read Privacy notice</Button>
                              </li>
                            </ul> */}
                            <div  dangerouslySetInnerHTML={{__html: staticData?.privacyText?.facilitator}}></div>

                            {/* <p className="text-muted">{staticData?.privacyText?.facilitator}</p> */}
                          </Col>
                          <Col md={12}>
                            <Form.Group className="iagree mb-3" controlId="formBasicCheckbox">
                              <Form.Check className="text-grey" type="checkbox" name="accept_privacy_policy" onChange={e => handleSchool(e.target.name, e.target.checked)} label={`I have read & accepted the Privacy Notice as on ${formatDate()}`} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <div className="signup-back-btn">
                        <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Submit'}
                          requiredFields={[values?.accept_privacy_policy ? values?.accept_privacy_policy : '']} />
                        {/* <button type="submit" className="btn btn-primary btn-pill px-4">Submit</button> */}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
      <PrivacyModal show={show} privacyText={staticData?.privacyText?.admin} handleClose={handleClose}/>
    </>
  )
}

export default FacilitatorSignupStepFour
