import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Row, Tab, Tabs } from "react-bootstrap";
import AdminLayout from "../admin/Dashboard/AdminLayout";
import { Organization } from "../../Services/Organization";
import { Link } from "react-router-dom";
import { Admin } from "../../Services/Admin";
import { School } from "../../Services/School";
import config from "../../config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NotificationManager } from "react-notifications";
import FieldErrors from "../../Services/FieldErrors";
import OrgSideMenu from "./OrgSideMenu";
import Logout from "../logout";
import { getResourceUrl } from "../../Services/CommanFunctions";
import NotificationUI from "../common/NotificationUI";
import { getMaskedText } from "../../helpers/utils";
import { Student } from "../../Services/Student";

export default function OrgSettings() {
    const [company, setCompany] = useState(null)
    const [error, setError] = useState(null)
    const [masked,doMask]=useState(true)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
    const [password, setPassword] = useState({
        password: '',
        confirm_password: ''
    })
    let history = useHistory()

    const submit = () => {
        user.id = user?.company?._id
        School.post(user, "company_user/update_company_user_self").then(
            (data) => {
                if (data?.data) {
                    NotificationManager.success(data.message, 'success!', 2000)
                    localStorage.setItem('user', JSON.stringify(data?.data))
                } else {
                    NotificationManager.error(data.message, 'error!', 2000)
                    setError(data.errors.details)
                }
            }
        )
    }
    const changePassword = () => {
        Student.update({ ...password, _id: user._id }, "users/change_password").then(
            (data) => {
                if (data?.data) {
                    NotificationManager.success(data.message, 'success!', 2000)
                } else {
                    NotificationManager.error(data.message, 'error!', 2000)
                    setError(data.errors.details)
                }
            }
        )
    }

    // const fetchCompanies = () => {
    //     Organization.getCategory({ is_registered: true }).then(
    //         (data) => setCompany(data),
    //         (error) => setError(error.toString())
    //     )
    // }
    // useEffect(() => {
    //     fetchCompanies()
    // }, [])
    const logout = () => {
        localStorage.clear()
        history.push('/school')
    }
    return <>
        <section style={{ height: '100%', width: 'auto' }}>
            <div className="dashboard-wrap">
                <OrgSideMenu />
                <div className="middle">
                    <Row>
                        <Col md={12}>
                            <Form.Group className="form-group mb-2">
                                <Form.Control type="text" placeholder="Search" className="search-input" />
                                <Button variant="primary" type="submit" className="search-btn">
                                    <img src="images/search-icon.png" alt="" width="22" />
                                </Button>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <div className="card">
                                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                                    <Tab eventKey="home" title="Account Settings">
                                        <div className="account-setting">
                                            <h5 className="text-muted">Basic Information</h5>
                                            <Form>


                                                {/* <Form.Group className="mb-3">
                                            <Form.Label>Parents/Guardian Email</Form.Label>
                                            <Form.Control type="email" value="doejohn@gmail.com" />
                                        </Form.Group> */}
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Disable masking to edit</Form.Label>
                                                            <Form.Check type="switch" defaultChecked value={masked} onChange={(e)=>doMask(e.target.checked)} />
                                                            {/* <FieldErrors errors={fieldError} field="name" /> */}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Name</Form.Label>
                                                            <Form.Control type="text" value={masked?getMaskedText(user?.name,'name'): user?.name} onChange={(e) => setUser({ ...user, name: e.target.value })} disabled={masked}/>
                                                            <FieldErrors errors={error} field="name" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control type="email" value={masked?getMaskedText(user?.email,'email'):user?.email} onChange={(e) => setUser({ ...user, email: e.target.value })} disabled={masked}/>
                                                            <FieldErrors errors={error} field="email" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Username</Form.Label>
                                                            <Form.Control type="text" value={masked?getMaskedText(user?.username,'email'):user?.username} onChange={(e) => setUser({ ...user, username: e.target.value })} disabled/>
                                                            <FieldErrors errors={error} field="username" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Mobile Number</Form.Label>
                                                            <Form.Control type="tel" value={masked?getMaskedText(user?.phone,'phone'):user?.phone} onChange={(e) => setUser({ ...user, phone: e.target.value })} disabled={masked}/>
                                                            <FieldErrors errors={error} field="phone" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                    <Form.Group className="mb-3">
                              <Form.Label>Timezone</Form.Label>
                              <Form.Select name="timezone" onChange={e=>setUser({ ...user, timezone: e.target.value })} defaultValue={user?.timezone}>
                                <option disabled value={''}>Select Time Zone*</option>
                                <option value="EST">EST</option>
                                <option value="CST">CST</option>
                                <option value="PST">PST</option>
                              </Form.Select>
                              <FieldErrors errors={error} field="timezone" />
                            </Form.Group>
                            </Col>
                                                    {/* <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Address</Form.Label>
                                                            <Form.Control type="text" value={user?.address} onChange={(e) => setUser({ ...user, address: e.target.value })} />
                                                            <FieldErrors errors={error} field="address" />
                                                        </Form.Group>
                                                    </Col> */}
                                                    {/* <Col md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Status</Form.Label>
                                                            <Form.Select disabled aria-label="Default select example" defaultValue={user?.status} onChange={(e) => setUser({ ...user, status: e.target.value })}>
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col> */}
                                                    {/* <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>School</Form.Label>
                                                    <Form.Select aria-label="Default select example">
                                                        <option value="1">Mather High School, IL</option>
                                                        <option value="2">Marion High School, IL</option>
                                                        <option value="3">Don Bosco School</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Grade</Form.Label>
                                                    <Form.Select aria-label="Default select example">
                                                        <option value="1">4th Grade</option>
                                                        <option value="2">8th Grade</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col> */}
                                                </Row>
                                                <Button variant="primary" onClick={submit}>
                                                    Save Changes
                                                </Button>
                                            </Form>
                                            {/* <hr />
                                            <h5 className="text-muted">Accessbility</h5>
                                            <Form>
                                                <div className="accessbility mb-3">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="one"
                                                        label="Request a facilitator"
                                                        checked
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="two"
                                                        label="Get notifications"
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="three"
                                                        label="Use chat feature"
                                                    />
                                                    <Button variant="primary" type="submit" disabled className="mt-3">
                                                        Save Changes
                                                    </Button>
                                                </div>
                                            </Form> */}
                                            {/* <hr />
                                            <h5 className="text-muted">Delete Accounts</h5>
                                            <Button variant="danger" type="submit" className="mt-3" style={{ width: 200, }}>
                                                Delete Your Account
                                            </Button> */}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="profile" title="Privacy Settings">
                                        <div className="account-setting">
                                            <Form>
                                                <h5 className="text-muted">Change Password</h5>
                                                {/* <Form.Group className="mb-3">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="text" value="Jane Doe" />
                          </Form.Group> */}
                                                <Form.Group className="mb-3">
                                                    <Form.Label>New Password</Form.Label>
                                                    <Form.Control type="password" value={password?.password} onChange={(e) => setPassword({ ...password, password: e.target.value })} />
                                                    <FieldErrors errors={error} field='password' />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <Form.Control type="password" value={password?.confirm_password} onChange={(e) => setPassword({ ...password, confirm_password: e.target.value })} />
                                                    <FieldErrors errors={error} field='confirm_password' />
                                                </Form.Group>
                                                <Button variant="primary" onClick={changePassword}>
                                                    Save Changes
                                                </Button>
                                            </Form>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="right">
                    <div className="user-short-text">
                        {/* <div className="notification-bell">
                            <img src="images/bell-icon.png" alt="" width="32" />
                            <div className="noti-dot"></div>
                        </div> */}
                        <NotificationUI/>
                        <div className="user-short-profile">

                            <div className="user-avatar">
                                {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="default_logos/school-logo.png" width={'100%'} height={'100%'} alt="" />}
                            </div>
                            <div className="user-short-name">
                                <h5>{user?.name}  </h5>
                                <p>Status: {user?.status}</p>
                            </div>
                            {/* <Button variant="outline-secondary" onClick={logout}>Logout</Button> */}
                            <Logout url={'/organization'} />
                            {/* <Dropdown >
                                <Dropdown.Toggle drop='none' variant="">

                                </Dropdown.Toggle >
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={logout}
                                    // href="#/action"
                                    >Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
                            {/* <div className="drop-down">
                                <img src="images/drop-icon.png" alt="" width="10" />
                            </div> */}
                        </div>
                    </div>
                    {/* <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src="images/avatar3.png" alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">View Profile</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#">Edit Account</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#">Delete Account</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>Jane Doe</h5>
                  <h6>Student ID: 567872</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="#" className="btn btn-primary solid btn-sm no-shadow d-block">Activate Account</a>
                </div>
                <div className="about-teacher">
                  <h4>About:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p>
                  <h4>Email:</h4>
                  <p>jane@mather.edu</p>
                  <h4>Parent’s Email:</h4>
                  <p>sarahdoe@gmail.com</p>
                  <h4>Facilitator Email:</h4>
                  <p>john@mather.edu</p>
                  <h4>Subjects Interested:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li>
                    </ul>
                  </div>
                </div>
                <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow same-width" variant="primary">Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill same-width" variant="secondary">Decline</Button>
                  </Col>
                </Row>
              </div>
            </div> */}
                </div>
            </div>
        </section>
    </>
}