import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import SessionCalendar from './SessionCalendar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../config';
import _ from 'lodash';
import { FecthData } from '../../Services/FetchData';
import { timeFormate } from '../../helpers/utils';
import Countdown from 'react-countdown';
import MyScheduleUI from '../common/MyScheduleUI';
import { cancelSession, getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';

function MySchedule() {
  const user = JSON.parse(localStorage.getItem('user'))
  const history = useHistory()
  const [redirect, setRedirect] = useState()
  const selectedDay = val => {
    console.log(val);
  };
  const logout = (url) => {
    localStorage.clear()
    history.push(url)
  }
  const [sessions, setSessions] = useState([])
  const getStudentSessions = () => {
    FecthData.postJsonData({ student_id: user?.student?._id,upcoming:true }, 'student/get_student_session_by_student_id').then(
      (data) => {
        const tutorSession=[]
         _.map(data?.data, session => {
          if (session?.tutor_session_id) {
           tutorSession.push(session?.tutor_session_id)
          }
        })
        setSessions(tutorSession)
      }
    )
  }
  const cancelTutorSession=(session_id,cancelled_by)=>{
    cancelSession(session_id,cancelled_by).then(()=>{
      getStudentSessions()
    })
  }
  useEffect(() => {
    getStudentSessions()
  }, [])
  const studentSessions = _.filter(sessions, session => {
    if (session?.tutor_session_id) {
      return session
    }
  })
  const tutor_sessions = _.map(sessions, session => {
    return session.tutor_session_id
  })
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
              <MyScheduleUI sessions={sessions} role='student' cancelTutorSession={cancelTutorSession}/>
              {/* <Col md={12}>
                <div className="tutor-list mt-3">
                  {sessions?.map(session => <><div className="class-date-time">
                    <ul>
                      <li>
                        {timeFormate(session?.tutor_session_id?.start_time)}- {timeFormate(session?.tutor_session_id?.end_time)}
                      </li>
                      <li>
                        {new Date(session?.tutor_session_id?.date).toLocaleDateString('en-us', { weekday: "long", month: "short", day: "numeric" })}
                        {/* Monday, Aug 06 *
                      </li>
                    </ul>
                  </div>
                    <div className={`tutor-item ${new Date(session?.date).toLocaleDateString() == new Date().toLocaleDateString() ? 'active' : ''}`}>
                      <div className="tutor-wrap">
                        <div className="tutor-avatar">
                          <img src={session?.tutor_session_id?.tutor_id?.user_id?.propic ? session?.tutor_session_id?.tutor_id?.user_id?.propic : "images/avatar-male.jpg"} alt="" />
                        </div>
                        <div className="tutor-subject">
                          <h6>{session?.tutor_session_id?.tutor_id?.first_name} {session?.tutor_session_id?.tutor_id?.last_name}</h6>
                          <h5>Subject: {session?.tutor_session_id?.subject?.title}
                            {/* Algebra Chapter 1: Expressions & Equations 
                          </h5>
                          {/* <p>Facilitator: Jane Doe | Session: Virtual</p> */}
              {/* <Link to="/join-class" className="btn btn-success px-5 mt-3">Join Class Now</Link> 
                          <Button variant="light" className="px-5 mt-3"> Time Left <Countdown
                            date={session?.tutor_session_id?.start_time}
                            daysInHours="true"
                          /></Button>
                        </div>
                      </div>
                      <div className="tutor-icon">
                        <img src={'images/' + session?.tutor_session_id?.subject?.title?.toLowerCase() + '.png'} alt="" />
                      </div>
                    </div></>)}
                  {/* <div className="class-date-time">
                    <ul>
                      <li>
                        10:00 AM-10:30 AM
                      </li>
                      <li>
                        Monday, Aug 06
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item active">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar-male.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>John D.</h6>
                        <h5>Algebra Chapter 1: Expressions & Equations</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Link to="/join-class" className="btn btn-success px-5 mt-3">Join Class Now</Link>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/algebra.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        12:00 PM-1:00PM
                      </li>
                      <li>
                        Monday, Aug 06
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <h5>Chemistry Chapter 3: Symbols Of Elements</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 2:55:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/geometry.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        05:00 PM-06:00 PM
                      </li>
                      <li>
                        Monday, Aug 06
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar-male.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>David T.</h6>
                        <h5>Geography Chapter 4: Cultural Geography</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 5:30:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/calcuus.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        10:00 AM-10:30 AM
                      </li>
                      <li>
                        Tuesday, Aug 07
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <h5>Chemistry Chapter 3: Symbols Of Elements</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 14:55:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/geometry.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        12:00 PM-1:00PM
                      </li>
                      <li>
                        Tuesday, Aug 07
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar-male.jpg" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>David T.</h6>
                        <h5>Geography Chapter 4: Cultural Geography</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 24:30:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/calcuus.png" alt="" />
                    </div>
                  </div> *
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
              <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.student?.gender_avatar?.icon_image && <img src={getResourceUrl(user?.student?.gender_avatar?.icon_image)} width={'100%'} height={'100%'} alt="" />}{!user?.student?.gender_avatar?.icon_image && <img src="images/avatar2.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.student_profile?.school_name}</h5>
                  <p><small>ID: {user?.student?.student_code}</small></p>
                  <p>Academic Year: {user?.student?.academic_year}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => logout('/')}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <SessionCalendar />
          </div>
        </div>
      </section>

    </>
  )
}

export default MySchedule
