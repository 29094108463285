import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import TutorSideMenu from "./TutorSideMenu";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { auto } from '@popperjs/core';
import _ from 'lodash';
import { FecthData } from '../../Services/FetchData';
import TutorSessionCalendar from './TutorSessionCalendar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { formatDate, getWeekOfMonth } from '../../helpers/utils';
import NotificationUI from '../common/NotificationUI';
import { getResourceUrl } from '../../Services/CommanFunctions';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Filler,
// );

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: '',
    },
  },
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true
      }
    },
    y: {
      display: true,
      title: {
        display: true,
        text: 'Value'
      },
      // suggestedMin: -1,
      // suggestedMax: 20
    }
  }
};

// const labels = ['WEEK 3', 'WEEK 4', 'WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4'];

// const graphData = {
//   labels,
//   datasets: [
//     {
//       label: 'Lessons',
//       data: ['0', '0', '0', '0', '0', '0', '0'],
//       borderColor: 'rgba(51, 64, 212, 0.5)',
//       backgroundColor: 'rgba(51, 64, 212, 0.5)',
//       fill: {
//         target: 'origin',
//         above: 'rgba(43, 99, 255, 0.4)',   // Area will be red above the origin
//         below: 'rgba(51, 64, 212, 0.4)'    // And blue below the origin
//       }
//     },
//     {
//       label: 'Hours',
//       data: ['0', '0', '0', '0', '0', '0', '0'],
//       borderColor: 'rgba(132, 176, 60, 0.4)',
//       backgroundColor: 'rgba(132, 176, 60, 0.4)',
//       fill: {
//         target: 'origin',
//         above: 'rgba(132, 176, 60, 0.4)',   // Area will be red above the origin
//         below: 'rgba(132, 176, 60, 0.4)'    // And blue below the origin
//       }
//     },
//   ],
// };

// ChartJS.register(Tooltip, Legend);

function TutorStatistics() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [sessions, setSessions] = useState([])
  const [statistics, setStatistics] = useState()
  const [interval, setInterval] = useState('weekly')
  const [labels, setLabels] = useState(['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'])
  const [lassionArr, setLessionArr] = useState(['0', '0', '0', '0', '0', '0', '0'])
  const [hoursArr, setHoursArr] = useState(['0', '0', '0', '0', '0', '0', '0'])
  const [selectedDate, selectDate] = useState(formatDate(new Date()))
  const [dateWiseSessions, setDateWiseSessions] = useState([])
  const history = useHistory()

  const selectedDay = val => {
    const date = new Date(val)
    selectDate(formatDate(date))
  };

  const getTutorSession = () => {
    FecthData.getData('tutor/get_tutor_session/' + user?.tutor?._id+'?date='+selectedDate+'&upcoming='+true).then(
      (data) => {
        setSessions(data?.data)
      }
    )
  }
  const getTutorSessionDateWise = () => {
    FecthData.getData('tutor/get_tutor_session/' + user?.tutor?._id+'?date='+selectedDate).then(
      (data) => {
      setDateWiseSessions(data?.data)
      }
    )
  }
  const getStatisticsCounts = () => {
    FecthData.getData('tutor/get_tutor_statistics_count/' + user?.tutor?._id).then(
      (data) => setStatistics(data?.data)
    )
  }
  function getWeek(date) {
    let monthStart = new Date(date);
    monthStart.setDate(0);
    let offset = (monthStart.getDay() + 1) % 7 - 1; // -1 is for a week starting on Monday
    return Math.ceil((new Date(date).getDate() + offset) / 7);
  }
  const getStatistics = () => {
    FecthData.getData('tutor/get_tutor_statistics/' + user?.tutor?._id + '?interval=' + interval).then(
      (data) => {
        var labels1 = interval == 'weekly' ? ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] : ['WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4', 'WEEK 5'];
        const sessionOfWeek = ['0', '0', '0', '0', '0', '0', '0']
        const hoursOfWeek = ['0', '0', '0', '0', '0', '0', '0']
        const sessionOfMonth = ['0', '0', '0', '0', '0',]
        const hoursOfMonth = ['0', '0', '0', '0', '0',]
        data?.data?.map(item => {
          if (interval == 'weekly') {
            sessionOfWeek[item?._id?.dayOfWeek - 1] = item.sessions.toString()
            hoursOfWeek[item?._id?.dayOfWeek - 1] = (item.total_hours / 60).toFixed(2).toString()
          } else {
            sessionOfMonth[getWeekOfMonth(item?.date)] = item.sessions.toString()
            hoursOfMonth[getWeekOfMonth(item?.date)] = (item.total_hours / 60).toFixed(2).toString()
          }
        })
        var lessionDataArr = []
        var hoursDataArr = []
        if (interval == 'weekly') {
          lessionDataArr = sessionOfWeek
          hoursDataArr = hoursOfWeek
        } else {
          lessionDataArr = sessionOfMonth
          hoursDataArr = hoursOfMonth
        }
        setLabels(labels1)
        setLessionArr(lessionDataArr)
        setHoursArr(hoursDataArr)
      }
    )
  }
  useEffect(() => {
    getStatisticsCounts()
  }, [])
  useEffect(() => {
    getStatistics()
  }, [interval])
  const getDateWiseSession = () => {
    if (selectedDate) {
      const tutorSessions = _.filter(sessions, session => {
        if (session.date == new Date(selectedDate).toISOString()) {
          return session
        }
      })
      setDateWiseSessions(tutorSessions)
    }
  }
  useEffect(() => {
    console.log(selectedDate)
    getTutorSessionDateWise()
  }, [selectedDate])
  const logout = () => {
    localStorage.clear()
    history.push('/tutor')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <TutorSideMenu />
          <div className="middle">
            <div className="card">
              <Row>
                <Col md={4}>
                  <div className="card bg-purple progress-box">
                    <div className="card-text text-white">
                      <img src="images/book-icon.png" alt="" width="58" />
                      <h4>Scheduled Classes</h4>
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">{statistics?.tutor_session_count}</h5>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-danger progress-box">
                    <div className="card-text text-white">
                      <img src="images/hat-icon.png" alt="" width="70" />
                      <h4>Number Of Students</h4>
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">{statistics?.tutor_student_count}</h5>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="card bg-success progress-box">
                    <div className="card-text text-white">
                      <img src="images/timer-icon.png" alt="" width="34" />
                      <h4>Time Spent On Dasya</h4>
                    </div>
                    <div className="card-big-text">
                      <h5 className="count">{statistics?.tutor_time_spent / 60 > 1 ? <>{(statistics?.tutor_time_spent / 60).toFixed()} <small>HRS</small></> :
                        <>{statistics?.tutor_time_spent} <small>MIN</small></>} </h5>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <h5 className="sub-heading mt-4 text-muted d-flex justify-content-between align-items-center">Statistics
                    <Form.Group style={{ minWidth: 120 }}>
                      <Form.Select defaultValue='weekly'
                        onChange={e => setInterval(e.target.value)}
                      >
                        <option value='weekly'>Weekly</option>
                        <option value='monthly'>Monthly</option>
                      </Form.Select>
                    </Form.Group>
                  </h5>
                  <Line options={options} data={{
                    labels: labels, datasets: [
                      {
                        label: 'Lessons',
                        data: lassionArr,
                        borderColor: 'rgba(51, 64, 212, 0.5)',
                        backgroundColor: 'rgba(51, 64, 212, 0.5)',
                        fill: {
                          target: 'origin',
                          above: 'rgba(43, 99, 255, 0.4)',   // Area will be red above the origin
                          below: 'rgba(51, 64, 212, 0.4)'    // And blue below the origin
                        }
                      },
                      {
                        label: 'Hours',
                        data: hoursArr,
                        borderColor: 'rgba(132, 176, 60, 0.4)',
                        backgroundColor: 'rgba(132, 176, 60, 0.4)',
                        fill: {
                          target: 'origin',
                          above: 'rgba(132, 176, 60, 0.4)',   // Area will be red above the origin
                          below: 'rgba(132, 176, 60, 0.4)'    // And blue below the origin
                        }
                      },
                    ]
                  }} />
                </Col>
              </Row>
            </div>
            <div className="card">
              <Row>
                <Col md={12} className="mb-4">
                  <h6 className="text-muted mt-3">Student’s Ratings</h6>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>ID: 567876</h4>
                      <h5>8th Grade</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>ID: 567876</h4>
                      <h5>8th Grade</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="student-rating-box">
                    <div className="rating-avatar">
                      <img src="images/avatar2.png" alt="" />
                    </div>
                    <div className="rating-description">
                      <h4>ID: 567876</h4>
                      <h5>8th Grade</h5>
                      <div className="star-count">
                        <img src="images/rating-4.png" alt="" width="184" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src={user?.propic?getResourceUrl(user?.propic):"images/no_profile_img.png"} width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.tutor?.org_type == 'school' ? user?.tutor_profile?.school_name : user?.tutor_profile?.company_name}</h5>
                  <p><small>ID: {user?.tutor?.tutor_code}</small></p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <TutorSessionCalendar dateWiseSessions={dateWiseSessions} selectedDay={selectedDay} />
          </div>
        </div>
      </section>

    </>
  )
}

export default TutorStatistics
