import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      {/* <h1>Footer</h1> */}
    </>
  )
}

export default Footer
