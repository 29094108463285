import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import { TextField } from '@material-ui/core';
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link, useHistory } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import { Student } from '../../Services/Student';
import TimePicker from 'react-time-picker';
import ActionButton from '../ActionButton';
import _ from 'lodash';
import { FecthData } from '../../Services/FetchData';
import config from '../../config';
import CalendarUI from '../CalenderUI';
import { formatDate, timeFormate } from '../../helpers/utils';
import SessionForm from '../Session/SessionForm';
import { getResourceUrl } from '../../Services/CommanFunctions';
import { staticData } from '../../helpers/data';

function SignupStepSix({ nextStep, handleFormData, prevStep, values }) {
  const [show, setShow] = useState(false);
  const [skip, setSkip] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [startTime, setStartTime] = useState([new Date()]);
  const [selectedSession, selectSession] = useState();
  let history = useHistory();
  const [datez, setDatez] = useState([new Date()]);
  const [endTime, setEndTime] = useState([new Date()]);
  const [error, setError] = useState('');
  const [error1, setError1] = useState([]);
  const [scheduleDay, setScheduleDay] = useState([['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date().getDay()]]);
  const [scheduleDate, setScheduleDate] = useState([[new Date()].toLocaleString('default', { month: 'short', day: "2-digit" })]);
  const [validated, setValidated] = useState(false);
  const [studentUser, setStudentUser] = useState([]);
  const [subject, setSubject] = useState([]);
  const [inputSlot, setInputSlot] = useState([
    { date: '', start_time: '10:00', end_time: '11:00', subject: values.subjects[0], type_of_tutoring_session: '', current_day_sessions: [], upcoming_days_sessions: [], tutor_session_id: '' }
  ])
  // const formatDate = (date) => {
  //   var d = new Date(date),
  //     month = '' + (d.getMonth() + 1),
  //     day = '' + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2)
  //     month = '0' + month;
  //   if (day.length < 2)
  //     day = '0' + day;

  //   return [month, day, year].join('/');
  // }
  const formatDate1 = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }
  const selectedDay = (index, val) => {
    var date = new Date(val)
    let data = [...inputSlot];
    data[index].date = val
    setInputSlot(data);

    let scheduleDays = [...scheduleDay];
    scheduleDays[index] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][val.getDay()];
    setScheduleDay(scheduleDays);

    let scheduleDates = [...scheduleDate];
    scheduleDates[index] = [val].toLocaleString('default', { month: 'short', day: "2-digit" });
    setScheduleDate(scheduleDates);

    let datezz = [...datez];
    datezz[index] = formatDate(val);
    setDatez(datezz);
    handleChangeSetData(index, 'date', formatDate(val))
    if (inputSlot[index].subject != '' && inputSlot[index].type_of_tutoring_session != '' && inputSlot[index].date != '' && inputSlot[index]?.start_time != '' && inputSlot[index]?.end_time != '') {
      getTutorSession(index, inputSlot[index].subject, studentUser?.grade_id, inputSlot[index].type_of_tutoring_session, inputSlot[index].date, inputSlot[index]?.start_time, inputSlot[index]?.end_time)
    }

  };


  const removeFields = (index) => {
    let data = [...inputSlot];
    data.splice(index, 1)
    setInputSlot(data)
  }
  const addFormFields = () => {
    let object = { date: '', start_time: '', end_time: '', subject: values.subjects[0], type_of_tutoring_session: '', current_day_sessions: [], upcoming_days_sessions: [], tutor_session_id: '' }
    setInputSlot([...inputSlot, object])
    setScheduleDay([...scheduleDay, ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date().getDay()]])
    setScheduleDate([...scheduleDate, [new Date()].toLocaleString('default', { month: 'short', day: "2-digit" })])
    setStartTime([...startTime, new Date()])
    setEndTime([...endTime, new Date()]);
    setDatez([...datez, new Date()])
    return true;
  }

  const handleChange = (index, event) => {
    // setValue(event.target.name,event.target.value) 
    let data = [...inputSlot];
    data[index][event.target.name] = event.target.value;
    setInputSlot(data);
    if (inputSlot[index].subject != '' && inputSlot[index].type_of_tutoring_session != '' && inputSlot[index].date != '' && inputSlot[index]?.start_time != '' && inputSlot[index]?.end_time != '') {
      getTutorSession(index, inputSlot[index].subject, studentUser?.grade_id, inputSlot[index].type_of_tutoring_session, inputSlot[index].date, inputSlot[index]?.start_time, inputSlot[index]?.end_time)
    }
  }
  const changeRadio = (id) => {
    // console.log(event)
    selectSession(id)
    // handleChange(event, id)
  }
  const handleChangeSetData = (index, name, value) => {
    let data = [...inputSlot];
    data[index][name] = value;
    setInputSlot(data);

  }
  const addStartTime = (index, e) => {
    let data = [...startTime];
    data[index]['start_time'] = e;
    setStartTime(data);
    handleChangeSetData(index, 'start_time', e)
  }
  const addEndTime = (index, e) => {
    let data = [...endTime];
    data[index]['end_time'] = e;
    setEndTime(data);
    handleChangeSetData(index, 'end_time', e)
  }
  const skipNow = (e) => {
    setSkip(true)
  }
  const redirectToHome = () => {
    if (!skip)
      handleShow()
    setTimeout(() => history.push('/'), 3000)
  }
  const submitFormData = (e) => {
    e.preventDefault();
    if (!skip && validateForm()) {
      addSession()
    }
  }

  const addSession = () => {
    School.post({ student_id: values.student_id, slot: inputSlot, skip: skip, signup: true }, 'student/student_add_session').then(
      (data) => {
        if (data.error === 0) {
          NotificationManager.success(data.message, 'success!', 2000);
          //setMessage(data.message)
          // nextStep()
          //  localStorage.setItem('student_subject', JSON.stringify(data.data))
          redirectToHome()
        }


      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
  }

  const validateForm = () => {

    let fields = inputSlot;
    let errors1 = [{}];
    let data = [...error1];
    let formIsValid = true;
    fields.map((field, index) => {
      let errors = [];
      if (!field["date"]) {
        formIsValid = false;
        errors["date"] = "*Please select date.";
      }
      // if (!field["recurring_option"]) {
      //   formIsValid = false;
      //   errors["recurring_option"] = "*Please select Recurring Option.";
      // }
      if (!field["subject"]) {
        formIsValid = false;
        errors["subject"] = "*Please select subject.";
      }
      // if (!field["session"]) {
      //   formIsValid = false;
      //   errors["session"] = "*Please select session.";
      // }
      // if (!field["start_time"]) {
      //   formIsValid = false;
      //   errors["start_time"] = "*Please select start time.";
      // }
      // if (!field["end_time"]) {
      //   formIsValid = false;
      //   errors["end_time"] = "*Please select end time.";
      // }
      if (!field["type_of_tutoring_session"]) {
        formIsValid = false;
        errors["type_of_tutoring_session"] = "*Please select tutoring session.";
      }
      data[index] = errors;
      setError1(data)
    })
    return formIsValid;


  }
  const getStudentSubject = () => {
    FecthData.getData('student/get_student_selected_subject/'+values.student_id).then(
      (data) => {
        if (data.error === 0) {
          setSubject(data.data)
        }

      },
      (error) => {
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error.toString())
      }
    )
  }
  const getTutorSession = (index, subject, grade_id, type_of_tutoring_session, date, start_time, end_time) => {
    const reqData = {
      student_id:studentUser?._id,
      subject: subject,
      grade_id: grade_id,
      type_of_tutoring_session: type_of_tutoring_session,
      date: date,
      start_time: start_time,
      end_time: end_time
    }
    FecthData.postJsonData(reqData, 'tutor/get_tutor_sessions_by_student_filter').then(
      (data) => {
        const data1 = [...inputSlot]
        data1[index].current_day_sessions = data?.data?.current_day_sessions
        data1[index].upcoming_days_sessions = data?.data?.upcoming_days_sessions
        setInputSlot(data1)
        setShow1(data?.data?.current_day_sessions.length == 0)
      }
    )
  }
  useEffect(() => {
    const studentUserz = localStorage.getItem("student");
    if (values.student_id) {
      const foundUser = JSON.parse(studentUserz);
      setStudentUser(foundUser);
      getStudentSubject()
    }
  }, []);
  useEffect(() => {
    if (inputSlot[0]) {

    }
  }, [inputSlot])
  useEffect(() => {
    if (skip)
      addSession()
  }, [skip])
  const getFieldValues = () => {
    let newArr = _.values(inputSlot, o => _.pick(o, ['date', 'subject', 'type_of_tutoring_session', 'tutor_session_id']));
    return newArr
  }
  let newArr = _.map(inputSlot, o => _.pick(o, ['date', 'subject', 'type_of_tutoring_session', 'tutor_session_id']));
  var data_1 = _.values(inputSlot, _.pick('date', 'subject'));
  const vals = inputSlot[0]
  const getDateOfSession = (date) => {
    var d = new Date(date)
    date = [
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2),
      d.getFullYear()
    ].join('-');
    return date
  }
  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '100%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Profile Completion</h6>
                    <h6>100% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">
                  <div className="signup-left">
                    <div className="user-are">
                      <div className="user-avatar">
                        {studentUser && studentUser?.gender_avatar && <img src={studentUser?.gender_avatar?.icon_image ? getResourceUrl(studentUser?.gender_avatar?.icon_image) : "images/avatar4.png"} alt="" />}
                        {/* {studentUser && studentUser?.gender_avatar == 'female' && <img src="images/avatar2.png" alt="" />} */}
                      </div>
                      <div className="user-text">
                        <h4>{studentUser?.first_name + ' ' + studentUser?.last_name} | ID: {studentUser?.student_code}</h4>
                        <h6>Academic Year  {studentUser?.academic_year}</h6>
                      </div>
                    </div>
                    <h4>{staticData?.verbiage?.student[6]?.heading} </h4>
                    <p>{staticData?.verbiage?.student[6]?.text}</p>
                    <div className="signup-step" style={{ marginTop: 202, }}>
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li className="active"></li>
                      </ul>
                    </div>
                  </div>
                  <Form onSubmit={submitFormData} className="signup-right">
                    <div className="available-date mt-3">
                      <SessionForm
                        selectedDay={selectedDay}
                        mode={'signup'}
                        subject={subject}
                        handleChange={handleChange}
                        inputSlot={inputSlot}
                        submitFormData={submitFormData}
                        removeFields={removeFields}
                      />
                      {/* {inputSlot.map((element, index) => (
                        <Row key={`slot-${index}`}>
                          <Col md={12} className="mb-3 text-end">
                            {
                              index ?
                                <a className="btn btn-dark mb-3" onClick={() => removeFields(index)}><i className="fa fa-close"></i></a>
                                : null
                            }
                          </Col>
                          <Col md={12} className="mb-3">

                            <CalendarUI onChange={(value, event) => selectedDay(index, value)} className='' />

                            {/* <DatePicker getSelectedDay={e => selectedDay(index, e)}
                              endDate={100}
                              selectDate={new Date()}
                              name={'date'}
                              labelFormat={"MMMM"}
                              color={"#287AEB"}
                              labelColor={'red'}
                              marked={[
                                {
                                  date: new Date(2021, 9, 3),
                                  marked: true,
                                  style: {
                                    color: "#ff0000",
                                    padding: "2px",
                                    fontSize: 12,
                                  },
                                  text: "1x",
                                },
                                {
                                  date: new Date(2021, 9, 4),
                                  marked: true,
                                  text: "5x"
                                },
                              ]}
                            /> 
                            <span className={`err date${index}`}>{error1?.[index]?.date}</span>
                          </Col>
                          {/* {element?.date && <Col md={12} className="mb-3 text-center">Date: {element?.date}</Col>} 
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Select name="subject" onChange={e => handleChange(index, e)} defaultValue={element.subject}>
                                
                                {subject && subject?.map((content, index) => <option key={index} value={content._id}>{content.title}</option>
                                )}
                              </Form.Select>
                              <span className={`err subject${index}`}>{error1?.[index]?.subject}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Select name="type_of_tutoring_session" onChange={e => handleChange(index, e)}>
                                <option value="">Type of tutoring session</option>
                                <option value="daily session">Daily session</option>
                                <option value="weekly session">Weekly session</option>
                                <option value="monthly session">Monthly session</option>
                              </Form.Select>
                              <span className={`err type_of_tutoring_session${index}`}>{error1?.[index]?.type_of_tutoring_session}</span>
                            </Form.Group>
                          </Col>
                          {/* <Col md={6}>
                            <Form.Group className="mb-3">
                              <TimePicker label="Start Time" name="start_time" onChange={e => addStartTime(index, e)} value={startTime[index]} renderInput={(params) => <TextField {...params} />} />
                              <span className={`err start_time${index}`}>{error1?.[index]?.start_time}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <TimePicker name="end_time" label="End Time" onChange={e => addEndTime(index, e)} value={endTime[index]} renderInput={(params) => <TextField {...params} />} />
                              <span className={`err end_time${index}`}>{error1?.[index]?.end_time}</span>
                            </Form.Group>
                          </Col> 
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Select name="tutor_session_id" onChange={e => handleChange(index, e)}>
                                <option value="">Select session Type</option>
                                <option value="live">Live</option>
                                <option value="on-demand">On Demand</option>
                                {/* <option value="4">Session 4</option>
                                <option value="5">Session 5</option>
                                <option value="6">Session 6</option> 
                              </Form.Select>
                              <span className={`err session${index}`}>{error1?.[index]?.session}</span>
                            </Form.Group>
                          </Col>
                          {/* <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Select name="tutor_session_id" onChange={e => handleChange(index, e)}>
                                <option value="">Select session*</option>
                                {element.tutor_sessions.map(session => <option value={session._id}>{session.type_of_tutoring_session}</option>)}
                              </Form.Select>
                              <span className={`err session${index}`}>{error1?.[index]?.session}</span>
                            </Form.Group>
                          </Col> 
                          {element.current_day_sessions.length > 0 ? <Col md={12}>
                            <p className='m-1'>Current day sessions</p>
                            <ul className='mb-3'>

                              {element.current_day_sessions.map(session => <li className='m-1' key={session._id} style={{ fontSize: '16px' }}>
                                <input type="radio" name="tutor_session_id" value={session._id} onChange={(e) => handleChange(index, e)} checked={(element.tutor_session_id == session._id)} />
                                {" " + timeFormate(session.start_time)} - {timeFormate(session.end_time) + " | " + new Date(session?.date).toDateString() + " | Session with " + session?.tutor_id?.first_name + " " + session?.tutor_id?.last_name}
                              </li>)}

                            </ul>
                          </Col> : null}
                          {/* {element.upcoming_days_sessions.length > 0 ? <Col md={12}>
                            <p className='m-1'>Upcoming days sessions</p>
                            <ul className='mb-3'>

                              {element.upcoming_days_sessions.map(session => <li className='m-1' key={session._id} style={{ fontSize: '16px' }}>
                                <input type="radio" name="tutor_session_id" value={session._id} onChange={(e) => handleChange(index, e)} checked={(element.tutor_session_id == session._id)} />
                                {" " + session?.tutor_id?.first_name + " " + session?.tutor_id?.last_name}, {getDateOfSession(session?.date)} at {timeFormate(session.start_time)} - {timeFormate(session.end_time)}
                              </li>)}

                            </ul>
                          </Col> : null} */}
                      {/* {element.tutor_sessions.map(session => <Col > <option value={session._id}>{session.type_of_tutoring_session}</option> </Col>)} */}

                      {/* <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                              <Form.Check className="text-grey" type="radio" name={`recurring_option${index}`} id="flexRadioDefault1" onChange={e => handleChangeSetData(index, 'recurring_option', scheduleDay[index])} label={`Apply the schedule to all ${scheduleDay[index]}`} defaultValue={scheduleDay[index]} />
                              <span className={`err recurring_option${index}`}>{error1?.[index]?.recurring_option}</span>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                              <Form.Check className="text-grey" type="radio" name={`recurring_option${index}`} id="flexRadioDefault2" onChange={e => handleChangeSetData(index, 'recurring_option', scheduleDate[index])} label={`Apply the schedule to ${scheduleDate[index]}`} defaultValue={scheduleDate[index]} />
                            </Form.Group>
                          </Col> 

                        </Row>
                      ))} */}
                      <Row>
                        <Col md={12} className="text-center">
                          <Button variant="outline-secondary" disabled={inputSlot.length >= 3} onClick={() => addFormFields()}><i className="fa fa-calendar" aria-hidden="true" ></i> Schedule another session</Button>
                        </Col>
                      </Row>

                    </div>
                    <div className="signup-back-btn">
                      <Link onClick={prevStep} to="#" className="btn btn-link">Back</Link>
                      <div>
                        <Button className="btn btn-secondary btn-pill px-4" style={{ marginInline: '1rem' }} onClick={skipNow}>Skip for now</Button>
                        <ActionButton type={'submit'} className={'btn btn-primary btn-pill px-4'} title={'Continue'}
                          requiredFields={[inputSlot[0]?.date, inputSlot[0]?.subject, inputSlot[0]?.type_of_tutoring_session, inputSlot[0]?.tutor_session_id
                            , inputSlot[1]?.date, inputSlot[1]?.subject, inputSlot[1]?.type_of_tutoring_session, inputSlot[1]?.tutor_session_id
                            , inputSlot[2]?.date, inputSlot[2]?.subject, inputSlot[2]?.type_of_tutoring_session, inputSlot[2]?.tutor_session_id]} ></ActionButton>
                      </div>
                      {/* <button type="submit" className="btn btn-primary btn-pill px-4" onClick={e => getStudentSubject()}>Continue</button> */}
                    </div>
                  </Form>

                </div>
              </Col>
            </Row>
          </Container>
        </div >
      </section >
      <Modal show={show1} onHide={handleClose1} onShow={() => setTimeout(handleClose, 3000)}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select another date or time</Modal.Title>
        </Modal.Header>
        <Modal.Body className='align-item-center'>
          <Form onSubmit={(e) => {
            submitFormData(e)
            handleClose1()
          }}>
            <div className='alert alert-success text-center'>Unfortunately we don't have a class available at your preferred date and time. Choose another date and time or try again in a couple of days to schedule a class with one of our tutors.</div>
            {inputSlot.map((element, index) => <>{element.upcoming_days_sessions.length > 0 ? <Col md={12}>
              <p className='m-1'>Next available class: </p>
              <ul className='mb-3'>

                {element.upcoming_days_sessions.map(session => <li className='m-1' key={session._id} style={{ fontSize: '16px' }}>
                  <input type="radio" name="tutor_session_id" value={session._id} onChange={(e) => handleChange(index, e)} checked={(element.tutor_session_id == session._id)} />
                  {" " + timeFormate(session.start_time)} - {timeFormate(session.end_time) + " | " + new Date(session?.date).toDateString() + " | Session with " + session?.tutor_id?.first_name + " " + session?.tutor_id?.last_name}
                </li>)}

              </ul>
            </Col> : null}</>)}
            <div className="text-center">
              <Button variant="primary" type='submit' className="px-5">
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose} onShow={() => setTimeout(handleClose, 3000)}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add session</Modal.Title>
        </Modal.Header>
        <Modal.Body className='align-item-center'>
          <div className='alert alert-success text-center'>Your session added successfully! please login now</div>
          <div className="text-center">
            <Button variant="primary" onClick={handleClose} className="px-5">
              Okay
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  )
}

export default SignupStepSix
