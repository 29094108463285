import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Stack,
  Modal
} from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { NavLink, Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete';
import config from "./../../config";
import { Student } from '../../Services/Student';
import TextField from "@material-ui/core/TextField";
import validator from "validator";
import ActionButton from '../ActionButton';
import { FecthData } from '../../Services/FetchData';
import FieldErrors from '../../Services/FieldErrors';
import { getResourceUrl } from '../../Services/CommanFunctions';
import { staticData } from '../../helpers/data';

function SignupStepOne({ nextStep, handleImage, handleFormData, handleSchool, values }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState(values.school_name);
  const [validated, setValidated] = useState(false);
  const [school, setSchool] = useState('');
  const [error, setError] = useState();
  const [grade, setGrade] = useState();
  const [selectGrade, setSelectGrade] = useState(values.grade_id);
  const [error1, setError1] = useState();
  const [error2, setError2] = useState();
  const [schReqData, setSchReqData] = useState({
    school_name: '',
    school_email: '',
    school_zip: ''
  })
  const currentYear = new Date().getFullYear();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (schReqData.school_name && schReqData.school_email && schReqData.school_zip)
      handleClose()
    FecthData.postJsonData(schReqData, 'users/school_add_request').then(
      (data) => {
        if (data?.data)
          handleClose()
        else
          setError(data.errors.details)
      }
    )
    setValidated(true);
  };
  const changeRadio = (event, id) => {
    // console.log(event)
    setSelectGrade(event)
    handleSchool('grade_id', id)
  }

  const submitFormData = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      nextStep();
    }
  };

  const validateForm = () => {

    let fields = values;
    let errors = {};
    let formIsValid = true;

    if (!fields["school_name"]) {
      formIsValid = false;
      errors["school_name"] = "*Please select Your school.";
    }

    if (!fields["grade_id"]) {
      formIsValid = false;
      errors["grade_id"] = "*Please select your grade.";
    }
    if (!fields["academic_year"]) {
      formIsValid = false;
      errors["academic_year"] = "*Please select your academic year.";
    }
    setError2(errors)
    return formIsValid;


  }

  useEffect(() => {
    Student.getDta({ page: 0 }, 'grades/get_all_grade').then(
      (data) => setGrade(data),
      (error) => setError1(error.toString())
    );
    Student.getSchool({ is_registered: false }).then(
      (data) => setSchool(data),
      (error) => setError(error.toString())
    );


  }, [])

  return (
    <>
      <Header />
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="login-body" style={{ backgroundImage: 'url("images/signup-bg.jpg")', height: '100%', width: 'auto', backgroundPosition: 'center' }}>
          <Container>
            <Row>
              <Col md={12}>
                <div className="step-progress">
                  <div className="step-progress-bar">
                    <div className="step-progress-percent" style={{ width: '25%' }}></div>
                  </div>
                  <div className="step-progress-text">
                    <h6>Account Creation</h6>
                    <h6>25% Completed</h6>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="signup-box">

                  <div className="signup-left">
                    <h4>{staticData?.verbiage?.student[1]?.heading}</h4>
                    <p>{staticData?.verbiage?.student[1]?.text} </p>
                    <div className="signup-step">
                      <ul>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  <div className="signup-right">
                    <Form onSubmit={submitFormData}>
                      <Form.Group className="form-group mb-2" style={{ zIndex: 2 }}>
                        {/* <Form.Control type="text" placeholder="Search" /> */}
                        <Autocomplete className="search"
                          items={school?.data || []}
                          shouldItemRender={(item, value
                          ) => item.school_name.toLowerCase()
                            .indexOf(value.toLowerCase()) > -1}
                          getItemValue={item => item.school_name}
                          renderItem={(item, isHighlighted) =>
                            <div style={{
                              background: isHighlighted ?
                                '#eee' : 'white',
                              padding: '5px 10px',
                            }}
                              {...item} key={item._id}>
                              {item.school_name}
                            </div>
                          }
                          defaultValue={values.school_name}
                          value={value}
                          onChange={(e) => {
                            setValue(e.target.value)
                            handleFormData(e)
                          }}
                          onSelect={(val, item) => {
                            setValue(val)
                            handleSchool('school', item)
                            handleSchool('school_id', item._id)
                            handleSchool('school_name', item.school_name)
                            handleSchool('school_code', item.school_code)
                            handleSchool('school_street', item.street)
                            handleSchool('school_city', item.city)
                            handleSchool('school_state', item.state)
                            handleSchool('school_zip', item.zip_code)
                          }}
                          inputProps={{
                            style: {
                              width: '100%',
                              background: '#fff',
                              boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                              color: '#000',
                              borderRadius: 10,
                              minHeight: 52,
                              border: '1px solid #E9ECF4',
                              fontSize: 14,
                              paddingLeft: 15,
                              paddingRight: 15,
                            },
                            name: 'school',
                            placeholder: 'Search by school name',
                            required: true
                          }}
                          wrapperStyle={{
                            width: '100%',
                          }}
                        />
                        <Button variant="primary" type="submit" className="search-btn">
                          <img src="images/search-icon.png" alt="" width="22" />
                        </Button>
                        <span className="err">{error2?.school_name}</span>
                      </Form.Group>
                      {/* <div className="text-end">
                        <a href="javascript:void(0)" className="text-primary fw-500" onClick={handleShow}>Can’t find your school?</a>
                      </div> */}
                      <div className="grade-batch">
                        <h6 className="text-muted">SELECT GRADE</h6>
                        <ul className='slctGrade'>
                          {grade && grade?.data && grade?.data?.map((content, index) => (<li className={(selectGrade === index || values.grade_id === content._id) ? 'selected' : ''} key={index} onClick={() => changeRadio(index, content._id)}>
                            <input type="radio" name="grade_id" value={content._id} onChange={(e) => handleSchool('grade_id', content._id)} checked={(selectGrade === index || values.grade_id === content._id)} />
                            <img src={getResourceUrl(content.icon_image)} alt={content.title} />
                          </li>))}

                        </ul>
                        <span className="err">{error2?.grade_id}</span>
                      </div>
                      <h6 className="text-muted mt-2">SELECT ACADEMIC YEAR</h6>
                      <Form.Group className="mb-3">
                        <Form.Select onChange={(e) => handleSchool('academic_year', e.target.value)} defaultValue={values.academic_year}>
                          <option value=''>Select Year</option>
                          {staticData?.years?.getYears()?.map(yr=><option value={yr?.value}>{yr?.label}</option>)}
                          {/* <option value={`${currentYear - 2}-${currentYear - 1}`}>{currentYear - 2}-{currentYear - 1}</option>
                          <option value={`${currentYear - 3}-${currentYear - 2}`}>{currentYear - 3}-{currentYear - 2}</option> */}
                        </Form.Select>
                        <span className="err">{error2?.academic_year}</span>
                      </Form.Group>
                      <div className="signup-back-btn">
                        <Link to="/login" className="btn btn-link">Back</Link>
                        <ActionButton type={'submit'} className={"btn btn-primary btn-pill px-4"} requiredFields={[values?.grade_id, values?.school_id, values?.academic_year]} title={'Continue'} />
                        {/* <ActionButton>
                          <button type="submit" className="btn btn-primary btn-pill px-4" disabled={!(values?.grade_id && values?.school_id && values?.academic_year)}>Continue</button>
                        </ActionButton> */}
                      </div>
                    </Form>
                  </div>

                </div>

              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Can’t Find Your School?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-text text-center">
            It looks like your school is not registered with us. Enter the name of your school and we can reach out to them and express your interest in Dasya.
          </p>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="my-4">
              <Form.Control className="bg-white" type="text" placeholder="Enter your school name" value={schReqData?.school_name} onChange={e => setSchReqData({ ...schReqData, school_name: e.target.value })} />
              <FieldErrors errors={error} field={'school_name'} />
            </Form.Group>
            <Form.Group className="my-4">
              <Form.Control className="bg-white" type="email" placeholder="Enter your school email address" value={schReqData?.school_email} onChange={e => setSchReqData({ ...schReqData, school_email: e.target.value })} />
              <FieldErrors errors={error} field={'school_email'} />
            </Form.Group>
            <Form.Group className="my-4">
              <Form.Control className="bg-white" type="zip" placeholder="Enter your school zip code" value={schReqData?.school_zip} onChange={e => setSchReqData({ ...schReqData, school_zip: e.target.value })} />
              <FieldErrors errors={error} field={'school_zip'} />
            </Form.Group>
            <div className="text-center">
              <Button variant="primary" type='submit' className="px-5">
                Send
              </Button>
            </div>
          </Form>

        </Modal.Body>
      </Modal>

    </>
  )
}

export default SignupStepOne
