import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import TutorSideMenu from "./TutorSideMenu";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Resource from '../common/Resource';
import UploadResource from '../common/UploadResource';
import NotificationUI from '../common/NotificationUI';
import { getResourceUrl } from '../../Services/CommanFunctions';

function TutorResources() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [conf, setConf] = useState(false);
  const handleClose2 = () => setConf(false);
  const handleShow2 = () => setConf(true);
  const history = useHistory()

  const logout = () => {
    localStorage.clear()
    history.push('/tutor')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <TutorSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
                {/* <div className="mt-4">
                  <h6 className="text-muted mt-3">K12 Lesson Plan & Curriculum</h6>
                </div> */}
              </Col>
              {/* <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box1.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-video-btn">Download</a>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box2.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Expressions & Equations</h4>
                    <p>1:00 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-video-btn">Download</a>
                </div>
              </Col>
              <Col md={4}>
                <div className="dash-box">
                  <img src="images/dash-box3.jpg" alt="" />
                  <div className="dash-box-text">
                    <h4>Statistics & Probability</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-video-btn">Download</a>
                </div>
              </Col> */}
              <Resource />
              {/* <Col md={12}>
                <div className="video-category">
                  <ul>
                    <li className="active">
                      <a href="javascript:void(0)">E-Books</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Teaching Material</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Archived Classed</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Training</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">Favorites</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={12}>
                <h6 className="text-muted mt-2">Featured Videos</h6>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video1.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <Dropdown className="watch-btn-option">
                    <Dropdown.Toggle>
                      <img src="images/three-dot.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="javascript:void(0)">Edit</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="javascript:void(0)" onClick={setConf}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video2.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <Dropdown className="watch-btn-option">
                    <Dropdown.Toggle>
                      <img src="images/three-dot.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="javascript:void(0)">Edit</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="javascript:void(0)" onClick={setConf}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video3.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video4.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={12}>
                <h6 className="text-muted mt-2">Most Viewed</h6>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video1.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video2.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video3.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col>
              <Col md={3}>
                <div className="video-box">
                  <img src="images/video4.jpg" alt="" />
                  <div className="video-box-text">
                    <h4>Functions & Graphing</h4>
                    <p>1:30 min</p>
                  </div>
                  <a href="javascript:void(0)" className="watch-btn">
                    <img src="images/play-icon.png" alt="" />
                  </a>
                  <a href="javascript:void(0)" className="watch-btn-option">
                    <img src="images/three-dot.png" alt="" width="20" />
                  </a>
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
              <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src={user?.propic?getResourceUrl(user?.propic):"images/no_profile_img.png"} width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} - {user?.tutor?.org_type == 'school' ? user?.tutor_profile?.school_name : user?.tutor_profile?.company_name}</h5>
                  <p><small>ID: {user?.tutor?.tutor_code}</small></p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            <UploadResource />
            {/* <div className="card">
              <div className="school-side-details">
                <h5 className="text-muted">Request Additional Resources</h5>
                <Form>
                  <Row>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Select className="bg-grey">
                        <option>Subject Selected</option>
                        <option value="1">Subject 1</option>
                        <option value="2">Subject 2</option>
                        <option value="3">Subject 3</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group mb-3" as={Col} md="12">
                      <Form.Control className="bg-grey" as="textarea" placeholder="What kind of additional resources do you need?" rows="4" />
                    </Form.Group>
                    <Col md="12" className="text-center mt-2">
                      <Button className="px-4 btn-pill no-shadow" variant="primary">Submit</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <Modal show={conf} onHide={handleClose2}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mb-3">
            <img src="/images/confirm-icon.png" alt="" width="50" />
          </div>
          <p className="modal-text text-center">
            Are you sure you want to delete this resource?
          </p>
          <div className="text-center">
            <Button variant="primary" onClick={handleClose2} className="px-5 btn-pill">
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default TutorResources
